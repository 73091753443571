import antdSA from 'antd/lib/locale-provider/en_US'
import saMessages from '../locales/ar_SA.json'

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'ar',
}
export default saLang
