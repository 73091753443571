import { notification } from 'antd'
//
import { userSignOut } from 'appRedux/actions'
import store from 'appRedux/store'
import { CONFIG } from 'config/constants'
import { ALERT_TYPES } from 'util/constants'
import { showMessage } from './helpers'

const { SERVER_URL } = CONFIG

const codeMessage = {
  200: 'The server successfully returned the requested data.  ',
  201: 'New or modified data is successful.',
  202: 'A request has entered the background queue (asynchronous task).',
  204: 'The data was deleted successfully.',
  400: 'The request was made with an error and the server did not perform any new or modified data operations.',
  401: 'User does not have permission (token, username, password is incorrect).',
  403: 'The user is authorized, but access is forbidden.',
  404: 'The request is made for a record that does not exist and the server does not operate.',
  406: 'The format of the request is not available.',
  410: 'The requested resource is permanently deleted and will not be retrieved.',
  422: 'A validation error occurred when creating an object.',
  500: 'An error occurred on the server. Please check the server.',
  502: 'Gateway error.',
  503: 'The service is unavailable and the server is temporarily overloaded or maintained.',
  504: 'The gateway timed out.',
}

function showError(e) {
  console.error('PRINT IN %s=====>', 'Show showError', e)
  try {
    e.response.json().then((res) => {
      try {
        const message = res?.message || res?.detail || codeMessage[e.name]
        showMessage(ALERT_TYPES.ERROR, message, 5)
      } catch (ex) {
        showMessage(
          ALERT_TYPES.ERROR,
          'Unexpected error from Server. Please contact administration',
          5
        )
      }
    })
  } catch (et) {
    showMessage(ALERT_TYPES.ERROR, 'Unexpected error from Server. Please contact administration', 5)
  }
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }
  if (response.status === 401) {
    notification.error({
      // message: `Request error ${response.status}: ${response.url}`,
      message: 'Login Failed',
      description: 'Try again',
    })
  }
  const errortext = codeMessage[response.status] || response.statusText
  /* notification.error({
    // message: `Request error ${response.status}: ${response.url}`,
    message: `Request error ${response.status}: ${response.url}`,
    description: errortext,
  }); */
  const error = new Error(errortext)
  error.name = response.status
  error.response = response
  throw error
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @param  {isToken} boolean  The token we want to pass to "fetch" headers
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(restApi, options, isToken = true) {
  const url = SERVER_URL + restApi
  // console.log('options', options);
  const newOptions = { ...options }
  newOptions.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...newOptions.headers,
  }
  if (isToken) {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (currentUser && currentUser.token) {
      newOptions.headers = {
        Authorization: `Bearer ${currentUser.token}`,
        ...newOptions.headers,
      }
    }
  }
  newOptions.body = JSON.stringify(newOptions.body)

  // console.log('newOptions', newOptions);
  return fetch(url, newOptions)
    .then(checkStatus)
    .then((response) => {
      if (restApi.includes('response_type=csv')) {
        return response.text()
      }
      return response.json()
    })
    .catch((e) => {
      const { dispatch } = store
      const status = e.name
      console.error('PRINT IN %s=====>', 'Show error===>', status)
      if (status === 401) {
        dispatch(userSignOut())
      } else if (status <= 504 && status >= 500) {
        showError(e)
        // return;
      } else if (status >= 400 && status < 422) {
        showError(e)
      } else throw e
    })
}
