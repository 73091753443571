/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spin } from 'antd'
//
import { fetchTeamById } from 'appRedux/actions'
import { PAGINATION_TYPES, PAGINATION_LIMIT } from 'util/constants'
import { Header, EmployeeList } from 'components/Team'
import Auxiliary from 'util/Auxiliary'
import { showTotal } from 'util/helpers'

const TeamPage = () => {
  const dispatch = useDispatch()
  const [viewType, setViewType] = useState('card')
  const [sorter, setSorter] = useState('id,desc')
  const [search, setSearch] = useState({})

  const pageRef = useRef(1)

  const handleInitial = useCallback(
    (_page, limit = PAGINATION_LIMIT) => {
      const query = {
        page: _page,
        sort: sorter,
        limit: viewType === 'card' ? 20 : limit,
      }
      dispatch(fetchTeamById(query, search, viewType))
    },
    [dispatch, viewType, search, sorter]
  )

  const { employees, isLoading } = useSelector(({ pagination, loading }) => ({
    employees: pagination[PAGINATION_TYPES.team],
    isLoading: loading[PAGINATION_TYPES.team],
  }))

  const loadMore = () => {
    if (pageRef.current === employees?.pagination?.last) return
    pageRef.current += 1
    handleInitial(pageRef.current)
  }

  useEffect(() => {
    handleInitial()
  }, [handleInitial])

  const pagination = {
    hideOnSinglePage: true,
    position: 'both',
    total: employees.total,
    showTotal,
    pageSizeOptions: [10, 20, 50],
    showQuickJumper: true,
    onChange: (page, pageSize) => handleInitial(page, pageSize),
  }

  const handleChangeTable = (_pagination, filters, sort) => {
    if (sort?.order) {
      const { field, order } = sort
      const newSorter = `${field},${order === 'ascend' ? 'asc' : 'desc'}`
      setSorter(newSorter)
    }
  }

  return (
    <Auxiliary>
      <Header
        toggleView={(e) => {
          pageRef.current = 1
          setViewType(e)
        }}
        onClick={(query) => {
          pageRef.current = 1
          setSearch(query)
        }}
        clearSearch={() => {
          setSearch({})
          handleInitial(1)
        }}
      />
      <Spin spinning={isLoading}>
        <EmployeeList
          viewType={viewType}
          data={employees.items}
          pagination={pagination}
          handleChangeTable={handleChangeTable}
          handleLoadMore={loadMore}
        />
      </Spin>
    </Auxiliary>
  )
}

export default TeamPage
