import request from 'util/request'
import { encodeQuery } from 'util/helpers'
import { FETCH_TYPES } from 'util/constants'
import { ServiceBase } from './ServiceBase'
import { REST_API } from './rest.api'

const {
  ASSIGN_OFFICE,
  ASSIGN_COMPANY,
  ASSETS_ASSIGN,
  ASSIGN_GROUP,
  ASSIGN_SHIFT,
  ENABLE,
  DISABLE,
  ASSIGN_SALARY_BASIC,
  ASSIGN_SALARY_TAX,
} = REST_API.EMPLOYEES_ROUTE
export class Employees extends ServiceBase {
  async search(query, body) {
    const queryParam = encodeQuery(query)
    const options = { method: FETCH_TYPES.POST, body }
    return await request(this.API_ENDPOINT + '/search' + queryParam, options)
  }
  async teamSearch(query) {
    const queryParam = encodeQuery(query)
    const options = { method: FETCH_TYPES.GET }   
    return await request(this.API_ENDPOINT + '/team' + queryParam, options)
  }
  async createItem(id, route, body) {
    const options = { method: FETCH_TYPES.POST, body }
    if (route === REST_API.ADD_USER) {
      return await request(route, options)
    }
    return await request(this.API_ENDPOINT + '/' + id + route, options)
  }
  async patchItem(id, route, body) {
    const options = { method: FETCH_TYPES.PATCH, body }
    if (
      route === ASSIGN_COMPANY ||
      route === ASSIGN_OFFICE ||
      route === ASSIGN_GROUP ||
      route === ASSETS_ASSIGN ||
      route === ENABLE ||
      route === DISABLE
    ) {
      options.body.employee_id = parseInt(id)
      return await request(this.API_ENDPOINT + route, options)
    }
    if (route === ASSIGN_SHIFT || route === ASSIGN_SALARY_BASIC || route === ASSIGN_SALARY_TAX) {
      return await request(this.API_ENDPOINT + route, options)
    }
    return await request(this.API_ENDPOINT + '/' + id + route, options)
  }
  async removeItem(route, body) {
    const options = { method: FETCH_TYPES.DELETE, body }
    return await request(this.API_ENDPOINT + route, options)
  }
  async fetchAll(query, body) {
    const queryParam = encodeQuery(query)
    const options = {method: FETCH_TYPES.POST, body }
    return await request(`${REST_API.employees}/search${queryParam}`, options)
  }
}

