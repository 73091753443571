import request from 'util/request'
import { FETCH_TYPES } from 'util/constants'
import { REST_API } from './rest.api'
import { ServiceBase } from './ServiceBase'

export class Exams extends ServiceBase {
  async createSubject(exam_id, body) {
    const options = { method: FETCH_TYPES.POST, body }
    return await request(this.API_ENDPOINT + '/' + exam_id + REST_API.subjects, options)
  }
  async removeSubject(body) {
    const options = { method: FETCH_TYPES.DELETE, body }
    return await request(this.API_ENDPOINT + REST_API.subjects, options)
  }
}
