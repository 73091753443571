import React from 'react'
import PropTypes from 'prop-types'
//
import Widget from 'components/Widget'
import AttendanceCalender from './AttendanceCalender'

const Attendance = ({ employeeId }) => {
  return (
    <Widget title="" styleName="gx-card-full">
      <div className="gx-p-3">
        <AttendanceCalender employee_id={employeeId} />
      </div>
    </Widget>
  )
}

Attendance.propTypes = {
  employeeId: PropTypes.number.isRequired,
}

export default Attendance
