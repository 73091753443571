import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { Button, Col, Collapse, DatePicker, Form, Row, Spin } from 'antd'
import { CloseOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
//

import {
  getFilterResetValues,
  getLocalStorageItems,
  getInputToFilterMapping,
  resetLocalStorageFilters,
  updatedChangeValues,
  updateLocalStorageKeys,
  updateLocalStorageValues,
} from 'util/helpers'
import { EmployeeSearchBox } from 'components/SearchBox'
import { REST_API } from 'services/rest.api'
import { PAGINATION_TYPES, DATE_FORMAT } from 'util/constants'
import { fetchCompanies, fetchGroups, fetchStaticItem } from 'appRedux/actions'
import { EprsService, KpiService, EmployeeService } from 'services'
import FilterList from './FilterList'
import SelectOption from './SelectOption'
import { FILTER_LIST_EPR_KPI } from './data'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const handleDate = (dates) => {
  if (!dates?.length) return
  return [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')]
}

function callback(key) {
  // console.log('callback--->', key);
}

const genExtra = (setShowFilter) => (
  <SettingOutlined
    onClick={(event) => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation()
      setShowFilter(true)
    }}
  />
)

const itemLabel = (name, text) => {
  let label = null
  switch (name) {
    case 'from_date':
      label = text
      break
    case 'to_date':
      label = text
      break
    default:
      break
  }
  return label
}

const FormItem = (name, label, children) => {
  return (
    <Form.Item name={name} label={itemLabel(name, label)} className="gx-w-100">
      {children}
    </Form.Item>
  )
}

const FilterWidget = ({ employeeDisable, onClick, clearSearch }) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const [form] = Form.useForm()

  const [showFilter, setShowFilter] = useState(false)
  const [uiFilters, setUiFilters] = useState(getLocalStorageItems(url)?.map((item) => Object.keys(item)[0]))

  useEffect(() => {
    updateLocalStorageKeys(getLocalStorageItems(url), uiFilters, url)
  }, [url, uiFilters])

  const handleClear = () => {
    clearSearch()
    form.resetFields()
    resetLocalStorageFilters(getLocalStorageItems(url), url)
  }

  useEffect(() => {
    dispatch(fetchCompanies())
    dispatch(fetchGroups({ type_id: 1 }))
    dispatch(fetchStaticItem(REST_API.kpis_status, KpiService))
    dispatch(fetchStaticItem(REST_API.kpis_type, KpiService))
    dispatch(fetchStaticItem(REST_API.eprs_type, EprsService))
    dispatch(fetchStaticItem(REST_API.eprs_status, EprsService))
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.LEVELS, EmployeeService))
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.GRADES, EmployeeService))
  }, [dispatch])

  const { groups, isLoading, ...others } = useSelector(
    ({ common, pagination, groups, loading }) => ({
      groups,
      Company: pagination[PAGINATION_TYPES.companies]?.items ?? [],
      'KPI Type': common[REST_API.kpis_type] ?? [],
      'KPI Status': common[REST_API.kpis_status] ?? [],
      'EPR Type': common[REST_API.eprs_type] ?? [],
      'EPR Status': common[REST_API.eprs_status] ?? [],
      Level: common[REST_API.EMPLOYEES_ROUTE.LEVELS] ?? [],
      Grade: common[REST_API.EMPLOYEES_ROUTE.GRADES] ?? [],
      isLoading: !!loading[PAGINATION_TYPES.companies] || !!loading[PAGINATION_TYPES.employees],
    })
  )

  const dynamicGroupName = React.useMemo(
    () =>
      groups.reduce((acc, { name, id, tree }, i) => {
        acc[name] = {
          name,
          tree,
          label: name,
          value: id,
          placeholder: name,
        }
        return acc
      }, {}),
    [groups]
  )
  const items = React.useMemo(() => {
    const allowed = url?.endsWith('/kpi') ? ['EPR Type', 'EPR Status'] : ['KPI Type', 'KPI Status']
    const listX = { ...FILTER_LIST_EPR_KPI }
    Object.keys(listX)
      .filter((key) => allowed.includes(key))
      .forEach((key) => delete listX[key])

    return { ...listX }
  }, [url, FILTER_LIST_EPR_KPI])

  const inputToFilterMapping = useMemo(() => getInputToFilterMapping({ employee_id: 'Employee' }, items), [items]);

  useEffect(() => {
    const filterResetValues = getFilterResetValues(url, inputToFilterMapping)
    const { to_date, from_date } = filterResetValues || {}
    form.setFieldsValue({
      ...filterResetValues,
      to_date: to_date && [moment(to_date[0]), moment(to_date[1])],
      from_date: from_date && [moment(from_date[0]), moment(from_date[1])],
    })
  }, [inputToFilterMapping])

  const handleValuesChange = (changedValues) => {
    let updatedChangedValues
    const changedValuesKey = Object.keys(changedValues)[0]
    if (changedValuesKey === 'employee_id') {
      updatedChangedValues = updatedChangeValues(changedValues, 'select')
    } else if (changedValuesKey === 'to_date' || changedValuesKey === 'from_date') {
      updatedChangedValues = updatedChangeValues(changedValues, 'date_range')
    } else {
      updatedChangedValues = changedValues
    }
    localStorage.setItem(
      url, JSON.stringify(updateLocalStorageValues(updatedChangedValues, url, inputToFilterMapping)),
    )
  }

  return (
    <>
      <Collapse onChange={callback} className="gx-mb-3">
        <Panel header="Filter Options" key="1" showArrow={false} extra={genExtra(setShowFilter)}>
          <Spin spinning={false}>
            <Form className="gx-w-100" form={form} name="kpi_epr_search" onValuesChange={handleValuesChange}>
              <Row gutter={[24, 8]} align="middle" className="gx-ml-3">
                <Col span={8}>
                  <Form.Item name="employee_id" label="" className="gx-w-100">
                    <EmployeeSearchBox
                      searchFilter
                      disabled={employeeDisable}
                      onChange={(item) => {
                        form.setFieldsValue({ employee_id: item?.value?.split('#')[0] })
                      }}
                    />
                  </Form.Item>
                </Col>
                {uiFilters?.map((labelKey, index) => {
                  const item = items[labelKey]
                  if (!item) return null
                  const { name, tree, label, placeholder } = item
                  return (
                    <Col
                      key={`filter-kpi-epr-${index}`}
                      span={name === 'from_date' || name === 'to_date' ? 11 : 8}
                    >
                      {others[label] &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={others[label]} placeholder={placeholder} />
                        )}
                      {Object.keys(dynamicGroupName).includes(name) &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={tree} placeholder={placeholder} />
                        )}
                      {(name === 'from_date' || name === 'to_date') &&
                        FormItem(
                          name,
                          label,
                          <RangePicker
                            ranges={{
                              Today: [moment(), moment()],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            format={DATE_FORMAT}
                          />
                        )}
                    </Col>
                  )
                })}
                <Col span={24} className="gx-pl-0">
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    loading={isLoading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((fieldValues) => {
                          const { from_date, to_date, employee_id, ...rest } = fieldValues
                          const from_key = url?.endsWith('/kpi') ? 'from_range' : 'from_date_range'
                          const to_key = url?.endsWith('/kpi') ? 'to_range' : 'to_date_range'
                          const tree_ids = []
                          for (const key in rest) {
                            if (Object.keys(dynamicGroupName).includes(key)) {
                              const value = rest[key]
                              if (value) {
                                tree_ids.push(value)
                              }
                              delete rest[key]
                            }
                          }
                          const values = _({
                            ...rest,
                            group_ids: tree_ids,
                            [from_key]: handleDate(from_date),
                            [to_key]: handleDate(to_date),
                            ...(!employeeDisable && { employee_id }),
                          })
                            .omitBy(_.isUndefined)
                            .omitBy(_.isNull)
                            .value()
                          if (!tree_ids.length) {
                            delete values.group_ids
                          }
                          onClick(values)
                        })
                        .catch((info) => {
                          console.error('Validate Failed:', info)
                        })
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    danger
                    icon={<CloseOutlined />}
                    disabled={isLoading}
                    onClick={() => handleClear()}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Panel>
      </Collapse>
      <FilterList {...{ showFilter, setShowFilter, uiFilters, setUiFilters, items }} />
    </>
  )
}

FilterWidget.defaultProps = {
  clearSearch: () => null,
  onClick: () => null,
  employeeDisable: false,
}
FilterWidget.propTypes = {
  clearSearch: PropTypes.func,
  onClick: PropTypes.func,
  employeeDisable: PropTypes.bool,
}

export default FilterWidget
