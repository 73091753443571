/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Radio,
  Row,
  Select,
  Table,
  Tooltip,
} from 'antd'
import moment from 'moment'
//
import { EprService } from 'services'
import { fetchKPI, fetchEPR, deleteEPR, addOrUpdateEPR, fetchStaticItem } from 'appRedux/actions'
import { PAGINATION_TYPES, DATE_FORMAT, FORM_ITEM_LAYOUT, EPR_KPI_STATUS_BG } from 'util/constants'
import FormModal from 'components/EPRFormModal'
import EmployeeCell from 'components/EmployeeCell'

import Widget from 'components/Widget'
import Auxiliary from 'util/Auxiliary'

import { sortColumnObject, showDeleteConfirm } from '../../util/helpers'
import { REST_API } from '../../services/rest.api'
import { getDateFormat, PAGINATION_LIMIT } from '../../util/constants'
import FilterWidget from '../../components/FilterWidget/KPIAndEPR'

const initialSearch = {
  employee_id: '',
  type_id: '',
  status_id: '',
  level_id: '',
  grade_id: '',
  company_id: '',
  group_ids: [],
  from_range: [],
  to_range: [],
}
const EPRForm = ({ types, kpis, isDisabled }) => {
  return (
    <>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="kpi_id"
        label="Period"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          allowClear
          className="gx-w-100"
          placeholder="Select kpi"
          options={kpis?.map((i) => ({ value: i.id, label: i.name }))}
        />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="type_id"
        label="Type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          allowClear
          className="gx-w-100"
          placeholder="Select type"
          options={types?.map((i) => ({ value: i.id, label: i.name }))}
        />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="from_date"
        label="From"
        rules={[
          {
            required: true,
            message: 'Start date must not be empty',
          },
        ]}
      >
        <DatePicker
          className="gx-w-100"
          placeholder="Start date"
          disabled={isDisabled}
          format={DATE_FORMAT}
        />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="to_date"
        label="To"
        rules={[
          {
            required: true,
            message: 'End date must not be empty',
          },
        ]}
      >
        <DatePicker
          className="gx-w-100"
          placeholder="End date"
          disabled={isDisabled}
          format={DATE_FORMAT}
        />
      </Form.Item>
    </>
  )
}

const EPRList = () => {
  const EPRListType = localStorage.getItem('EPR_LIST_TYPE') ?? 'self'
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const [form] = Form.useForm()
  const [listType, setListType] = useState(EPRListType)
  const [search, setSearch] = useState(initialSearch)
  const [visible, setVisible] = useState(false)
  const [isDisabled, setDisabled] = useState(false)
  const [editItem, setEditItem] = useState(undefined)
  const [current, setCurrent] = useState(1)
  const [sort, setSort] = useState(JSON.parse(localStorage.getItem(`${url}/sort`)) || '')
  const handleInitial = useCallback(
    (_page, limit = PAGINATION_LIMIT) => {
      setCurrent(_page)
      const query = {
        list_type: listType,
        page: _page,
        limit,
        ...(sort && { sort }),
        ...search,
      }
      dispatch(fetchEPR(query))
    },
    [dispatch, search, listType, sort]
  )

  useEffect(() => {
    handleInitial(1)
  }, [handleInitial])

  useEffect(() => {
    if (sort) {
      localStorage.setItem(`${url}/sort`, JSON.stringify(sort))
    }
  }, [sort])

  useEffect(() => {
    dispatch(fetchKPI({ list_type: 'self', status_id: 4 }))
    dispatch(fetchStaticItem(REST_API.eprs_type, EprService))
  }, [dispatch])
  const handleEdit = (item) => {
    const { type, kpi, from_date, to_date, ...rest } = item
    form.setFieldsValue({
      ...rest,
      type_id: type.id,
      kpi_id: kpi?.id,
      from_date: getDateFormat(from_date),
      to_date: getDateFormat(to_date),
    })
    setVisible(true)
    setEditItem(item)
  }

  const handleDelete = (item) => {
    showDeleteConfirm(<ExclamationCircleOutlined />, item.name, () => {
      return dispatch(deleteEPR(item.id))
    })
  }

  const { kpis, EPR, types, isLoading } = useSelector(({ pagination, common, loading }) => ({
    kpis: pagination[PAGINATION_TYPES.kpi],
    EPR: pagination[PAGINATION_TYPES.epr],
    types: common[REST_API.eprs_type],
    isLoading: loading[PAGINATION_TYPES.epr],
  }))

  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      render: (_) => {
        return <Badge count={_?.name} style={{ backgroundColor: EPR_KPI_STATUS_BG[_?.id] }} />
      },
      ...sortColumnObject('status_id', setSort, sort),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (_) => _?.name,
      ...sortColumnObject('type_id', setSort, sort),
    },
    {
      title: 'EPR',
      dataIndex: 'name',
      render: (_, record) => (
        <Link to={`/epr/${record?.id}`}>
          <div className="gx-link">{_}</div>
        </Link>
      ),
    },
    {
      title: 'KPI',
      dataIndex: ['kpi', 'name'],
      ...sortColumnObject('kpi_id', setSort, sort),
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      render: (employee) => {
        const { name, photo, eid } = employee ?? {}
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('employee_id', setSort, sort),
    },
    {
      title: 'Line manager',
      dataIndex: 'line_manager',
      render: (line_manager) => {
        const { name, photo, eid } = line_manager ?? {}
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('line_manager_id', setSort, sort),
    },
    {
      title: 'O&O',
      dataIndex: 'one_over_one',
      render: (employee) => {
        const { name, photo, eid } = employee ?? {}
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('one_over_one_id', setSort, sort),
    },
    {
      title: 'Fiscal Year',
      dataIndex: 'from_date',
      render: (_, item) => {
        const date = `${moment(_).format('YYYY')} - ${moment(item?.to_date).format('YYYY')}`
        return <div>{date}</div>
      },
      ...sortColumnObject('from_date', setSort, sort),
    },
    {
      title: 'Action',
      width: 100,
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item disabled={record?.status?.id !== 1} onClick={() => handleEdit(record)}>
              <EditOutlined />
              EDIT
            </Menu.Item>
            <Menu.Item disabled={record?.status?.id !== 1} onClick={() => handleDelete(record)}>
              <DeleteOutlined />
              DELETE
            </Menu.Item>
          </Menu>
        )
        return (
          <Dropdown
            overlay={menu}
            disabled={false}
            className="gx-pl-1"
            placement="bottomCenter"
            arrow
          >
            <ControlOutlined />
          </Dropdown>
        )
      },
    },
  ]

  const renderColumns = React.useMemo(() => {
    const IndexesToBeRemoved = [5, 8]
    if (listType !== 'self') {
      while (IndexesToBeRemoved.length) {
        columns.splice(IndexesToBeRemoved.pop(), 1)
      }
    } else {
      columns.splice(4, 1)
    }
    return columns
  }, [columns, listType])

  const pagination = {
    current,
    hideOnSinglePage: true,
    position: 'both',
    total: EPR.total,
    pageSizeOptions: [10, 20, 50],
    showQuickJumper: false,
    onChange: (page, pageSize) => handleInitial(page, pageSize),
  }

  const renderHeader = () => (
    <div className="gx-d-flex">
      <Tooltip title="Create Remote Work Application">
        <Button
          shape="circle"
          type="primary"
          onClick={() => {
            setEditItem(null)
            setVisible(true)
          }}
        >
          <span className="gx-fs-xxl">+</span>
        </Button>
      </Tooltip>
      <Radio.Group
        style={{ minWidth: '130px' }}
        options={[
          { label: 'Self', value: 'self' },
          { label: 'Team', value: 'team' },
        ]}
        onChange={(e) => {
          setListType(e?.target?.value)
          localStorage.setItem('EPR_LIST_TYPE', e?.target?.value)
        }}
        value={listType}
        optionType="button"
        buttonStyle="solid"
      />
      <div className="gx-w-100">
        <FilterWidget
          employeeDisable={listType === 'self'}
          clearSearch={() => setSearch(initialSearch)}
          onClick={(values) => setSearch(values)}
        />
      </div>
    </div>
  )
  const renderEPR = () => (
    <Row justify="center" align="middle">
      <Col span={24}>
        <Table
          loading={isLoading}
          columns={renderColumns}
          dataSource={EPR.items}
          pagination={pagination}
          rowKey={(item) => item?.id}
        />
      </Col>
    </Row>
  )
  return (
    <Auxiliary>
      {renderHeader()}
      <Widget>{renderEPR()}</Widget>
      {visible && (
        <FormModal
          {...{
            visible,
            width: 450,
            formName: 'epr-create-form',
            handleCancel: () => setVisible(false),
            handleOk: (fieldsValue) => {
              const values = {
                ...fieldsValue,
                from_date: fieldsValue.from_date.format(DATE_FORMAT),
                to_date: fieldsValue.to_date.format(DATE_FORMAT),
              }
              if (editItem) values.id = editItem.id
              return dispatch(addOrUpdateEPR(values))
            },
            onFieldsChange: () => {
              const kpiID = form.getFieldValue('kpi_id')
              const type = form.getFieldValue('type_id')
              if (type === 1) {
                // Yearly EPR
                const kpi = kpis?.items?.find(({id}) => id === kpiID)
                if (kpi?.type?.id === 1) {
                  setDisabled(true)
                  // Yearly KPI
                  const { from, to } = kpi
                  form.setFieldsValue({
                    from_date: getDateFormat(from),
                    to_date: getDateFormat(to),
                  })
                  return null
                }
              }
              setDisabled(false)
            },
            title: !editItem ? 'Create New EPR' : 'Update EPR',
            form,
            // eslint-disable-next-line react/display-name
            component: () => <EPRForm types={types} kpis={kpis.items} isDisabled={isDisabled} />,
          }}
        />
      )}
    </Auxiliary>
  )
}
export default EPRList
