import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    app: true,
  },
  reducers: {
    setLoading: (state, { payload }) => ({ ...state, [payload.key]: payload.value }),
    // actionTypeExam: {
    //   reducer: (state, { payload }) => {
    //     return { ...state, [payload.key] : payload.value }
    //   }
    // },
    loading: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
  },
})

export const { setLoading } = loadingSlice.actions
export default loadingSlice.reducer
