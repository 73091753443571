import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, Layout } from 'antd'

import SidebarContent from './SidebarContent'
import { updateWindowSize, toggleCollapsedSideNav } from 'appRedux/reducers/Common'
import {
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from 'util/constants/ThemeSetting'

const { Sider } = Layout

const Sidebar = () => {
  const dispatch = useDispatch()

  const { themeType, navStyle } = useSelector(({ uiSettings }) => uiSettings)
  const { navCollapsed, width } = useSelector(({ common }) => common)

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed))
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowSize({ width: window.innerWidth, height: window.innerHeight }))
    })
  }, [dispatch])

  let drawerStyle = 'gx-collapsed-sidebar'

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = ''
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = 'gx-mini-sidebar'
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = 'gx-collapsed-sidebar'
  }
  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} ${
        themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null
      }`}
      trigger={null}
      collapsed={width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR}
      theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
      collapsible
    >
      {width < TAB_SIZE ? (
        <Drawer
          className={`gx-drawer-sidebar ${
            themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null
          }`}
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          visible={navCollapsed}
        >
          <SidebarContent />
        </Drawer>
      ) : (
        <SidebarContent />
      )}
    </Sider>
  )
}
export default Sidebar
