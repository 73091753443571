import React from 'react'
import { connect } from 'react-redux'
import { Avatar, Divider, Select, Spin } from 'antd'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { UserOutlined } from '@ant-design/icons'
//
import { EmployeeService } from 'services'
import { addCommonItems } from 'appRedux/reducers/Common'
import { KEYS } from 'util/constants'

const { Option } = Select

class EmployeeSearch extends React.Component {
  constructor(props) {
    super(props)
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 600)
  }

  state = {
    data: [],
    value: undefined,
    fetching: false,
  }
  async componentDidMount() {
    const { onChange, searchFilter, value } = this.props
    if (value) {
      let res
      if (searchFilter) {
        res = await EmployeeService.search({}, { ids: [value], status: true })
      } else {
        res = await EmployeeService.find({ id: value, enable: true })
      }
      const data = res.items.map((user) => ({
        text: user.name,
        value: user.id + '#' + user.name + '#' + user.eid,
        eid: user.eid,
        image: user.photo,
      }))
      this.setState({
        data,
        fetching: false,
        value: data[0]?.value ? { value: data[0]?.value } : value,
      })
      if (onChange && data[0]?.value) {
        onChange({ value: data[0]?.value })
      }
    }
  }
  fetchUser = (value) => {
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ data: [], fetching: true })
    const query = { full_text: value, status: true }
    EmployeeService.search({}, query).then((res) => {
      if (fetchId !== this.lastFetchId) {
        // for fetch callback order
        return
      }
        const data = res?.items?.map((user) => ({
          text: user.name,
          value: user.id + '#' + user.name + '#' + user.eid,
          eid: user.eid,
          image: user.photo,
        })) ?? []
      this.setState({ data, fetching: false })
    })
  }

  handleChange = (value) => {
    const filtered = this.state.data.filter((x) => x?.value === value?.value)[0]
    const employee = {
      name: filtered?.text,
      id: filtered?.value?.split('#')[0],
      ...filtered,
    }
    this.props.dispatch(addCommonItems({ key: KEYS.selectedEmployee, value: employee }))
    this.setState({
      value,
      data: [],
      fetching: false,
    })
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { fetching, data, value } = this.state
    return (
      <Select
        showSearch
        allowClear
        labelInValue
        disabled={this.props.disabled}
        value={value}
        defaultActiveFirstOption={false}
        showArrow={false}
        placeholder="Select employee"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        onSearch={this.fetchUser}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {data.map((d) => (
          <Option key={d.value}>
            <Avatar size={20} src={d.image?.small} icon={<UserOutlined />} />
            <Divider type="vertical" />
            {d.text}
            <small> ({d.eid})</small>
          </Option>
        ))}
      </Select>
    )
  }
}

EmployeeSearch.defaultProps = {
  searchFilter: false,
}

EmployeeSearch.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  props: PropTypes.any,
  disabled: PropTypes.bool,
  searchFilter: PropTypes.bool,
}

export const EmployeeSearchBox = connect()(EmployeeSearch)
