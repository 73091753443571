import React, { useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Col, Progress, Row, Spin } from 'antd'
import Slider from 'react-slick'
//
import { fetchLeaveTypes } from 'appRedux/actions'
import { PAGINATION_TYPES } from 'util/constants'

function SampleArrow() {
  return <div style={{ display: 'none' }} />
}
const options = {
  dots: true,
  infinite: false,
  // autoplay: true,
  // autoplaySpeed: 2000,
  speed: 2000,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <SampleArrow />,
  prevArrow: <SampleArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

const EmployeeLeave = ({ data }) => {
  const dispatch = useDispatch()
  const leaveStatus = useMemo(() => data?.cache?.leave ?? [], [data])
  const { leaveTypes, isLoading } = useSelector(({ pagination, loading }) => ({
    leaveTypes: pagination[PAGINATION_TYPES.leave_types],
    isLoading: loading[PAGINATION_TYPES.leave_types],
  }))

  useEffect(() => {
    dispatch(fetchLeaveTypes())
  }, [dispatch])

  const leaveList = useMemo(
    () =>
      leaveTypes?.items
        ?.filter((leave) => ![5, 6, 7, 9].includes(leave.id))
        .map((v) => ({ ...v, leaveNo: leaveStatus[v.id] ?? 0 })),
    [leaveStatus, leaveTypes.items]
  )
  const item = useMemo(() => leaveList[4], [leaveList])

  const progressText = () => {
    return (
      <>
        <div>
          <p
            style={{
              color: '#1E232DCC',
              marginBottom: '7px',
              marginRight: '10px',
            }}
          >
            {item?.days - item?.leaveNo}
          </p>
          <p style={{ color: '#7F7F7F', fontSize: '17px', marginRight: '7px' }}>Leave Balance</p>
        </div>
      </>
    )
  }
  return (
    <Spin spinning={isLoading}>
      <div className="gx-d-flex gx-justify-content-center gx-align-items-center gx-m-4 gx-py-3 gx-flex-column">
        <Progress
          type="circle"
          percent={(item?.leaveNo / item?.days) * 100}
          width={180}
          strokeColor={{
            '0%': '#6FCF97',
            '100%': '#6FCF97',
          }}
          strokeLinecap="square"
          trailColor="#E5E5E5"
          format={progressText}
        />
        <p className="gx-text-center gx-fs-lg" style={{ marginLeft: -20 }}>
          <span className="gx-post-designation">{item?.name}</span>
        </p>
      </div>
      <div className="gx-d-flex gx-justify-content-between gx-mb-1">
        <div className="gx-pl-4">
          <div className="gx-d-flex gx-justify-content-between">
            <div
              style={{
                height: '13px',
                width: '13px',
                backgroundColor: '#ECECEC',
              }}
            />
            <p className="gx-ml-md-2 gx-mb-0 gx-fs-md"> Total leaves </p>
          </div>
          <p
            style={{
              fontWeight: '500',
              fontSize: '20px',
              marginTop: '3px',
              textAlign: 'left',
              marginLeft: '20px',
            }}
          >
            {item?.days}
          </p>
        </div>
        <div className="gx-pr-4">
          <div className="gx-d-flex gx-justify-content-between">
            <div
              style={{
                height: '13px',
                width: '13px',
                backgroundColor: '#6FCF97',
              }}
            />
            <p className="gx-ml-md-2 gx-mb-0 gx-fs-md"> Leave enjoyed </p>
          </div>
          <p
            style={{
              fontWeight: '500',
              fontSize: '20px',
              marginTop: '3px',
              textAlign: 'right',
            }}
          >
            {item?.leaveNo ?? 0}
          </p>
        </div>
      </div>
      <Row>
        <Col span={24}>
          <div styleName="gx-text-center">
            {/* eslint-disable */}
            <Slider {...options}>
            {/* eslint-enable */}
              {leaveList
                .filter((v) => v.name !== 'Casual')
                .map((leave) => (
                  <Section key={leave.name} leave={leave} />
                ))}
            </Slider>
          </div>
        </Col>
      </Row>
    </Spin>
  )
}

const Section = ({ leave }) => {
  const { name, leaveNo, days } = leave
  const progressColor = useMemo(() => {
    if (name === 'Without Pay') {
      return {
        '0%': '#00ffbf',
        '100%': '#00ffbf',
      }
    }
    if (name === 'Alternative') {
      return {
        '0%': '#FFBA5A',
        '100%': '#FFBA5A',
      }
    }
    if (name === 'Earned') {
      return {
        '0%': '#F8A830',
        '100%': '#F8A830',
      }
    }
    if (name === 'Medical') {
      return {
        '0%': '#229DE2',
        '100%': '#229DE2',
      }
    }
    return {
      '0%': '#229DE2',
      '100%': '#229DE2',
    }
  }, [name])
  return (
    <div
      style={{ height: 128 }}
      className="gx-classic-testimonial gx-slide-item gx-pb-3 gx-d-flex gx-align-items-center gx-flex-column"
    >
      <div>
        <Progress
          type="circle"
          percent={(leaveNo / days) * 100}
          width={65}
          style={{ marginLeft: '14px' }}
          strokeColor={progressColor}
          strokeLinecap="square"
          trailColor="#E5E5E5"
          format={() => {
            return (
              <>
                <div>
                  <p
                    style={{
                      color: '#666666',
                      marginBottom: '7px',
                      fontSize: '13px',
                      marginRight: '10px',
                    }}
                  >
                    {leaveNo}/
{days}
                  </p>
                </div>
              </>
            )
          }}
        />
      </div>
      <p>
        <small className="gx-post-designation">{name}</small>
      </p>
    </div>
  )
}
Section.propTypes = {
  leave: PropTypes.shape({
    name: PropTypes.string.isRequired,
    days: PropTypes.number.isRequired,
    leaveNo: PropTypes.number.isRequired,
  }).isRequired,
}

EmployeeLeave.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types, react/require-default-props
  data: PropTypes.object,
}

export default EmployeeLeave
