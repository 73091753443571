import moment from 'moment'

const KEYS = {
  login: 'login',
  FIREBASE: 'FIREBASE',
  profile: 'profile',
  groups: 'GROUPS',
  exams: 'EXAMS',
  examsTypes: 'EXAMS_TYPES',
  selectedEmployee: 'SELECTED_EMPLOYEE',
}

const PAGINATION_LIMIT = 10
const PAGINATION_TYPES = {
  assets: 'ASSETS',
  employees: 'EMPLOYEES',
  offices: 'OFFICES',
  holidays: 'HOLIDAYS',
  shifts: 'SHIFTS',
  assign_shifts: 'ASSIGN_SHIFTS',
  companies: 'COMPANIES',
  remote_work: 'REMOTE_WORK',
  leave: 'LEAVE',
  leave_durations: 'LEAVE_DURATIONS',
  leave_types: 'LEAVE_TYPES',
  salary_basic: 'SALARY_BASIC',
  pay_schedules: 'PAY_SCHEDULES',
  pay_schedule_types: 'PAY_SCHEDULES_TYPES',
  loans: 'LOANS',
  salaries: 'SALARIES',
  development_areas: 'DEVELOPMENT_AREAS',
  epr: 'EPR',
  kpi: 'KPI',
  team: 'TEAM',
  attendance_individual: 'ATTENDANCE_INDIVIDUAL',
  attendance_summary: 'ATTENDANCE_SUMMARY',
  activity: 'ACTIVITY',
}

const FETCH_TYPES = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}

const ALERT_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  DANGER: 'danger',
  INFO: 'info',
  ERROR: 'error',
  WARN: 'warn',
}

const FB_EVENTS = {
  OB_COMMENT: 'employee.objective.comment',
  OB_KPI_COMMENT: 'employee.objective.kpi.comment',
}

const SECTION_TAB = [
  { key: 'section_a', tab: 'Section A (KPI)', color: 'turquoise' },
  { key: 'section_b', tab: 'Section B (Value)', color: 'aquamarine' },
  { key: 'section_c', tab: 'Section C (Overall)', color: 'red' },
  { key: 'section_d', tab: 'Section D (IDP/Remarks)', color: 'lightblue' },
]
const EPR_SECTION_C = {
  'Exceeded expectations': [
    { rating: '3.1', title: 'Superior Results, UnSatisfactory Behaviours' },
    { rating: '3.2', title: 'Superior Results, Good Behaviours' },
    { rating: '3.3', title: 'Superior Results, Superior Behavious' },
  ],
  'Fully met expectations': [
    { rating: '2.1', title: 'Good Results, Unsatisfactory Behaviours' },
    { rating: '2.2', title: 'Good Results, Good Behaviours' },
    { rating: '2.3', title: 'Good Results, Superior Behaviours' },
  ],
  'Partially met expectations': [
    { rating: '1.1', title: 'Unsatisfactory Results, Unsatisfactory Behaviours' }, // TODO ask DADA which is correct Behaviours || Behaviors
    { rating: '1.2', title: 'Unsatisfactory Results, Good Behaviours' },
    { rating: '1.3', title: 'Unsatisfactory Results, Superior Behaviours' },
  ],
}
const EMPLOYEE_RATING_COLOR = {
  border: '1.8px solid #01A9B4',
}
const LM_RATING_COLOR = {
  border: '1.8px solid #D9534F',
}
const RATING_STYLE = {
  width: 40,
  height: 40,
  borderRadius: '50%',
  fontSize: '18px',
  marginBottom: 0,
  marginRight: '1rem',
}
const EMPLOYEE_TAB = [
  {
    key: 'profile',
    tab: 'Profile',
  },
  {
    key: 'general',
    tab: 'General',
  },
  {
    key: 'personal',
    tab: 'Personal',
  },
  {
    key: 'employmentHistory',
    tab: 'Employment History',
  },
  {
    key: 'achievements',
    tab: 'Achievements',
  },
  {
    key: 'activity',
    tab: 'Activity',
  },
  {
    key: 'educations',
    tab: 'Educations',
  },
  {
    key: 'assets',
    tab: 'Assets',
  },
  {
    key: 'documents',
    tab: 'Documents',
  },
  {
    key: 'separations',
    tab: 'Separations',
  },
]

const FORM_ITEM_LAYOUT = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
}

const FORM_NAMES = {
  HOLIDAY: 'holiday',
  EMPLOYEE_GENERAL: 'em_f_genereal',
  EMPLOYEE_ADDRESS: 'em_f_address',
  EMPLOYEE_REFERENCE: 'em_f_reference',
  EMPLOYEE_CONTACT: 'em_f_contact',
  EMPLOYEE_DOCUMENT: 'em_f_document',
  EMPLOYEE_EDUCATION: 'em_f_education',
  EMPLOYEE_ACHIEVEMENT: 'em_f_achievement',
  EMPLOYEE_FAMILY: 'em_f_family',
  EMPLOYEE_FAMILY_MEMBER: 'em_f_family_member',
  EMPLOYEE_SKILL: 'em_f_skill',
  EMPLOYEE_TRAINING: 'em_f_training',
  EMPLOYEE_HISTORY: 'em_f_history',
  EMPLOYEE_LANGUAGES: 'em_f_languages',
  EMPLOYEE_SEPARATION: 'em_f_separations',
  EMPLOYEE_ASSETS: 'em_f_assets',
  EMPLOYEE_GROUPS: 'em_f_groups',
}

const DATE_FORMAT = 'DD-MM-YYYY'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'

const WEEK_DAYS = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' },
]

const setTimeFormat = (time) => time.format('HH:mm:ss')
const getTimeFormat = (time) => moment(new Date(time), TIME_FORMAT)
const viewTimeFormat = (time) => moment(time).format(TIME_FORMAT)

const setDateFormat = (date) => date.format(DATE_FORMAT)
const getDateFormat = (date) => moment(new Date(date), DATE_FORMAT)
const getDateTimeFormat = (date) => moment(new Date(date), DATE_TIME_FORMAT)
const viewDateFormat = (date) => moment(date).format(DATE_FORMAT)

const BG_COLOR_LIST = [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'orange',
  'pink',
  'teal',
]
const LANGUAGES_PROFICIENCY = ['Low', 'Medium', 'Good', 'Excellent']
const STATUS_BG = ['#000', '#616161', '#01A9B4', '#5CB85C', '#D9534F']
const EPR_KPI_STATUS_BG = ['#000', '#6c757d', '#007bff', '#01A9B4', '#5CB85C', '#ffc107']
export {
  setTimeFormat,
  getTimeFormat,
  viewTimeFormat,
  setDateFormat,
  getDateFormat,
  getDateTimeFormat,
  viewDateFormat,
  BG_COLOR_LIST,
  EMPLOYEE_RATING_COLOR,
  LM_RATING_COLOR,
  RATING_STYLE,
  LANGUAGES_PROFICIENCY,
  KEYS,
  PAGINATION_LIMIT,
  DATE_FORMAT,
  TIME_FORMAT,
  DATE_TIME_FORMAT,
  WEEK_DAYS,
  PAGINATION_TYPES,
  FETCH_TYPES,
  ALERT_TYPES,
  FB_EVENTS,
  EMPLOYEE_TAB,
  SECTION_TAB,
  FORM_ITEM_LAYOUT,
  FORM_NAMES,
  STATUS_BG,
  EPR_KPI_STATUS_BG,
  EPR_SECTION_C,
}
