import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Col, Descriptions, Divider, Row, Tag } from 'antd'
import { SafetyCertificateOutlined } from '@ant-design/icons'
//
import Widget from '../../Widget'
import { ContactList } from 'components/Employee'
import CustomScrollbars from 'util/CustomScrollbars'
import { DATE_FORMAT } from 'util/constants'

const Personal = ({ data }) => {
  const {
    references,
    contacts,
    family: { father_name, mother_name, spouse_name, marriage_date, marital_status, members },
  } = data

  return (
    <Row>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Personal Information">
          <Descriptions column={1} bordered size="small" labelStyle={{ width: 180 }}>
            <Descriptions.Item label="Father name">{father_name}</Descriptions.Item>
            <Descriptions.Item label="Mother name">{mother_name}</Descriptions.Item>
            <Descriptions.Item label="Spouse name">{spouse_name}</Descriptions.Item>
            <Descriptions.Item label="Marriage Date">
              {marriage_date && moment(marriage_date).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label="Marital Status">{marital_status?.name}</Descriptions.Item>
          </Descriptions>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget
          title={
            <Tag icon={<SafetyCertificateOutlined />} color="#87d068">
              Family Member Information
            </Tag>
          }
          styleName="gx-card-tabs"
        >
          <Divider />
          <CustomScrollbars className="gx-module-content-scroll">
            {members.length === 0 ? (
              <div className="gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center">
                {'No family member found'}
              </div>
            ) : (
              <ContactList contactList={members} />
            )}
          </CustomScrollbars>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget
          title={
            <Tag icon={<SafetyCertificateOutlined />} color="magenta">
              References
            </Tag>
          }
          styleName="gx-card-tabs"
        >
          <Divider />
          <CustomScrollbars className="gx-module-content-scroll">
            {references.length === 0 ? (
              <div className="gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center">
                {'No reference found'}
              </div>
            ) : (
              <ContactList contactList={references} />
            )}
          </CustomScrollbars>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget
          title={
            <Tag icon={<SafetyCertificateOutlined />} color="blue">
              Contacts
            </Tag>
          }
          styleName="gx-card-tabs"
        >
          <Divider />
          <CustomScrollbars className="gx-module-content-scroll">
            {contacts.length === 0 ? (
              <div className="gx-h-100 gx-d-flex gx-align-items-center gx-justify-content-center">
                {'No contact found'}
              </div>
            ) : (
              <ContactList contactList={references} />
            )}
          </CustomScrollbars>
        </Widget>
      </Col>
    </Row>
  )
}

Personal.defaultProps = {
  data: {
    family: {},
    references: [],
    contacts: [],
  },
}

Personal.propTypes = {
  data: PropTypes.object,
}

export default Personal
