/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Descriptions, Row, Table } from 'antd'
import { EditOutlined } from '@ant-design/icons'
//
import {
  EPR_SECTION_C,
  LM_RATING_COLOR,
  EMPLOYEE_RATING_COLOR,
  RATING_STYLE,
} from '../../util/constants'
import Widget from '../Widget'

const SectionC = ({ section_c, section_a, section_b, handleEdit, isLM, eprStatusID }) => {
  const SECTION_C_KEYS = Object.keys(EPR_SECTION_C)
  const lm_overall_rating = React.useMemo(
    () => `${section_a?.lm_rating ?? 0}.${section_b?.lm_rating ?? 0}`,
    [section_a, section_b]
  )
  const columns = [
    {
      title: 'By',
      dataIndex: 'by',
      key: 'by',
    },
    {
      title: 'KPI',
      dataIndex: 'kpi',
      key: 'kpi',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Overall',
      dataIndex: 'overall',
      key: 'overall',
    },
  ]
  const dataSource = React.useMemo(
    () => [
      {
        by: 'Self',
        kpi: section_a?.rating ?? 0,
        value: section_b?.rating ?? 0,
        overall: `${section_a?.rating ?? 0}.${section_b?.rating ?? 0}`,
      },
      {
        by: 'Line Manager',
        kpi: section_a?.lm_rating ?? 0,
        value: section_b?.lm_rating ?? 0,
        overall: lm_overall_rating,
      },
    ],
    []
  )
  return (
    <Row>
      <Col span={24}>
        <Widget
          title={<div className="gx-pl-3">OVERALL PERFORMANCE & STABLE EVALUATION</div>}
          extra={
            isLM && // Never show
            eprStatusID === 10 && (
              <div className="gx-pr-3">
                <EditOutlined onClick={() => handleEdit(section_c)} />
              </div>
            )
          }
        >
          <Table pagination={false} dataSource={dataSource} columns={columns} />
          {/* <div className="gx-flex-row">
            {SECTION_C_KEYS.map((label, index) => {
              return (
                <div
                  key={`erp_section_c_label_key_${index}`}
                  className="gx-flex-row ant-row-center ant-row-middle gx-mr-4"
                  style={{ fontSize: 16 }}
                >
                  <p
                    className="ant-row-middle gx-mr-1"
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: '50%',
                      textAlign: 'center',
                      marginRight: 5,
                      paddingTop: 3,
                      ...EMPLOYEE_RATING_COLOR,
                    }}
                  >
                    {3 - index}
                  </p>
                  <p>{label}</p>
                </div>
              )
            })}
          </div>
          <Descriptions
            labelStyle={{ width: 150 }}
            bordered
            column={3}
          >
            <Descriptions.Item label="KPI">
              <div className="gx-flex-row">
                <div style={{ ...RATING_STYLE, ...EMPLOYEE_RATING_COLOR }}>
                  <p style={{ textAlign: 'center', margin: 7 }}>{kpi_rating}</p>
                </div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Stable">
              <div className="gx-flex-row">
                <div style={{ ...RATING_STYLE, ...LM_RATING_COLOR }}>
                  <p style={{ textAlign: 'center', margin: 7 }}>{stable_rating}</p>
                </div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label="Overall">
              <div className="gx-flex-row">
                <div style={{ ...RATING_STYLE, ...EMPLOYEE_RATING_COLOR }}>
                  <p style={{ textAlign: 'center', marginTop: 7 }}>
                    {overall_rating}
                  </p>
                </div>
              </div>
            </Descriptions.Item>
          </Descriptions> */}
        </Widget>
      </Col>
      {SECTION_C_KEYS.map((labelKey) => {
        const items = EPR_SECTION_C[labelKey]
        return items.map((item, index) => {
          const { rating, title } = item
          return (
            <Col key={`epr-kpi-section-c-details-${index}`} xs={12} sm={8} md={8} lg={8} xl={8}>
              <Widget
                className="gx-mb-0"
                style={{ background: rating == lm_overall_rating ? '#DBEFFA' : '#FCFCFC' }}
              >
                <div className="gx-text-center gx-pt-4" style={{ height: 150 }}>
                  <h2
                    style={{
                      fontSize: '45px',
                      lineHeight: '49px',
                      color: rating == lm_overall_rating ? '#1095DF' : '#000',
                    }}
                  >
                    {rating}
                  </h2>
                  <h4 style={{ color: rating == lm_overall_rating ? '#1095DF' : '#6E6B7B' }}>
                    {title}
                  </h4>
                </div>
              </Widget>
            </Col>
          )
        })
      })}
    </Row>
  )
}

SectionC.propTypes = {
  eprStatusID: PropTypes.number.isRequired,
  handleEdit: PropTypes.func.isRequired,
  isLM: PropTypes.bool.isRequired,
  section_c: PropTypes.shape({
    kpi_rating: PropTypes.number,
    stable_rating: PropTypes.number,
    overall_rating: PropTypes.number,
  }),
}

SectionC.defaultProps = {
  section_c: PropTypes.shape({
    kpi_rating: 0,
    stable_rating: 0,
    overall_rating: 0,
  }),
}
export default SectionC
