import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import _ from 'lodash'
import { Button, Col, Collapse, DatePicker, Form, Input, Row, Slider, Spin, Switch } from 'antd'
import { CheckOutlined, CloseOutlined, SearchOutlined, SettingOutlined } from '@ant-design/icons'
//
import {
  getFilterResetValues,
  getLocalStorageItems,
  getInputToFilterMapping,
  resetLocalStorageFilters,
  updatedChangeValues,
  updateLocalStorageKeys,
  updateLocalStorageValues,
  getInitialSearch,
  setSearchInLocalStorage,
} from 'util/lmTeam'
import { REST_API } from 'services/rest.api'
import { PAGINATION_TYPES, KEYS, DATE_FORMAT, setDateFormat } from 'util/constants'
import { fetchExams, fetchCompanies, fetchStaticItem } from 'appRedux/actions'
import { FILTER_LIST } from './data'
import FilterList from './FilterList'
import SelectOption from './SelectOption'
import { SearchEmployeeBox } from '../../components/SearchBox'

const { Panel } = Collapse
const { RangePicker } = DatePicker

const handleDate = (dates) => {
  if (!dates?.length) return undefined
  return {
    start: setDateFormat(dates[0]),
    end: setDateFormat(dates[1]),
  }
}

function callback() {
  // console.log('callback--->', key);
}

const genExtra = (setShowFilter) => (
  <SettingOutlined
    onClick={(event) => {
      // If you don't want click extra trigger collapse, you can prevent this:
      event.stopPropagation()
      setShowFilter(true)
    }}
  />
)

const itemLabel = (name, text) => {
  let label = null
  switch (name) {
    case 'service_length':
      label = text
      break
    case 'age':
      label = text
      break
    case 'status':
      label = text
      break
    case 'confirmation_date':
      label = text
      break
    case 'joining_date':
      label = text
      break
    case 'birthday':
      label = text
      break
    default:
      break
  }
  return label
}

const FormItem = (name, label, children) => {
  return (
    <Form.Item name={name} label={itemLabel(name, label)} className="gx-w-100">
      {children}
    </Form.Item>
  )
}

const FilterWidget = ({ onClick, clearSearch }) => {
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const history = useHistory(); // Hook to listen to route changes
  const [form] = Form.useForm()

  const [showFilter, setShowFilter] = useState(false)
  // const [uiFilters, setUiFilters] = useState(getLocalStorageItems(url)?.map((item) => Object.keys(item)[0]))
  const [uiFilters, setUiFilters] = useState(() => {
    const localStorageItems = getLocalStorageItems(url)
    const arrayFromLocalStorage = Object.values(localStorageItems)
    return arrayFromLocalStorage.map((item) => Object.keys(item)[0])
  })

  const [search, setSearch] = useState(getInitialSearch({}, url))

  useEffect(() => {
    const savedSearch = JSON.parse(localStorage.getItem('parent_id_key')) || {}
    if (savedSearch.parent_id) {
      setSearch((prevState) => ({ ...prevState, parent_id: savedSearch.parent_id }))
    }
  }, [])
  useEffect(() => {
    if (search) {
      setSearchInLocalStorage(search, 'parent_id_key')
      if (search.parent_id) {
        localStorage.setItem('parent_id_key', JSON.stringify({ parent_id: search.parent_id }))
      }
    }
  }, [search, url])

  useEffect(() => {
    updateLocalStorageKeys(getLocalStorageItems(url), uiFilters, url, undefined, ['Status'])
  }, [url, uiFilters])

  // Clean up local storage on route change
  useEffect(() => {
    const unlisten = history.listen(() => {
      localStorage.removeItem('parent_id_key')
    })

    // Clean up the listener on component unmount
    return () => {
      unlisten()
    }
  }, [history])

  // Clean up local storage on site close or reload
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem('parent_id_key')
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [url])

  const handleClear = () => {
    clearSearch()
    form.resetFields()
    resetLocalStorageFilters(getLocalStorageItems(url), url)
    // Reset the parent_id to an empty string
    setSearch((prevState) => ({ ...prevState, parent_id: '' }))
    localStorage.removeItem('parent_id_key')
  }

  useEffect(() => {
    dispatch(fetchExams())
    dispatch(fetchCompanies())
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.BLOOD_GROUPS))
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.GENDERS))
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.RELIGIONS))
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.MARITAL_STATUSES))
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.EDUCATION_TYPES))
    dispatch(fetchStaticItem(REST_API.EMPLOYEES_ROUTE.SEPARATION_TYPES))
  }, [dispatch])

  const {
    exams,
    companies,
    bloodGroups,
    genders,
    religions,
    educationTypes,
    maritalStatuses,
    separationsTypes,
    isLoading,
  } = useSelector(({ common, pagination, exams, loading }) => ({
    exams,
    companies: pagination[PAGINATION_TYPES.companies],
    bloodGroups: common[REST_API.EMPLOYEES_ROUTE.BLOOD_GROUPS],
    genders: common[REST_API.EMPLOYEES_ROUTE.GENDERS],
    religions: common[REST_API.EMPLOYEES_ROUTE.RELIGIONS],
    educationTypes: common[REST_API.EMPLOYEES_ROUTE.EDUCATION_TYPES],
    maritalStatuses: common[REST_API.EMPLOYEES_ROUTE.MARITAL_STATUSES],
    separationsTypes: common[REST_API.EMPLOYEES_ROUTE.SEPARATION_TYPES],
    isLoading:
      !!loading[PAGINATION_TYPES.companies] ||
      !!loading[KEYS.exams] ||
      !!loading[PAGINATION_TYPES.employees],
  }))

  const handleChange = (value = '', key) => {
    setSearch((prevState) => ({ ...prevState, [key]: value }))
  }

  const items = { ...FILTER_LIST }

  const inputToFilterMapping = useMemo(
    () => getInputToFilterMapping({ name: 'Employee' }, items),
    [items]
  )

  useEffect(() => {
    const filterResetValues = getFilterResetValues(url, inputToFilterMapping)
    const { age, birthday, confirmation_date, joining_date, ...rest } = filterResetValues || {}
    form.setFieldsValue({
      ...rest,
      ...(age && { age }),
      birthday: birthday && [moment(birthday[0]), moment(birthday[1])],
      joining_date: joining_date && [moment(joining_date[0]), moment(joining_date[1])],
      confirmation_date: confirmation_date && [
        moment(confirmation_date[0]),
        moment(confirmation_date[1]),
      ],
    })
  }, [inputToFilterMapping])

  const handleValuesChange = (changedValues) => {
    let updatedChangedValues
    const changedValuesKey = Object.keys(changedValues)[0]
    if (
      changedValuesKey === 'joining_date' ||
      changedValuesKey === 'confirmation_date' ||
      changedValuesKey === 'birthday'
    ) {
      updatedChangedValues = updatedChangeValues(changedValues, 'date_range')
    } else {
      updatedChangedValues = changedValues
    }
    localStorage.setItem(
      url,
      JSON.stringify(updateLocalStorageValues(updatedChangedValues, url, inputToFilterMapping))
    )
  }

  return (
    <>
      <Collapse onChange={callback} className="gx-mb-3">
        <Panel header="Filter Options" key="1" showArrow={false} extra={genExtra(setShowFilter)}>
          <Spin spinning={false}>
            <Form
              className="gx-w-100"
              form={form}
              name="employees_search"
              onValuesChange={handleValuesChange}
            >
              <Row gutter={[24, 8]} align="middle" className="gx-ml-3">
                <Col span={8}>
                  <Form.Item name="name" label="" className="gx-w-100">
                    <Input allowClear placeholder="Employee name" />
                  </Form.Item>
                </Col>
                {uiFilters?.map((labelKey, index) => {
                  const item = items[labelKey]
                  if (!item) return null
                  const { name, tree, label, placeholder } = item
                  return (
                    <Col
                      key={`filter-${index}`}
                      span={
                        name === 'birthday' ||
                        name === 'joining_date' ||
                        name === 'confirmation_date'
                          ? 11
                          : 8
                      }
                    >
                      {name === 'eid' &&
                        FormItem(name, label, <Input allowClear placeholder={placeholder} />)}
                      {name === 'parent_id' &&
                        FormItem(
                          name,
                          label,
                          <Col span={48} className="gx-w-100">
                            <SearchEmployeeBox
                              value={search?.parent_id || ''}
                              onChange={(item) =>
                                handleChange(item?.value?.split('#')[0] ?? '', 'parent_id')
                              }
                            />
                          </Col>
                        )}
                      {name === 'status' &&
                        FormItem(
                          name,
                          label,
                          <Form.Item name="status" valuePropName="checked" noStyle>
                            <Switch
                              checkedChildren={
                                <>
                                  <CheckOutlined /> Active
                                </>
                              }
                              unCheckedChildren={
                                <>
                                  Inactive <CloseOutlined />
                                </>
                              }
                              defaultChecked
                            />
                          </Form.Item>
                        )}
                      {name === 'blood_group_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={bloodGroups} placeholder={placeholder} />
                        )}
                      {name === 'service_length' &&
                        FormItem(name, label, <Slider min={1} max={50} />)}
                      {name === 'age' && FormItem(name, label, <Slider range min={18} max={62} />)}
                      {name === 'company_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={companies.items} placeholder={placeholder} />
                        )}
                      {name === 'gender_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={genders} placeholder={placeholder} />
                        )}
                      {name === 'marital_status_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={maritalStatuses} placeholder={placeholder} />
                        )}
                      {name === 'religion_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={religions} placeholder={placeholder} />
                        )}
                      {name === 'education_type_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={educationTypes} placeholder={placeholder} />
                        )}
                      {name === 'exam_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={exams} placeholder={placeholder} />
                        )}
                      {name === 'separation_id' &&
                        FormItem(
                          name,
                          label,
                          <SelectOption items={separationsTypes} placeholder={placeholder} />
                        )}
                      {(name === 'birthday' ||
                        name === 'joining_date' ||
                        name === 'confirmation_date') &&
                        FormItem(
                          name,
                          label,
                          <RangePicker
                            ranges={{
                              Today: [moment(), moment()],
                              'This Month': [moment().startOf('month'), moment().endOf('month')],
                            }}
                            showTime
                            format={DATE_FORMAT}
                          />
                        )}
                    </Col>
                  )
                })}
                <Col span={24} className="gx-pl-0">
                  <Button
                    type="primary"
                    icon={<SearchOutlined />}
                    loading={isLoading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((fieldValues) => {
                          const {
                            age,
                            service_length,
                            birthday,
                            joining_date,
                            confirmation_date,
                            status,
                            ...rest
                          } = fieldValues
                          const tree_ids = []
                          const values = _({
                            ...search,
                            ...rest,
                            age: age && { start: age[0], end: age[1] },
                            service_length: service_length && {
                              start: service_length,
                              end: service_length,
                            },
                            birthday: handleDate(birthday),
                            joining_date: handleDate(joining_date),
                            confirmation_date: handleDate(confirmation_date),
                            tree_ids,
                          })
                            .omitBy(_.isUndefined)
                            .omitBy(_.isNull)
                            .value()
                          if (Object.hasOwnProperty.call(fieldValues, 'status')) {
                            const element = fieldValues['status']
                            values.status = element === undefined || element
                          }
                          if (!tree_ids.length) {
                            delete values.tree_ids
                          }
                          if (search?.parent_id?.length) {
                            values.parent_id = search?.parent_id
                          } else {
                            localStorage.removeItem('parent_id_key')
                          }
                          onClick(values)
                        })
                        .catch((info) => {
                          console.error('Validate Failed:', info)
                        })
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    danger
                    icon={<CloseOutlined />}
                    disabled={isLoading}
                    onClick={() => handleClear()}
                  >
                    Clear
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Panel>
      </Collapse>
      <FilterList {...{ showFilter, setShowFilter, uiFilters, setUiFilters, items }} />
    </>
  )
}

FilterWidget.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default FilterWidget
