import React from 'react'
import { Route, Switch } from 'react-router-dom'
//
import ErrorPage404 from 'routes/errorPages/404'
import PrivateRoutesConfig from '../config/PrivateRoutesConfig'

const App = () => (
  <div className="gx-main-content-wrapper">
    <Switch>
      {PrivateRoutesConfig.map(({ path, component: Component }) => {
        return (
          <Route
            exact
            key={path}
            path={path}
            // eslint-disable-next-line react/jsx-props-no-spreading
            render={(props) => <Component {...props} path={path} />}
          />
        )
      })}
      <Route exact path="*" component={ErrorPage404} />
    </Switch>
  </div>
)

App.propTypes = {}

export default App
