import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
//
import CustomScrollbars from 'util/CustomScrollbars'
import { THEME_TYPE_LITE } from 'util/constants/ThemeSetting'
import UserInfo from 'components/UserInfo'
import PrivateRoutesConfig from 'config/PrivateRoutesConfig'
import SidebarLogo from './SidebarLogo'

const { SubMenu } = Menu

const SidebarContent = () => {
  const { pathname } = useSelector(({ common }) => common)
  const { themeType } = useSelector(({ uiSettings }) => uiSettings)

  const allowedRoutes = PrivateRoutesConfig

  const selectedKeys = pathname.substr(1).replace(/\/\d+$/, '')
  const pathSegments = selectedKeys.split('/')
  pathSegments.pop()
  const defaultOpenKeys = [pathSegments.join('/')]
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className="gx-sidebar-notifications">
          <UserInfo showName />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={defaultOpenKeys}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline"
          >
            {allowedRoutes.map(
              (child) =>
                child.isVisibleInSidebar &&
                (child?.children ? (
                  <SubMenu
                    key={child.path.substring(1)}
                    title={
                      <span>
                        <i className={`icon ${child.icon}`} />
                        <span>{child.title}</span>
                      </span>
                    }
                  >
                    {child.children.map((childOfChild) => (
                      <Menu.Item key={childOfChild.path.substring(1)}>
                        <Link to={childOfChild.path}>
                          <span>{childOfChild.title}</span>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={child.path.substring(1)}>
                    <Link to={child.path}>
                      {['/epr', '/kpi', '/leave', '/remote-work', '/attendance'].includes(
                        child.path
                      ) ? (
                          <img
                            width={20}
                            style={{ marginRight: 20, marginTop: -5 }}
                            src={child.icon}
                            alt="kpi"
                          />
                        ) : (
                          <i className={`icon ${child.icon}`} />
                        )}
                      <span className="gx-p-3">{child.title}</span>
                    </Link>
                  </Menu.Item>
                ))
            )}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  )
}

SidebarContent.propTypes = {}
export default SidebarContent
