import React from 'react'
import ReactDOM from 'react-dom'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import NextApp from './NextApp'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as serviceWorker from './registerServiceWorker'
// Add this import:
// import { CONFIG } from './config/constants'

// Sentry.init({
//   dsn: CONFIG.SENTRY_DSN,
//   integrations: [new Integrations.BrowserTracing()],
//   environment: CONFIG.SENTRY_ENV,
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// Disabled console function:
if (process.env.NODE_ENV === 'production') {
  window.console.log = () => {}
}

// Wrap the rendering in a function:
ReactDOM.render(<NextApp />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
