import React from 'react'
import PropTypes from 'prop-types'
import { Col, DatePicker, Form, Row, Select, Switch } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
//
import { DATE_FORMAT } from 'util/constants'

const SelectedOptions = ({ item, ...rest }) => {
  return (
    <Select allowClear placeholder="Select one" {...rest}>
      {item.map((item, index) => (
        <Select.Option key={index} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  )
}

SelectedOptions.propTypes = {
  item: PropTypes.array.isRequired,
}

const FORM_ITEM_LAYOUT = {
  labelCol: { span: 13 },
  wrapperCol: { span: 11 },
}

const EmployeeSeparationForm = ({ separationsTypes, separationsStatuses, separationsModes }) => {
  return (
    <Row>
      <Col xs={12}>
        <Form.Item {...FORM_ITEM_LAYOUT} name="resign_date" label="Resign Date">
          <DatePicker allowClear forma={DATE_FORMAT} />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="type_id" label="Separation Type">
          <SelectedOptions item={separationsTypes} />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="mode_id" label="Payment Mode">
          <SelectedOptions item={separationsModes} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item {...FORM_ITEM_LAYOUT} name="last_working_date" label="Last Working Date">
          <DatePicker allowClear forma={DATE_FORMAT} />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="status_id" label="Final Settlement Status">
          <SelectedOptions item={separationsStatuses} />
        </Form.Item>
        <Form.Item label="Experience Certificate Issued">
          <Form.Item name="cert_issued" valuePropName="checked" noStyle>
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Form.Item>
      </Col>
    </Row>
  )
}

EmployeeSeparationForm.defaultProps = {
  separationsTypes: [],
  separationsStatuses: [],
  separationsModes: [],
}

EmployeeSeparationForm.propTypes = {
  separationsTypes: PropTypes.array,
  separationsStatuses: PropTypes.array,
  separationsModes: PropTypes.array,
}
export default EmployeeSeparationForm
