/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Col, Row, Table, Tooltip, Button, Radio, Menu, Dropdown, Form, DatePicker, Select } from 'antd'
import {
  CheckCircleTwoTone,
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import { useRouteMatch } from 'react-router-dom'
//
import {
  getInitialSearch,
  setSearchInLocalStorage,
  showDeleteConfirm,
  sortColumnObject,
} from 'util/helpers'
import {
  fetchRemoteWorks,
  addOrUpdateRemoteWork,
  deleteRemoteWork,
  handleRemoteWorkActions,
  fetchStaticItem,
} from 'appRedux/actions'
import {
  PAGINATION_TYPES,
  PAGINATION_LIMIT,
  DATE_FORMAT,
  STATUS_BG,
  getDateFormat,
  getTimeFormat,
  TIME_FORMAT,
} from 'util/constants'
import { EmployeeSearchBox } from 'components/SearchBox'
import Widget from 'components/Widget'
import Auxiliary from 'util/Auxiliary'
import { REST_API } from 'services/rest.api'
import { RemoteWorkService } from 'services'
import FormModal from 'components/RemoteWorkFormModal'
import EmployeeCell from 'components/EmployeeCell'

const actions = {
  SUBMIT: '/submit',
  APPROVE: '/approve',
  REJECT: '/reject',
}

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'

const initialSearch = {
  employee_id: '',
  type_id: '',
  from_date_range: [],
  to_date_range: [],
}

const RemoteWork = () => {
  const REMOTEListType = localStorage.getItem('REMOTE_LIST_TYPE') ?? 'self'
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { remoteWorks, types, isLoading } = useSelector(({ pagination, common, loading }) => ({
    remoteWorks: pagination[PAGINATION_TYPES.remote_work],
    types: common[REST_API.remote_work_type],
    isLoading: loading[PAGINATION_TYPES.leave] || loading[PAGINATION_TYPES.remote_work],
    height: common.height, // TODO it will be use in table
  }))
  const [listType, setListType] = useState(REMOTEListType)
  const { url } = useRouteMatch()
  const [search, setSearch] = useState(getInitialSearch(initialSearch, url))
  const [visible, setVisible] = useState(false)
  const [editItem, setEditItem] = useState(undefined)
  const [current, setCurrent] = useState(1)
  const [sort, setSort] = useState(JSON.parse(localStorage.getItem(`${url}/sort`)) || '')
  const handleInitial = useCallback(
    (_page, limit = PAGINATION_LIMIT) => {
      setCurrent(_page)
      const { employee_id, ...rest } = search || {}
      const query = {
        sort: 'id,desc',
        limit,
        ...rest,
        page: _page,
        list_type: listType,
        ...(sort && { sort }),
        ...(listType !== 'self' && { employee_id }),
      }
      dispatch(fetchRemoteWorks(query))
    },
    [dispatch, listType, search, sort]
  )

  useEffect(() => {
    if (search) {
      setSearchInLocalStorage(search, url)
    }
  }, [search, url])

  useEffect(() => {
    if (sort) {
      localStorage.setItem(`${url}/sort`, JSON.stringify(sort))
    }
  }, [sort])

  useEffect(() => {
    handleInitial(1)
  }, [handleInitial])

  useEffect(() => {
    dispatch(fetchStaticItem(REST_API.remote_work_type, RemoteWorkService))
  }, [dispatch])

  const data = useMemo(() => remoteWorks?.items ?? [], [remoteWorks])
  const pagination = {
    current,
    hideOnSinglePage: true,
    position: 'both',
    total: remoteWorks.total,
    pageSizeOptions: [10, 20, 50],
    showQuickJumper: true,
    onChange: (page, pageSize) => handleInitial(page, pageSize),
  }

  const handleEdit = (item) => {
    const { type, from_time, to_time, to_date, from_date, ...rest } = item
    form.setFieldsValue({
      ...rest,
      type_id: type.id,
      from_date: getDateFormat(from_date),
      from: type.id === 1 ? getTimeFormat(from_time) : getDateFormat(from_date),
      to: type.id === 1 ? getTimeFormat(to_time) : getDateFormat(to_date),
    })
    setVisible(true)
    setEditItem(item)
  }

  const handleChange = (value = '', key) => {
    setSearch((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleDateChange = (dates, key) => {
    if (!dates?.length) {
      handleChange([], key)
      return
    }
    handleChange([dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')], key)
  }

  const handleDelete = (item) => {
    showDeleteConfirm(<ExclamationCircleOutlined />, 'remote work', () => {
      return dispatch(deleteRemoteWork(item.id))
    })
  }
  const handleActions = (item, TYPE) => {
    const values = {
      key: PAGINATION_TYPES.remote_work,
      route: actions[TYPE],
      id: item.id,
    }
    dispatch(handleRemoteWorkActions(values))
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      ...sortColumnObject('id', setSort, sort),
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      width: 180,
      render: (_) => {
        const { name, photo, eid } = _ ?? { name: '', photo: { small: 'https//' } }
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('employee_id', setSort, sort),
    },
    {
      title: 'Line Manager',
      dataIndex: 'line_manager',
      width: 180,
      render: (_) => {
        const { name, photo, eid } = _?.employee ?? { name: '', photo: { small: 'https//' } }
        if (!_?.employee) {
          return (
            <div className="gx-mb-0 gx-ml-1">
              <h4 className="gx-mb-0">{_?.name}</h4>
            </div>
          )
        }
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('line_manager_id', setSort, sort),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 120,
      render: (_) => _?.name,
      ...sortColumnObject('type_id', setSort, sort),
    },
    {
      title: 'From',
      dataIndex: 'from',
      width: 115,
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        const formatDate =
          record?.type?.id !== 1
            ? moment(record?.from_date).format('DD-MM-YYYY')
            : `${moment(record?.from_date).format('DD-MM-YYYY')}(${moment(record?.from_time).format('HH:mm')})`
        return <span>{formatDate}</span>
      },
      ...sortColumnObject('from', setSort, sort),
    },
    {
      title: 'To',
      dataIndex: 'to',
      width: 115,
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        const date =
          record?.type?.id !== 1
            ? moment(record?.to_date).format('DD-MM-YYYY')
            : moment(record?.to_time).format('HH:mm')
        return <span>{date}</span>
      },
      ...sortColumnObject('to_date', setSort, sort),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 115,
      // eslint-disable-next-line react/display-name
      render: (_) => (
        <Badge
          className="site-badge-count-109"
          count={_?.name}
          style={{ backgroundColor: STATUS_BG[_?.id] }}
        />
      ),
      ...sortColumnObject('status_id', setSort, sort),
    },
    {
      title: 'Action',
      width: 100,
      key: 'action',
      fixed: 'right',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        const statusID = record?.status?.id
        const menu = (
          <Menu>
            <Menu.Item key="edit" disabled={statusID !== 1} onClick={() => handleEdit(record)}>
              <EditOutlined />
              EDIT
            </Menu.Item>
            <Menu.Item key="delete" disabled={statusID !== 1} onClick={() => handleDelete(record)}>
              <DeleteOutlined />
              DELETE
            </Menu.Item>
            <Menu.Item
              key="submit"
              disabled={statusID !== 1}
              onClick={() => handleActions(record, 'SUBMIT')}
            >
              <CheckCircleTwoTone twoToneColor={STATUS_BG[2]} />
              SUBMIT
            </Menu.Item>
            <Menu.Item
              key="approve"
              disabled={statusID !== 2 || listType === 'self'}
              onClick={() => handleActions(record, 'APPROVE')}
            >
              <CheckCircleTwoTone twoToneColor={STATUS_BG[3]} />
              APPROVE
            </Menu.Item>
            <Menu.Item
              key="reject"
              disabled={statusID !== 2 || listType === 'self'}
              onClick={() => handleActions(record, 'REJECT')}
            >
              <CheckCircleTwoTone twoToneColor={STATUS_BG[4]} />
              REJECT
            </Menu.Item>
          </Menu>
        )
        return (
          <Dropdown
            arrow
            overlay={menu}
            placement="bottomCenter"
          >
            <ControlOutlined />
          </Dropdown>
        )
      },
    },
  ]

  const renderData = () => (
    <Row justify="center" align="middle">
      <Col span={5}>
        <Tooltip title="Create Remote Work Application">
          <Button
            shape="circle"
            type="primary"
            className="gx-mt-3"
            onClick={() => {
              setEditItem(null)
              setVisible(true)
            }}
          >
            <span className="gx-fs-xxl">+</span>
          </Button>
        </Tooltip>
        <span>
          <Radio.Group
            options={[
              { label: 'Self', value: 'self' },
              { label: 'Team', value: 'team' },
            ]}
            onChange={(e) => {
              setListType(e?.target?.value)
              localStorage.setItem('REMOTE_LIST_TYPE', e?.target?.value)
            }}
            value={listType}
            optionType="button"
            buttonStyle="solid"
          />
        </span>
      </Col>
      <Col span={5}>
        <EmployeeSearchBox
          searchFilter
          value={search?.employee_id}
          disabled={listType === 'self'}
          onChange={(item) => handleChange(item?.value?.split('#')[0], 'employee_id')}
        />
      </Col>
      <Col span={4}>
        <Select
          allowClear
          className="gx-w-100"
          placeholder="Select type"
          onChange={(item) => handleChange(item, 'type_id')}
          defaultValue={search?.type_id === '' ? null : search?.type_id}
          options={types?.map(i => ({ value: i.id, label: i.name }))}
        />
      </Col>
      <Col span={5}>
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          placeholder={['From start', 'From end']}
          format={DATE_FORMAT}
          onChange={(v) => handleDateChange(v, 'from_date_range')}
          defaultValue={search?.from_date_range?.length > 0 && [moment(search?.from_date_range[0], dateFormat), moment(search?.from_date_range[1], dateFormat)]}
        />
      </Col>
      <Col span={5}>
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          placeholder={['To start', 'To end']}
          format={DATE_FORMAT}
          onChange={(v) => handleDateChange(v, 'to_date_range')}
          defaultValue={search?.to_date_range?.length > 0 && [moment(search?.to_date_range[0], dateFormat), moment(search?.to_date_range[1], dateFormat)]}
        />
      </Col>
      <Col span={24} className="gx-mt-4">
        <Table
          loading={isLoading}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          rowKey={(item) => item.id}
          scroll={{ x: 1000 }}
        />
      </Col>
    </Row>
  )

  return (
    <Auxiliary>
      <Widget>{renderData()}</Widget>
      {visible && (
        <FormModal
          {...{
            visible,
            formName: 'remote-work-create-form',
            handleCancel: () => setVisible(false),
            handleOk: (fieldsValue) => {
              const { type_id } = fieldsValue
              const values = {
                ...fieldsValue,
                from_date: fieldsValue.from.format(DATE_FORMAT),
                to_date: fieldsValue.to.format(DATE_FORMAT),
              }
              if (type_id === 1) {
                // out of office id  = 1
                values.from_date = fieldsValue.from_date.format(DATE_FORMAT)
                values.to_date = fieldsValue.from_date.format(DATE_FORMAT)
                values.from_time = `${fieldsValue.from.format(TIME_FORMAT)}:00`
                values.to_time = `${fieldsValue.to.format(TIME_FORMAT)}:00`
              }
              if (editItem) values.id = editItem.id
              return dispatch(addOrUpdateRemoteWork(values))
            },
            title: !editItem ? 'Remote work application' : 'Update application',
            form,
            types,
          }}
        />
      )}
    </Auxiliary>
  )
}

export default RemoteWork
