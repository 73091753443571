import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Spin } from 'antd'
//
import { history } from 'appRedux/store'
import { showMessage } from 'util/helpers'
import { ALERT_TYPES } from 'util/constants'
import { wait } from 'appRedux/actions'

const FormModal = ({
  visible,
  formName,
  width,
  handleCancel,
  handleOk,
  title,
  form,
  onFieldsChange,
  component: Component,
}) => {
  const [loading, setLoading] = useState(false)

  const onValuesChange = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field)
      if (!error.length) {
        return
      }
      // Clear error message of field
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ])
    })
  }

  const handleSubmit = async (value) => {
    setLoading(true)
    try {
      const res = await handleOk(value)
      form.resetFields()
      handleCancel()
      wait(500)
      history.push(`/epr/${res?.id}`)
    } catch (error) {
      try {
        console.error('PRINT IN %s=====>', 'Form Submit First', error)
        const e_res = await error.response.json().then((res) => res)
        showMessage(ALERT_TYPES.ERROR, e_res.message)
        const errors = e_res.errors.children
        let data = []
        for (let v in value) {
          let i = {
            name: v,
            ...errors[v],
          }
          data.push(i)
        }
        form.setFields(data)
      } catch (error) {
        console.error('PRINT IN %s=====>', 'Form Submit', error)
        showMessage(ALERT_TYPES.WARN, 'Oops, Something Wrong!')
      }
    }
    setLoading(false)
  }
  return (
    <Modal
      visible={visible}
      width={width}
      title={title}
      okText="Submit"
      cancelText="Cancel"
      confirmLoading={loading}
      destroyOnClose
      onCancel={() => {
        form.resetFields()
        handleCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values)
          })
          .catch((info) => {
            console.error('Validate Failed:', info)
          })
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form} 
          onValuesChange={onValuesChange}
          onFieldsChange={onFieldsChange}
          name={formName}>
          <Component />
        </Form>
      </Spin>
    </Modal>
  )
}

FormModal.propTypes = {
  visible: PropTypes.bool,
  formName: PropTypes.string,
  width: PropTypes.number,
  title: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  handleOk: PropTypes.func.isRequired,
  component: PropTypes.any.isRequired,
  onFieldsChange: PropTypes.func.isRequired,
}

FormModal.defaultProps = {
  formName: 'dynamic_rule',
  visible: false,
  loading: false,
  width: 400,
}

export default FormModal
