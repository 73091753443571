import React from 'react'
import { Avatar } from 'antd'

const ReceivedMessageCell = ({ conversation, user }) => {
  return (
    <div className="gx-chat-item">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src={conversation.employee?.photo?.medium}
        alt={conversation.employee?.name}
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">{conversation.comment}</div>
        </div>
      </div>
    </div>
  )
}

export default ReceivedMessageCell
