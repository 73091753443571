import React from 'react'

import ReceivedMessageCell from './ReceivedMessageCell'
import SentMessageCell from './SentMessageCell'

const Conversation = ({ conversationData, selectedUser }) => {
  const res = localStorage.getItem('currentUser')
  const user = JSON.parse(res)
  const { employee_id } = user
  return (
    <div className="gx-chat-main-content">
      {conversationData.map((conversation, index) =>
        conversation?.employee?.id === employee_id ? (
          <SentMessageCell key={index} conversation={conversation} />
        ) : (
          <ReceivedMessageCell key={index} conversation={conversation} user={selectedUser} />
        )
      )}
    </div>
  )
}

export default Conversation
