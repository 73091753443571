import React, { memo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'

import { setThemeType, onNavStyleChange, onLayoutTypeChange } from 'appRedux/reducers/Settings'
import { userSignOut, userImpersonateSignIn } from 'appRedux/actions/Auth'
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  THEME_TYPE_DARK,
} from 'util/constants/ThemeSetting'
import AppLocale from 'lngProvider'
import MainApp from './MainApp'
import SignIn from '../SignIn'
import ForgotPassword from '../ForgotPassword'
import ResetPassword from '../ResetPassword'

// eslint-disable-next-line react/prop-types
const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) => (
  <Route
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    render={(props) =>
      // eslint-disable-next-line react/prop-types
      authUser ? (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: location },
          }}
        />
      )
    }
  />
)

const App = () => {
  const dispatch = useDispatch()
  const { locale, themeType, layoutType } = useSelector(({ uiSettings }) => uiSettings)
  const { authUser, initURL } = useSelector(({ auth }) => auth)

  const location = useLocation()
  const history = useHistory()
  const match = useRouteMatch()

  const setLayoutType = (_layoutType) => {
    if (_layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('full-layout')
    } else if (_layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('boxed-layout')
    } else if (_layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('full-layout')
      document.body.classList.add('framed-layout')
    }
  }

  const tokenCheck = useCallback(() => {
    const itemStr = localStorage.getItem('currentUser')
    if (!itemStr) {
      return false
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem('currentUser')
      dispatch(userSignOut())
      return false
    }
    return true
  }, [dispatch])

  const handleImpersonate = React.useCallback(async (user) => {
    if (authUser?.user) {
      await dispatch(userSignOut())
      localStorage.removeItem('currentUser')
    }
    dispatch(userImpersonateSignIn({ user: JSON.parse(user) }))
    history.push('/profile')
  }, [])
  useEffect(() => {
    if (initURL === '') {
      // dispatch(setInitUrl(location.pathname))
    }
    const params = new URLSearchParams(location.search)
    const user = params.get('user')
    if (location.pathname === '/impersonate' && user) {
      handleImpersonate(user)
    }
    if (params.has('theme')) {
      dispatch(setThemeType(params.get('theme')))
    }
    if (params.has('nav-style')) {
      dispatch(onNavStyleChange(params.get('nav-style')))
    }
    if (params.has('layout-type')) {
      dispatch(onLayoutTypeChange(params.get('layout-type')))
    }
    setLayoutType(layoutType)
  }, [initURL, location, layoutType, dispatch])

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser?.user === null) {
        history.push('/signin')
      } else {
        history.push('/profile')
      }
    }
  }, [authUser, initURL, location, history])

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme')
      document.body.classList.add('dark-theme')
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = '/css/dark_theme.css'
      link.className = 'style_dark_theme'
      document.body.appendChild(link)
    }
  }, [themeType])

  const currentAppLocale = AppLocale[locale.locale]

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
        <Switch>
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route path="/resetpassword/:token" component={ResetPassword} />
          <RestrictedRoute
            path={`${match.url}`}
            authUser={tokenCheck()}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  )
}

export default memo(App)
