import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'

// eslint-disable-next-line one-var, import/no-mutable-exports
let app, db, auth

export const firebaseInit = ({ apiKey, databaseURL, projectId, token }) => {
  // console.log('--------a key', apiKey);
  if (!firebase.apps.length) {
    app = firebase.initializeApp({ apiKey, databaseURL, projectId })
  } else {
    app = firebase.app()
  }

  // app = initializeApp({apiKey, databaseURL, projectId});
  // console.log('-app name-------', app?.name);
  // console.log('firebase app--------', app);
  app
    .auth()
    .signInWithCustomToken(token)
    .then(() => {
      // eslint-disable-next-line no-console
      console.info('firebase auth ---success->')
    })
    .catch((error) => {
      console.error('firebase auth ---->', error)
    })
  db = app.database()
}

export { db, auth }
