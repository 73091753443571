import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from 'antd'
import { TableOutlined, UnorderedListOutlined } from '@ant-design/icons'
import FilterWidget from '../FilterWidget'

const Header = ({ toggleView, onClick, clearSearch }) => {
  return (
    <div className="gx-d-flex">
      <Tooltip title="Card View">
        <Button icon={<TableOutlined />} onClick={() => toggleView('card')} />
      </Tooltip>
      <Tooltip title="Table View">
        <Button icon={<UnorderedListOutlined />} onClick={() => toggleView('table')} />
      </Tooltip>
      <div className="gx-w-100">
        <FilterWidget clearSearch={clearSearch} onClick={onClick} />
      </div>
    </div>
  )
}
Header.defaultProps = {
  clearSearch: () => {},
  onClick: () => {},
}

Header.propTypes = {
  toggleView: PropTypes.func.isRequired,
  clearSearch: PropTypes.func,
  onClick: PropTypes.func,
}

export default Header
