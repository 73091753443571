import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Table } from 'antd'
import moment from 'moment'
//
import Widget from '../../Widget'
const Achievements = ({ data }) => {
  const { achievements, skills, trainings } = data
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      // eslint-disable-next-line react/display-name
      render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ]
  const skillsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Efficiency',
      dataIndex: 'efficiency',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ]
  const trainingsColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ]

  return (
    <Row>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Achievements Information">
          <Table
            className="gx-w-100"
            columns={columns}
            dataSource={achievements}
            pagination={false}
            rowKey={(item) => item.id}
          />
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Trainings Information">
          <Table
            className="gx-w-100"
            columns={trainingsColumns}
            dataSource={trainings}
            pagination={false}
            rowKey={(item) => item.id}
          />
        </Widget>
      </Col>
      <Col span={24}>
        <Widget title="Skills Information">
          <Table
            className="gx-w-100"
            columns={skillsColumns}
            dataSource={skills}
            pagination={false}
            rowKey={(item) => item.id}
          />
        </Widget>
      </Col>
    </Row>
  )
}
Achievements.defaultProps = {
  data: {
    achievements: [],
    skills: [],
    trainings: [],
  },
}

Achievements.propTypes = {
  data: PropTypes.object,
}

export default Achievements
