/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'antd'
//
import Attendance from '../Attendance'
import Leave from '../Leave'
// import Group from '../Group'
// import Team from '../Team'

// eslint-disable-next-line no-unused-vars
const Home = ({ data, employeeId, employeeGroups }) => {
  return (
    <Row>
      <Col span={16}>
        <Attendance employeeId={employeeId} />
      </Col>
      <Col span={8}>
        <Leave data={data} />
      </Col>
      {/* <Col span={12}>
        <Group {...{ employeeGroups }} />
      </Col>
      <Col span={12}>
        <Team />
      </Col> */}
    </Row>
  )
}

Home.defaultProps = {
  data: {
    general: {},
    address: {},
  },
  employeeGroups: [],
}

Home.propTypes = {
  employeeId: PropTypes.string.isRequired,
  data: PropTypes.object,
  employeeGroups: PropTypes.array,
}

export default Home
