import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from 'antd'
//
import Chat from './Chat'

const ChatFormModal = ({ visible, item, width, handleCancel }) => {
  const { id, name, weight, comments, type, objectiveID } = item ?? {}
  return (
    <Modal
      visible={visible}
      width={width}
      footer={false}
      onCancel={() => handleCancel()}
      style={{ top: 20 }}
      destroyOnClose
    >
      <div className="gx-overflow-auto">
        <Chat {...{ id, name, weight, comments, type, objectiveID }} />
      </div>
    </Modal>
  )
}

ChatFormModal.propTypes = {
  visible: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
  width: PropTypes.number,
  handleCancel: PropTypes.func.isRequired,
}

ChatFormModal.defaultProps = {
  visible: false,
  width: 800,
  item: {},
}

export default ChatFormModal
