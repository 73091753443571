import { setLoading } from 'appRedux/reducers/Loading'
import { userSignInSuccess, userSignOutSuccess, setInitUrl } from 'appRedux/reducers/Auth'
import { AuthService } from 'services'
import PrivateRoutesConfig from 'config/PrivateRoutesConfig'
import { addCommonItems } from '../reducers/Common'
import { KEYS } from '../../util/constants'

export const userSignIn = (payload) => {
  return async (dispatch) => {
    const { email, password } = payload
    const loadingObj = { key: 'login', value: true }
    try {
      dispatch(setLoading(loadingObj))
      const signInUser = await AuthService.login({ username: email, password })
      if (signInUser?.user?.token) {
        const now = new Date()
        const item = {
          ...signInUser.user,
          expiry: now.getTime() + 22 * 60 * 60 * 1000,
        }
        localStorage.setItem('currentUser', JSON.stringify(item))
        dispatch(userSignInSuccess(signInUser))
        const allowedRoutes = PrivateRoutesConfig ?? []
        dispatch(setInitUrl(allowedRoutes[0]?.path))
      }
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Auth', e)
    } finally {
      loadingObj.value = false
      dispatch(setLoading(loadingObj))
    }
  }
}

export const userImpersonateSignIn = (payload) => {
  return async (dispatch) => {
    const { user } = payload ?? {}
    const loadingObj = { key: 'login', value: true }
    try {
      dispatch(setLoading(loadingObj))
      if (user?.token) {
        const now = new Date()
        const item = {
          ...user,
          expiry: now.getTime() + 24 * 60 * 60 * 1000,
        }
        localStorage.setItem('currentUser', JSON.stringify(item))
        dispatch(userSignInSuccess({ user }))
        dispatch(setInitUrl('/profile'))
      }
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Auth', e)
    } finally {
      loadingObj.value = false
      dispatch(setLoading(loadingObj))
    }
  }
}
export const userSignOut = () => {
  return async (dispatch) => {
    try {
      const signOutUser = await AuthService.logout()
      if (signOutUser.status === 200) {
        dispatch(userSignOutSuccess())
        localStorage.clear()
      } else {
        // dispatch(setMessage(signOutUser.message));
      }
    } catch (error) {
      // dispatch(setMessage(error));
    }
  }
}

export const fetchFirebaseConfig = () => {
  return async (dispatch) => {
    try {
      const res = await AuthService.getFirebaseInit()
      if (res?.firebase) {
        const { api_key: apiKey, url, ref, project_id: projectId, token } = res.firebase
        const data = {
          apiKey,
          databaseURL: url,
          projectId,
          ref,
          token,
        }
        dispatch(addCommonItems({ key: KEYS.FIREBASE, value: data }))
      }
    } catch (e) {
      console.error('PRINT IN %s=====>', 'fetchFirebaseConfig', e)
    }
  }
}
