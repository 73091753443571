/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
//
import Auxiliary from 'util/Auxiliary'
import FormModal from 'components/FormModal'
import {
  FORM_ITEM_LAYOUT,
  DATE_FORMAT,
  PAGINATION_TYPES,
  KEYS,
  EPR_KPI_STATUS_BG,
} from 'util/constants'
import {
  getKPIById,
  addOrUpdateObjective,
  deleteObjective,
  addOrUpdateObjectiveKPI,
  deleteObjectiveKPI,
  patchActions,
} from 'appRedux/actions'
import { selectPaginationItem } from 'appRedux/reducers/Pagination'
import Widget from 'components/Widget'
import { fetchStaticItem } from '../../appRedux/actions/Common'
import { REST_API } from '../../services/rest.api'
import { KpiService, ObjectiveKpiService, ObjectiveService } from '../../services'
import { showDeleteConfirm } from '../../util/helpers'
import ChatFormModal from '../../components/ChatFormModal'
import { fetchFirebaseConfig } from '../../appRedux/actions/Auth'
import { firebaseInit } from '../../services/Firebase'
import MessageIcon from '../../assets/images/icons/message-text.png'

const ObjectiveAddBtn = ({ setVisible, setEditItem }) => {
  return (
    <Tooltip title="Create Objective">
      <Button
        shape="circle"
        type="primary"
        className="gx-mt-3"
        onClick={() => {
          setEditItem(null)
          setVisible(true)
        }}
      >
        <span className="gx-fs-xxl">+</span>
      </Button>
    </Tooltip>
  )
}
const ObjectiveKPIAddBtn = ({ setVisibleObjectiveKpi, setEditObjectiveKpi }) => {
  return (
    <Tooltip title="Create KPI">
      <Button
        shape="circle"
        type="primary"
        className="gx-mt-3"
        onClick={() => {
          setVisibleObjectiveKpi(true)
          setEditObjectiveKpi(null)
        }}
      >
        <span className="gx-fs-xxl">+</span>
      </Button>
    </Tooltip>
  )
}
const checkWeight = (_, value) => {
  if (value > 0 && value < 101) {
    return Promise.resolve()
  }
  return Promise.reject(new Error('Weight must be 1 to 100!'))
}
const ObjectiveForm = () => {
  return (
    <>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="name"
        label="Objective"
        rules={[
          {
            required: true,
            message: 'Name must not be empty',
          },
          {
            max: 60,
            min: 4,
            message: 'Name must have a length between 4 and 60',
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="weight"
        label="Weight"
        rules={[
          {
            required: true,
            validator: checkWeight,
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
    </>
  )
}

const ObjectiveKPIForm = ({ target_units }) => {
  return (
    <>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="name"
        label="KPI"
        rules={[
          {
            required: true,
            message: 'Name must not be empty',
          },
          {
            max: 150,
            min: 4,
            message: 'Name must have a length between 4 and 150',
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="weight"
        label="Weight"
        rules={[
          {
            required: true,
            validator: checkWeight,
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="time"
        label="Time"
        rules={[
          {
            required: true,
            message: 'Time must not be empty',
          },
          {
            max: 150,
            min: 4,
            message: 'Time must have a length between 4 and 150',
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="target"
        label="Target"
        rules={[
          {
            required: true,
            message: 'Target must not be 0',
          },
        ]}
      >
        <InputNumber className="gx-w-100" min={1} />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="target_unit_id"
        label="Target unit"
        rules={[{ required: true }]}
      >
        <Select
          allowClear
          className="gx-w-100"
          placeholder="Select target unit"
          options={target_units?.map((v) => ({ value: v.id, label: v.name }))}
        />
      </Form.Item>
      <Form.Item {...FORM_ITEM_LAYOUT} name="budget" label="Budget">
        <InputNumber className="gx-w-100" min={1} />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="initiative"
        label="Initiative"
        rules={[
          {
            max: 150,
            min: 4,
            message: 'Initiative must have a length between 4 and 150',
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
    </>
  )
}
const KPIDetails = () => {
  const { kpiId } = useParams()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [chatItem, setChatItem] = useState(null)
  const [visibleObjectiveKpi, setVisibleObjectiveKpi] = useState(false)
  const [editObjectiveKpi, setEditObjectiveKpi] = useState(null)
  const [editItem, setEditItem] = useState(null)
  const [perspective, setPerspective] = useState(null)

  useEffect(() => {
    dispatch(getKPIById(kpiId))
    dispatch(fetchStaticItem(REST_API.objective_perspectives, ObjectiveService))
    dispatch(fetchStaticItem(REST_API.target_units, ObjectiveKpiService))
    dispatch(fetchFirebaseConfig())
  }, [kpiId, dispatch])

  const KPI = useSelector(selectPaginationItem({ key: PAGINATION_TYPES.kpi, id: kpiId }))
  const { authUser, firebaseConfig, isLoading, perspectives, target_units } = useSelector(
    ({ auth, common, loading }) => ({
      perspectives: common[REST_API.objective_perspectives],
      target_units: common[REST_API.target_units],
      isLoading: loading[PAGINATION_TYPES.kpi],
      firebaseConfig: common[KEYS.FIREBASE],
      authUser: auth?.authUser?.user ?? {},
    })
  )

  useEffect(() => {
    if (firebaseConfig.apiKey) {
      firebaseInit({ ...firebaseConfig })
    }
  }, [firebaseConfig])
  const handleEdit = (item, per, type) => {
    if (type === 'Objective') {
      form.setFieldsValue(item)
      setEditItem(item)
      setPerspective(per)
      setVisible(true)
    }
    if (type === 'ObjectiveKPI') {
      const { target_unit, ...rest } = item
      form.setFieldsValue({
        ...rest,
        target_unit_id: target_unit.id,
      })
      setEditObjectiveKpi(item)
      setEditItem(per)
      setVisibleObjectiveKpi(true)
    }
  }

  const handleDelete = (item, type) => {
    showDeleteConfirm(<ExclamationCircleOutlined />, item.name, () => {
      return dispatch(
        type === 'Objective' ? deleteObjective(item.id, kpiId) : deleteObjectiveKPI(item.id, kpiId)
      )
    })
  }
  const isLM = useMemo(() => authUser?.employee_id === KPI?.line_manager?.id, [authUser, KPI])
  const isOneOverOne = useMemo(
    () => authUser?.employee_id === KPI?.one_over_one?.id,
    [authUser, KPI]
  )
  const handleActions = (route) => {
    if (route !== 'Approve') {
      dispatch(patchActions(route, { kpi_id: kpiId }, PAGINATION_TYPES.kpi, KpiService))
    } else {
      const route_path = isLM
        ? REST_API.KPI_ACTIONS.KPI_APPROVE_LM
        : REST_API.KPI_ACTIONS.KPI_APPROVE
      dispatch(patchActions(route_path, { kpi_id: kpiId }, PAGINATION_TYPES.kpi, KpiService))
    }
  }

  const columns = [
    {
      title: 'KPI',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Weight',
      dataIndex: 'weight',
      key: 'weight',
      width: 80,
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      width: 120,
    },
    {
      title: 'Target unit',
      dataIndex: 'target_unit',
      key: 'target_unit',
      render: (_) => _?.name,
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (_) => {
        const color = _.id > 1 ? 'geekblue' : 'green'
        return <Tag color={color}>{_.name.toUpperCase()}</Tag>
      },
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      key: 'budget',
    },
    {
      title: 'Initiative',
      dataIndex: 'initiative',
      key: 'initiative',
    },
    {
      title: 'Action',
      width: 80,
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        const isDisabled = record?.status?.id === 3
        if (isDisabled) {
          return null
        }
        return (
          <Space size="small">
            <EditOutlined onClick={() => handleEdit(record, null, 'ObjectiveKPI')} />
            <Divider type="vertical" />
            <DeleteOutlined onClick={() => handleDelete(record, 'ObjectiveKPI')} />
          </Space>
        )
      },
    },
    {
      title: 'Comment',
      dataIndex: 'comments',
      width: 80,
      key: 'comments',
      render: (_, record) =>
        record?.status?.id !== 3 && (
          <Badge
            count={_?.length}
            offset={[5, -3]}
            className="gx-link"
            onClick={() => {
              setChatItem({ ...record, type: 'objective_kpi' })
            }}
            style={{ backgroundColor: EPR_KPI_STATUS_BG[4] }}
          >
            <Image src={MessageIcon} style={{ width: 14, height: 14 }} />
          </Badge>
        ),
    },
  ]
  const kpiStatusID = useMemo(() => KPI?.status?.id ?? '0', [KPI])
  const renderColumns = useMemo(() => {
    if ([2, 3, 4].includes(kpiStatusID) || KPI?.created_by?.id !== authUser?.id) {
      columns.splice(8, 1)
    }
    if ([1].includes(kpiStatusID)) {
      columns.splice(9, 1)
    }
    return columns
  }, [KPI, kpiStatusID, authUser, columns])

  const employee = useMemo(() => KPI?.employee, [KPI])
  const totalPerspective = (id, list = []) =>
    list.reduce((a, b) => {
      if (b.perspective.id === id && b?.status?.id !== 3) {
        // eslint-disable-next-line no-param-reassign
        a += b.weight
      }
      return a
    }, 0)
    console.log('PRINT IN %s=====>', 'KPI START ***', KPI?.objectives);

  return (
    <Auxiliary>
      <Spin spinning={isLoading}>
        <Widget>
          <Row align="middle" justify="space-between">
            <Col span={18}>
              <div className="gx-flex-row gx-flex-nowrap gx-align-items-center">
                <Avatar size={50} src={employee?.photo?.medium} icon={<UserOutlined />} />
                <div className="gx-mb-0 gx-ml-1">
                  <span className="gx-fs-xl gx-mb-0">{employee?.name}</span>
                  <span className="gx-fs-lg gx-mb-0 gx-ml-1">{employee?.eid}</span>
                </div>
              </div>
              <div>
                <span className="gx-fs-xxl gx-mr-1">{KPI?.name}</span>
                <span>
                  {`(${KPI?.type?.name} : ${moment(KPI?.from).format(DATE_FORMAT)}  ${moment(
                    KPI?.to
                  ).format(DATE_FORMAT)}) `}
                </span>
                <span>
                  <strong> Total weight: </strong>
                  {KPI?.total_objective_weight}
                </span>
              </div>
              <div className="gx-flex-row gx-align-items-center gx-fs-lg">
                Status:
                <Badge
                  className="gx-mt-2 gx-ml-2"
                  count={KPI?.status?.name}
                  style={{ backgroundColor: EPR_KPI_STATUS_BG[kpiStatusID] }}
                />
              </div>
            </Col>
            <Col className="gx-pr-5">
              {[1, 5].includes(kpiStatusID) && KPI?.created_by?.id === authUser?.id ? (
                <Button onClick={() => handleActions(REST_API.KPI_ACTIONS.KPI_SUBMIT)}>
                  Submit
                </Button>
              ) : (
                <>
                  {kpiStatusID === 2 && isLM && (
                    <>
                      <Button
                        onClick={() => handleActions(REST_API.KPI_ACTIONS.KPI_UPDATE_REQUEST)}
                      >
                        Update Request
                      </Button>
                      <Button onClick={() => handleActions('Approve')}>Approve</Button>
                    </>
                  )}
                  {kpiStatusID === 3 && isOneOverOne && (
                    <>
                      <Button
                        onClick={() => handleActions(REST_API.KPI_ACTIONS.KPI_UPDATE_REQUEST)}
                      >
                        Update Request
                      </Button>
                      <Button onClick={() => handleActions('Approve')}>Approve</Button>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </Widget>
        {perspectives?.map((per, i) => {
          return (
            <Widget key={`kpi_objective_key_${i}`}>
              <Row align="middle">
                <Col span={22}>
                  <span className="gx-fs-lg">
                    <strong>{per?.name?.toUpperCase()}</strong> (
                    {totalPerspective(per.id, KPI?.objectives)})
                  </span>
                </Col>
                <Col className="gx-pl-4">
                  {[1, 5].includes(KPI?.status?.id) && KPI?.created_by?.id === authUser?.id && (
                    <div onClick={() => setPerspective(per)}>
                      <ObjectiveAddBtn {...{ setVisible, setEditItem }} />
                    </div>
                  )}
                </Col>
              </Row>
              {KPI?.objectives?.map((obj, objIndex) => {
                const kpis = obj?.kpis?.map((kpi) => ({ ...kpi, objectiveID: obj.id })) ?? []
                const objStatus = obj?.status?.id // 1. active 2. approve 3. deleted
                const updatedColumns = objStatus === 3 ? renderColumns.slice(0, -2) : renderColumns
                if (obj.perspective.id === per.id) {
                  return (
                    <div key={`kpi_objective_perspective_key_${objIndex}`}>
                      <Row align="middle">
                        <Col span={22} className={objStatus === 3 && 'gx-text-strikethrough'}>
                          <Divider />
                          <span className="gx-fs-lg gx-mr-1">{`${obj?.name}`}</span>
                          <span className="gx-fs-xl gx-mr-1">({obj?.weight})</span>
                          {objStatus !== 3 &&
                            [1, 5].includes(KPI?.status?.id) &&
                            KPI?.created_by?.id === authUser?.id && (
                              <>
                                <EditOutlined onClick={() => handleEdit(obj, per, 'Objective')} />
                                <Divider type="vertical" />
                                <DeleteOutlined onClick={() => handleDelete(obj, 'Objective')} />
                              </>
                            )}
                          {objStatus !== 3 && KPI?.status?.id > 1 && (
                            <>
                              <Divider type="vertical" />
                              <Badge
                                count={obj?.comments?.length}
                                offset={[10, -3]}
                                className="gx-link"
                                onClick={() => {
                                  setChatItem({ ...obj, type: 'objective' })
                                }}
                                style={{ backgroundColor: EPR_KPI_STATUS_BG[4] }}
                              >
                                <Image src={MessageIcon} style={{ width: 14, height: 14 }} />
                              </Badge>
                            </>
                          )}
                        </Col>
                        <Col span={2} className="gx-pl-4">
                          {objStatus !== 3 &&
                            [1, 5].includes(KPI?.status?.id) &&
                            KPI?.created_by?.id === authUser?.id && (
                              <div onClick={() => setEditItem(obj)}>
                                <ObjectiveKPIAddBtn
                                  {...{ setVisibleObjectiveKpi, setEditObjectiveKpi }}
                                />
                              </div>
                            )}
                        </Col>
                      </Row>
                      <Row>
                        <Col span={1} />
                        <Col span={22} className="gx-mt-3">
                          <Table
                            rowKey={(item) => `${item?.id}`}
                            columns={updatedColumns}
                            dataSource={kpis}
                            pagination={false}
                            rowClassName={() => objStatus === 3 && 'gx-text-strikethrough'}
                          />
                        </Col>
                      </Row>
                    </div>
                  )
                }
                return null
              })}
            </Widget>
          )
        })}
        {visible && (
          <FormModal
            {...{
              visible,
              formName: 'kpi-object-create-form',
              handleCancel: () => setVisible(false),
              handleOk: (fieldsValue) => {
                const values = {
                  ...fieldsValue,
                }
                values.kpi_id = kpiId
                values.perspective_id = perspective.id
                if (editItem) values.id = editItem.id
                return dispatch(addOrUpdateObjective(values))
              },
              title: !editItem
                ? `Create ${perspective?.name} objective`
                : `Update ${perspective?.name} objective`,
              form,
              // eslint-disable-next-line react/display-name
              component: () => <ObjectiveForm perspectives={perspectives} />,
            }}
          />
        )}
        {visibleObjectiveKpi && (
          <FormModal
            {...{
              visible: visibleObjectiveKpi,
              formName: 'kpi-object-kpi-create-form',
              handleCancel: () => setVisibleObjectiveKpi(false),
              handleOk: (fieldsValue) => {
                const values = {
                  ...fieldsValue,
                }
                values.kpi_id = kpiId
                if (editItem) values.objective_id = editItem.id
                if (editObjectiveKpi) values.id = editObjectiveKpi.id
                return dispatch(addOrUpdateObjectiveKPI(values))
              },
              title: `${editItem?.name ? 'Create' : 'Update'} KPI`,
              form,
              // eslint-disable-next-line react/display-name
              component: () => <ObjectiveKPIForm target_units={target_units} />,
            }}
          />
        )}
        {!!chatItem && (
          <ChatFormModal
            {...{
              visible: !!chatItem,
              item: chatItem,
              formName: 'kpi-chat-form',
              handleCancel: () => setChatItem(null),
              title: 'Chat...',
              form,
            }}
          />
        )}
      </Spin>
    </Auxiliary>
  )
}

export default KPIDetails
