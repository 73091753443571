import { ServiceBase } from './ServiceBase'
import request from 'util/request'
import { FETCH_TYPES } from 'util/constants'

export class PaySchedules extends ServiceBase {
  async patchItem(route, id) {
    const options = { method: FETCH_TYPES.PATCH, body: { pay_schedule_id: id } }
    return await request(this.API_ENDPOINT + route, options)
  }
}
