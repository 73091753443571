import React from 'react'
import { connect } from 'react-redux'
import {
  Avatar, Divider, Select, Spin,
} from 'antd'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { UserOutlined } from '@ant-design/icons'
//
import { EmployeeService } from 'services'
import { addCommonItems } from 'appRedux/reducers/Common'
import { KEYS } from 'util/constants'

const { Option } = Select
class TeamSearch extends React.Component {
  constructor(props) {
    super(props)
    this.lastFetchId = 0
    this.fetchTeam = debounce(this.fetchTeam, 600)
    this.state = {
      data: [],
      value: undefined,
      fetching: false,
    }
  }

  async componentDidMount() {
    const { value, onChange } = this.props
    if (value) {
      const res = await EmployeeService.findById(value)
      const {
        eid, id, name, photo,
      } = res || {}
      const data = [{
        eid,
        image: photo,
        text: name,
        value: `${id}#${name}#${eid}`,
      }]
      this.setState({
        data,
        fetching: false,
        value: data[0]?.value ? { value: data[0]?.value } : value,
      })
      if (onChange && data[0]?.value) {
        onChange({ value: data[0]?.value })
      }
    }
  }

  fetchTeam = (value) => {
    const authUser = JSON.parse(localStorage.getItem('currentUser'))
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ data: [], fetching: true })
    EmployeeService.teamSearch({ id: authUser?.employee_id, type: 'all', full_text: value }).then((res) => {
      if (fetchId !== this.lastFetchId) {
        // for fetch callback order
        return
      }
      const data = res
        ?.items
        ?.filter((user) => user?.id !== JSON.parse(localStorage.getItem('currentUser'))?.employee_id)
        ?.map((user) => ({
          text: user?.name,
          value: `${user?.id}#${user?.name}#${user?.eid}`,
          eid: user?.eid,
          image: user?.photo,
        })) ?? []
      this.setState({ data, fetching: false })
    })
  }

  handleChange = (value) => {
    const { data } = this.state
    const { dispatch } = this.props
    const filtered = data?.filter((x) => x?.value === value?.value)[0]
    const employee = {
      name: filtered?.text,
      id: filtered?.value?.split('#')[0],
      ...filtered,
    }
    dispatch(addCommonItems({ key: KEYS.selectedEmployee, value: employee }))
    this.setState({
      value,
      data: [],
      fetching: false,
    })
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { disabled, placeholder } = this.props
    const { fetching, data, value } = this.state
    return (
      <Select
        showSearch
        allowClear
        labelInValue
        disabled={disabled}
        value={value}
        defaultActiveFirstOption={false}
        showArrow={false}
        placeholder={placeholder}
        // placeholder="Select employee"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        onSearch={this.fetchTeam}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {data?.map((d) => (
          <Option key={d?.value}>
            <Avatar
              size={20}
              src={d?.image?.small}
              icon={<UserOutlined />}
            />
            <Divider type="vertical" />
            {d?.text}
            <small>
              {' '}
              (
              {d?.eid}
              )
            </small>
          </Option>
        ))}
      </Select>
    )
  }
}

TeamSearch.defaultProps = {
  value: '',
  disabled: false,
  onChange: () => {},
  dispatch: () => {},
  placeholder: 'Select responsible person',
}

TeamSearch.propTypes = {
  onChange: PropTypes.func,
  dispatch: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
}

// eslint-disable-next-line import/prefer-default-export
export const TeamSearchBox = connect()(TeamSearch)
