import React from 'react'
import PropTypes from 'prop-types'
import { DeleteOutlined } from '@ant-design/icons'
//
import Widget from 'components/Widget'

const GroupCard = (props) => {
  const { name, group, bgColor, handleDelete } = props
  return (
    <Widget styleName={'gx-card-full'}>
      <div className={`gx-fillchart gx-bg-${bgColor} gx-overlay-fillchart`}>
        <div className="gx-fillchart-content">
          <div className="ant-card-head-title gx-mb-1">{group}</div>
          <h2 className="gx-mb-0 gx-fs-xxxl gx-font-weight-medium">{name}</h2>
        </div>
        <div className="gx-fillchart-btn" onClick={() => handleDelete()}>
          <DeleteOutlined />
        </div>
      </div>
    </Widget>
  )
}

GroupCard.propTypes = {
  handleDelete: PropTypes.func,
  name: PropTypes.string,
  group: PropTypes.string,
  bgColor: PropTypes.string,
}

export default GroupCard
