/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Descriptions, Divider, Row, Space, Table, Tooltip } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import Widget from '../Widget'

const SectionD = ({ section_d, handleEdit, handleDelete, eprStatusID, isLM, isSelfEPR }) => {
  const {
    self_proposal,
    remarks,
    dev_areas,
    lm_recommendation,
    lm_remarks,
    lm_dev_areas,
    next_level_remarks,
    dev_plans,
  } = section_d

  const columns = [
    {
      title: 'Development area',
      dataIndex: 'dev_area',
      key: 'dev_area',
    },
    {
      title: 'Action plan',
      dataIndex: 'action_plan',
      key: 'action_plan',
    },
    {
      title: 'When',
      dataIndex: 'when',
      key: 'when',
    },
    {
      title: 'Who',
      dataIndex: 'who',
      key: 'who',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="small">
          <EditOutlined
            onClick={() => {
              if (![2].includes(eprStatusID)) return null
              handleEdit({ ...record, section_d_id: section_d.id, dev_plan_id: record.id })
            }}
          />
          <Divider type="vertical" />
          <DeleteOutlined
            onClick={() => {
              if (![2].includes(eprStatusID)) return null
              handleDelete(record)
            }}
          />
        </Space>
      ),
    },
  ]
  const renderColumns = isLM ? columns : columns.slice(0, -1)
  const EonColumns = [
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    // {
    //   title: 'Proposal',
    //   dataIndex: 'proposal',
    //   key: 'proposal',
    // },
    // {
    //   title: 'Development Area',
    //   dataIndex: 'dev_areas',
    //   key: 'dev_areas',
    //   render: (areas) => dev_areas?.map((v) => (
    //     <span key={v.name}>{v.name}, </span>
    //   ))
    // }
  ]
  const EonData = [
    {
      comment: remarks,
      // proposal: self_proposal,
      // dev_areas: dev_areas,
    },
  ]
  const LMColumns = [
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
    // {
    //   title: 'Next level remarks',
    //   dataIndex: 'next_level_remarks',
    //   key: 'next_level_remarks',
    // },
    // {
    //   title: 'Recommendation',
    //   dataIndex: 'recommendation',
    //   key: 'recommendation',
    // },
    // {
    //   title: 'Development Area',
    //   dataIndex: 'dev_areas',
    //   key: 'dev_areas',
    //   render: (areas) => areas?.map((v) => (
    //     <span key={v.name}>{v.name}, </span>
    //   ))
    // }
  ]
  const LMData = [
    {
      comment: lm_remarks,
      // recommendation: lm_recommendation,
      // next_level_remarks: next_level_remarks,
      // dev_areas: lm_dev_areas,
    }
  ]
  return (
    <Row>
      <Col span={24}>
        <Widget>
          <Table
            columns={EonColumns}
            dataSource={EonData}
            pagination={false}
            bordered
            title={() => (<div className='gx-d-flex gx-justify-content-between'>
            <div> Eoner's</div>
            {isSelfEPR && (
              <div>
                <EditOutlined
                  onClick={() => {
                    if (![1, 5].includes(eprStatusID)) return null
                    handleEdit({ remarks, self_proposal, dev_areas })
                  }}
                />
              </div>
            )}
            </div>
            )}
          />
        </Widget>
      </Col>
      <Col span={24}>
        <Widget>
          <Table
            columns={LMColumns}
            dataSource={LMData}
            pagination={false}
            bordered
            title={() => (<div className='gx-d-flex gx-justify-content-between'>
            <div> Line manager</div>
            {isLM && [2].includes(eprStatusID) && (
              <div>
                <EditOutlined
                  onClick={() => {
                    if (eprStatusID !== 2) return null
                    handleEdit(section_d)
                  }}
                />
              </div>
            )}
            </div>
            )}
          />
        </Widget>
      </Col>
      <Col span={24}>
        <Widget>
          <Table
            rowKey={(item) => `${item?.id}`}
            columns={renderColumns}
            dataSource={dev_plans}
            pagination={false}
            bordered
            title={() => (<div className='gx-d-flex gx-justify-content-between gx-align-items-center'>
            <div> INDIVIDUAL DEVELOPMENT PLAN (IDP)</div>
            {isLM && [2].includes(eprStatusID) && (
                <Button
                  shape="circle"
                  disabled={![2].includes(eprStatusID)}
                  type="primary"
                  className=" gx-m-0"
                  onClick={() => {
                    // eslint-disable-next-line react/prop-types
                    handleEdit({ section_d_id: section_d.id })
                  }}
                >
                  <span className="gx-fs-xxl">+</span>
                </Button>
            )}
            </div>
            )}
          />
        </Widget>
      </Col>
    </Row>
  )
}

SectionD.propTypes = {
  isLM: PropTypes.bool.isRequired,
  eprStatusID: PropTypes.number.isRequired,
  isSelfEPR: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  section_d: PropTypes.shape({
    id: PropTypes.number,
    self_proposal: PropTypes.string,
    remarks: PropTypes.string,
    dev_areas: PropTypes.array,
    lm_recommendation: PropTypes.string,
    lm_remarks: PropTypes.string,
    lm_dev_areas: PropTypes.array,
    next_level_remarks: PropTypes.string,
    dev_plans: PropTypes.array,
  }),
}

SectionD.defaultProps = { 
  section_d: PropTypes.shape({
    id: 0,
    self_proposal: '',
    remarks: '',
    dev_areas: [],
    lm_recommendation: '',
    lm_remarks: '',
    lm_dev_areas: [],
    next_level_remarks: '',
    dev_plans: [],
  }),
}
export default SectionD
