import { message, Modal } from 'antd'
import _ from 'lodash'
import moment from 'moment'
//
import { ALERT_TYPES } from 'util/constants'

const { confirm } = Modal

export const capitalizeFirstLetter = (string) =>
  !!string && string.charAt(0).toUpperCase() + string.slice(1)
export const encodeQuery = (data) => {
  let query = '?'
  // eslint-disable-next-line no-restricted-syntax
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      query += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}&`
    }
  }
  return query.slice(0, -1)
}
export const getBase64 = (img, callback) =>
  new Promise(function (resolve, reject) {
    let reader = new FileReader()
    reader.readAsDataURL(img)
    reader.onload = () => resolve(callback(reader.result))
    reader.onerror = (error) => reject('Error: ', error)
  })

// export function getBase64ToDataUrl(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function() {
//     var reader = new FileReader();
//     reader.onloadend = function() {
//       callback(reader.result);
//     };
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url);
//   xhr.responseType = 'blob';
//   xhr.send();
// }
export const getBase64ToDataUrl = (url, fileType, callback) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          blob = blob.slice(0, blob.size, fileType)
          const reader = new FileReader()
          reader.onloadend = () => resolve(callback(reader.result))
          reader.onerror = reject()
          reader.readAsDataURL(blob)
        })
    )

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!')
  }
  // const isLt1M = file.size / 1024 / 1024 < 1;
  const isLt200K = file.size / 1024 < 200
  if (!isLt200K) {
    message.error('Image must smaller than 200KB!')
  }
  return isJpgOrPng && isLt200K
}

export function showMessage(type = 'error', text = '', time = 5) {
  switch (type) {
    case ALERT_TYPES.ERROR:
      message.error(text, time)
      break
    case ALERT_TYPES.SUCCESS:
      message.success(text, time)
      break
    case ALERT_TYPES.INFO:
      message.info(text, time)
      break
    case ALERT_TYPES.WARN:
      message.warn(text, time)
      break

    default:
      break
  }
}

export const showDeleteConfirm = (icon, name = '', myCallback) => {
  confirm({
    title: 'Do you want to delete this item?',
    icon,
    content: `Your Selected Item is ${name} !`,
    onOk() {
      return new Promise((resolve, reject) => {
        resolve(myCallback())
      })
        .then((res) => {
          res?.success && showMessage(ALERT_TYPES.SUCCESS, `${name} has been successfully deleted!`)
        })
        .catch((e) => console.error('Oops errors!', e))
    },
    onCancel() {},
  })
}
export const getChildren = (data) => {
  return data
    .map((g) => ({
      ...g,
      tree: g.tree.map((t) => ({
        ...t,
        children: [],
      })),
    }))
    .map((g, i) => {
      const children =
        g.tree &&
        g.tree.length > 0 &&
        g.tree
          .map((t, i) => {
            if (t.parent) {
              let p = g.tree.findIndex((item) => item.id === t.parent.id)
              if (p !== -1) {
                if (g.tree[p].children && g.tree[p].children.length > 0) {
                  const newArray = [...g.tree[p].children, t]
                  g.tree[p].children = newArray
                } else {
                  g.tree[p].children = [t]
                }
              }
            }
            return t
          })
          .filter((el) => el.parent === null)
      const { tree, ...rest } = g
      return { ...rest, children }
    })
}

export const renderTreeData = (data, _preKey) => {
  return data.map((item, index) => {
    const preKey = _preKey || '0'
    const { id, name, children, ...rest } = item
    const key = `${preKey}-${index}`
    if (children && children.length > 0) {
      return {
        id: item.id,
        isGroup: key.split('-').length === 2,
        title: name,
        name,
        key,
        children: renderTreeData(children, key),
        ...rest,
      }
    }
    return {
      id: item.id,
      isGroup: key.split('-').length === 2,
      title: name,
      name,
      ...rest,
      key,
    }
  })
}

export const getTreeId = (key, tree) => {
  let treeId
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        const item = _.find(node.children, (item) => item.key === key)
        treeId = item.id
      } else if (getTreeId(key, node.children)) {
        treeId = getTreeId(key, node.children)
      }
    }
  }
  return treeId
}
export const getNewGroups = (keys = [], tree = []) => {
  let groups = []
  for (let index = 0; index < keys.length; index++) {
    const element = keys[index]
    const i = tree.filter((g) => g.tree.some((t) => t.id === element.id))
    groups.push(...i)
  }

  const negative_intersection_filter = (function (a, b) {
    // create a map to speed up the filtering later
    const map = a.reduce((map, current) => {
      // perform the intersection
      map[current.id] = b.some((item) => item.id === current.id)
      return map
    }, {})
    return (item) => !map[item.id]
  })(tree, groups)
  return tree.filter(negative_intersection_filter)
}

export const getParentKey = (key, tree) => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

export const getParent = (key, tree) => {
  let parent
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parent = node
      } else if (getParent(key, node.children)) {
        parent = getParent(key, node.children)
      }
    }
  }
  return parent
}

const dataList = []
export const generateList = (data) => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i]
    const { key, title } = node
    dataList.push({ key, title })
    if (node.children) {
      generateList(node.children)
    }
  }
  return dataList
}

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max))

export const swapElement = (array, from, to) => {
  const arr = [...array]
  ;[arr[from], arr[to]] = [arr[to], arr[from]]
  return arr
}

export const isArrayWithLength = (arr) => Array.isArray(arr) && arr.length

export const getAllowedRoutes = (routes) => {
  return routes.filter(({ permission }) => isShowForPermission(permission))
}

export const isShowForPermission = (permission) => {
  const roles = JSON.parse(localStorage.getItem('currentUser'))
  if (!permission) return true
  else if (!isArrayWithLength(permission)) return true
  else return _.intersection(permission, roles?.roles).length
}

export const waitPromise = (milliSeconds = 1500) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve({ success: true })
    }, milliSeconds)
  )
}
export const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current > moment().endOf('day')
}

export const showTotal = (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`;
/**
 * useEffect(( {source} ) => {
    const subscription = source.subscribe();
    return () => {
      // Clean up the subscription
      subscription.unsubscribe();
    };
  });
 */

/* Filter Options Persist Start */

export const getLocalStorageItems = (url) => {
  try {
    const res = localStorage.getItem(url) ?? '[]'
    return JSON.parse(res)
  } catch (e) {
    console.error(e)
  }
  return []
}
export const getFilterResetValues = (url, inputToFilterMapping) => {
  const result = {}
  const filteredValues = getLocalStorageItems(url)
  filteredValues.forEach((filteredValue) => {
    const filteredValueKey = Object.keys(filteredValue)[0]
    const inputKey = Object.keys(inputToFilterMapping)
      .find((inputKeyy) => inputToFilterMapping[inputKeyy] === filteredValueKey)
    result[inputKey] = filteredValue[filteredValueKey]
  })
  return result
}
export const updatedChangeValues = (changedValues, type) => {
  const changedValuesKey = Object.keys(changedValues)[0]
  if (type === 'select') {
    return { [changedValuesKey]: parseInt(changedValues?.employee_id?.value?.split('#')[0], 10) }
  }
  if (type === 'date') {
    return {
      [changedValuesKey]: Object.values(changedValues)[0] ? Object.values(changedValues)[0].format() : null,
    }
  }
  if (type === 'date_range') {
    return {
      [changedValuesKey]: Object.values(changedValues)[0]
        ? [Object.values(changedValues)[0][0].format(), Object.values(changedValues)[0][0].format()]
        : null,
    }
  }
  return false
}

export const getFilterObjectsWithDefaultKeys = (params) => {
  const { defaultKeys, updatedFilterObjects, url } = params || {}
  const localStorageItems = getLocalStorageItems(url)
  const filterObjects = updatedFilterObjects
    ?.filter((obj) => !Object.keys(obj).some((key) => defaultKeys.includes(key)))
  const defaultFilterObjects = defaultKeys.map((key) => {
    const item = localStorageItems.find((itemm) => key in itemm)
    return { [key]: item ? item[key] : null }
  })
  return [
    ...filterObjects,
    ...defaultFilterObjects
  ]
}
export const updateLocalStorageKeys = (
  filterObjects,
  uiFilters,
  url,
  defaultKeys = ['Employee'],
  givenKeys = []
) => {
  try {
    const updatedFilterObjects = uiFilters?.map(uiFilter => {
      const filterObject = filterObjects.find(input => Object.keys(input)[0] === uiFilter) || { [uiFilter]: null }
      if (givenKeys.includes(uiFilter)) {
        filterObject[uiFilter] = true
      }
      return filterObject
    })
    localStorage.setItem(url, JSON.stringify(
      getFilterObjectsWithDefaultKeys({
        url,
        defaultKeys,
        updatedFilterObjects,
      }),
    ))
  } catch (e) {
    console.error(e)
  }
}
export const updateLocalStorageValues = (updatedChangedValues, url, inputToFilterMapping) => {
  const localStorageItems = getLocalStorageItems(url)
  const updateKey = Object.keys(updatedChangedValues)[0]

  return localStorageItems?.map((obj) => {
    const mappedKey = inputToFilterMapping[updateKey]
    if (mappedKey in obj) {
      if (updatedChangedValues[updateKey]) {
        obj[mappedKey] = updatedChangedValues[updateKey]
      } else {
        obj[mappedKey] = null
      }
      return obj
    }
    return obj
  })
}
export const resetLocalStorageFilters = (filterObjects, url) => {
  localStorage.setItem(url, JSON.stringify(filterObjects?.map((obj) => {
    const keys = Object.keys(obj)
    return { [keys[0]]: null }
  })))
}
export const getInputToFilterMapping = (defaultFilterObject, items) => {
  const inputToFilterMapping = defaultFilterObject
  for (const key in items) {
    const { name, label } = items[key]
    inputToFilterMapping[name] = label
  }
  return inputToFilterMapping
}

export const setSearchInLocalStorage = (searchObject, url) => {
  try {
    localStorage.setItem(url, JSON.stringify(searchObject))
  } catch (e) {
    console.error(e)
  }
}
export const getInitialSearch = (defaultSearch, url) => {
  const search = defaultSearch
  Object.entries(getLocalStorageItems(url)).forEach(([key, value]) => {
    if (value) {
      search[key] = value
    }
  })
  return search
}

export const sortKeyChange = (direction, key) => {
  if (direction === 'ascend') {
    return `${key},asc`
  }
  return `${key},desc`
}
export const getDefaultSortOrder = (key, sort) => {
  if (sort.split(',').includes(key)) {
    if (sort.includes('desc')) {
      return 'descend'
    }
    return 'ascend'
  }
  return undefined
}
export const sortColumnObject = (key, setSort, sort) => ({
  sorter: (a, b, c) => setSort(sortKeyChange(c, key)),
  sortDirections: ['descend', 'ascend', 'descend'],
  defaultSortOrder: getDefaultSortOrder(key, sort),
})

/* Filter Options Persist End */

