/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Spin, Tabs } from 'antd'
//
import Auxiliary from 'util/Auxiliary'
import { EMPLOYEE_TAB } from 'util/constants'
import { fetchStaticItem, userSignOut } from 'appRedux/actions'
import { EmployeeBanner } from 'components/Employee'
import Widget from 'components/Widget'
import {
  Home,
  General,
  Personal,
  EmploymentHistory,
  Achievements,
  Assets,
  Educations,
  Document,
  Separations,
  Activity,
} from 'components/Employee/tabs'
import ScrollButton from 'components/ScrollButton'
import { EmployeeService } from 'services'

const UserProfile = () => {
  const { profileId } = useParams()

  const res = localStorage.getItem('currentUser')
  const user = JSON.parse(res)
  const { employee_id } = user
  const employeeId = React.useMemo(
    () => (!profileId ? employee_id : profileId),
    [profileId, employee_id]
  )
  const dispatch = useDispatch()
  const [activeTabKey, setActiveTabKey] = useState('profile')

  useEffect(() => {
    if (!employeeId) {
      dispatch(userSignOut())
    }
    dispatch(fetchStaticItem(`EMPLOYEE_/${employeeId}`, EmployeeService))
  }, [employeeId, dispatch])

  const { employee, isLoading } = useSelector(({ common, loading }) => ({
    employee: common[`EMPLOYEE_/${employeeId}`],
    isLoading: !!loading[`EMPLOYEE_/${employeeId}`],
  }))

  const { data, groups: employeeGroups } = employee || {}

  const getActiveTab = (tabKey) => {
    switch (tabKey) {
      case 'profile':
        return <Home {...{ data, employeeId, employeeGroups }} />
      case 'general':
        return <General {...{ data, employeeGroups }} />
      case 'personal':
        return <Personal data={data} />
      case 'employmentHistory':
        return <EmploymentHistory {...{ data }} />
      case 'achievements':
        return <Achievements data={data} />
      case 'activity':
        return <Activity {...{ employeeId }} />
      case 'educations':
        return <Educations {...{ data }} />
      case 'assets':
        return <Assets {...{ data }} />
      case 'documents':
        return <Document {...{ data }} />
      case 'separations':
        return <Separations {...{ data }} />
      default:
        return null
    }
  }
  return (
    <Auxiliary>
      <Spin spinning={isLoading}>
        <EmployeeBanner {...{ user: employee }} />
        <Widget
          className="gx-card-tabs"
          style={{ width: '100%', borderRadius: 0 }}
          tabList={EMPLOYEE_TAB}
          activeTabKey={activeTabKey}
          onTabChange={(key) => {
            setActiveTabKey(key)
          }}
        >
          <Tabs
            className="gx-card-tabs"
            defaultActiveKey="1"
            onChange={(key) => setActiveTabKey(key)}
          >
            {EMPLOYEE_TAB.map((tab) => (
              <Tabs.TabPane tab={<span className="gx-jr-db-label">{tab.tab}</span>} key={tab.key} />
            ))}
          </Tabs>
        </Widget>
        <div className="gx-mt-5">{data && getActiveTab(activeTabKey)}</div>
        <ScrollButton />
      </Spin>
    </Auxiliary>
  )
}

export default UserProfile
