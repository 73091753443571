import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Table } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const EmployeesTable = ({ data, pagination, isLoading, handleChangeTable }) => {
  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      // sorter: () => {},
      // eslint-disable-next-line react/display-name
      render: (name, { id, photo: url, designation }) => {
        return (
          <Link to={`/profile/${id}`}>
            <div className="gx-flex-row gx-align-items-center">
              <Avatar src={url?.small} icon={<UserOutlined />} />
              <div className="gx-mb-0 gx-ml-1">
                <h4 className="gx-mb-0 gx-link">{name}</h4>
                <p className="gx-text-grey gx-mb-0">{designation}</p>
              </div>
            </div>
          </Link>
        )
      },
    },
    {
      title: 'Employee ID',
      dataIndex: 'eid',
      // sorter: () => {},
      key: 'eid',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      // sorter: () => {},
      key: 'email',
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      // sorter: () => {},
      key: 'mobile',
    },
  ]

  return (
    <Table
      className="gx-w-100"
      loading={isLoading}
      columns={columns}
      pagination={pagination}
      dataSource={data}
      rowKey={(item) => item?.id}
      onChange={handleChangeTable}
    />
  )
}

EmployeesTable.propTypes = {
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  isLoading: PropTypes.bool,
  handleDelete: PropTypes.func.isRequired,
  handleChangeTable: PropTypes.func,
}

export default EmployeesTable
