/* eslint-disable no-restricted-syntax */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable camelcase */
/* eslint-disable prefer-template */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Form, Modal, Radio, Select, Spin } from 'antd'
import moment from 'moment'
//
import { history } from 'appRedux/store'
import { showMessage } from 'util/helpers'
import { ALERT_TYPES, DATE_FORMAT, FORM_ITEM_LAYOUT } from 'util/constants'
import { wait } from 'appRedux/actions'

const FormModal = ({
  visible,
  formName,
  width,
  handleCancel,
  handleOk,
  title,
  form,
  types,
  years,
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState(0)

  useEffect(() => {
    const typeId = form.getFieldValue('type_id')
    setSelectedType(typeId ?? 0)
  }, [form])

  const onValuesChange = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field)
      if (!error.length) {
        return
      }
      // Clear error message of field
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ])
    })
  }

  const handleSubmit = async (value) => {
    setLoading(true)
    try {
      const res = await handleOk(value)
      form.resetFields()
      handleCancel()
      wait(500)
      history.push('/kpi/' + res?.id)
    } catch (error) {
      try {
        console.error('PRINT IN %s=====>', 'Form Submit First', error)
        const e_res = await error.response.json().then((res) => res)
        showMessage(ALERT_TYPES.ERROR, e_res.message)
        const errors = e_res.errors.children
        const data = []
        // eslint-disable-next-line guard-for-in
        for (const v in value) {
          const i = {
            name: v,
            ...errors[v],
          }
          data.push(i)
        }
        form.setFields(data)
      } catch (err) {
        console.error('PRINT IN %s=====>', 'Form Submit', err)
        showMessage(ALERT_TYPES.WARN, 'Oops, Something Wrong!')
      }
    }
    setLoading(false)
  }

  return (
    <Modal
      visible={visible}
      width={width}
      title={title}
      okText="Submit"
      cancelText="Cancel"
      confirmLoading={loading}
      destroyOnClose
      onCancel={() => {
        form.resetFields()
        handleCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values)
          })
          .catch((info) => {
            console.error('Validate Failed:', info)
          })
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onValuesChange={onValuesChange}
          onFieldsChange={() => {
            const type = form.getFieldValue('type_id')
            const year = form.getFieldValue('year_id')
            setSelectedType(type)
            if (type === 1) {
              // type id === 1 isYearly
              if (year) {
                const selectedYear = years.filter((y) => y.id === year)[0]
                const { name, from_date, to_date } = selectedYear
                form.setFieldsValue({
                  name,
                  from: moment(new Date(from_date), DATE_FORMAT),
                  to: moment(new Date(to_date), DATE_FORMAT),
                })
              }
            }
          }}
          name={formName}
        >
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="type_id"
            label="Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group options={types?.map((i) => ({ value: i.id, label: i.name }))} />
          </Form.Item>
          {selectedType === 1 && (
            <Form.Item
              {...FORM_ITEM_LAYOUT}
              name="year_id"
              label="Period"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                className="gx-w-100"
                placeholder="Select year"
                options={years?.map((i) => ({ value: i.id, label: i.name }))}
              />
            </Form.Item>
          )}
          {selectedType === 2 && (
            <>
              <Form.Item
                {...FORM_ITEM_LAYOUT}
                name="from"
                label="From"
                rules={[
                  {
                    required: true,
                    message: 'Start date must not be empty',
                  },
                ]}
              >
                <DatePicker
                  className="gx-w-100"
                  placeholder="Start date"
                  disabled={selectedType === 1}
                  format={DATE_FORMAT}
                />
              </Form.Item>
              <Form.Item
                {...FORM_ITEM_LAYOUT}
                name="to"
                label="To"
                rules={[
                  {
                    required: true,
                    message: 'End date must not be empty',
                  },
                ]}
              >
                <DatePicker
                  className="gx-w-100"
                  placeholder="End date"
                  disabled={selectedType === 1}
                  format={DATE_FORMAT}
                />
              </Form.Item>
            </>
          )}
        </Form>
      </Spin>
    </Modal>
  )
}

FormModal.propTypes = {
  visible: PropTypes.bool,
  formName: PropTypes.string,
  width: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string,
  handleCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  handleOk: PropTypes.func.isRequired,
}

FormModal.defaultProps = {
  formName: 'dynamic_rule',
  visible: false,
  loading: false,
  width: 450,
}

export default FormModal
