/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { Calendar, Col, Divider, Row, Select, Spin, Typography } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
//
import { fetchHolidays, fetchIndividualAttendance } from 'appRedux/actions'
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-icon.svg'
import { ReactComponent as CloseIcon } from 'assets/images/icons/close-icon.svg'
import { PAGINATION_TYPES } from 'util/constants'
import { disabledDate } from 'util/helpers'

const SEARCH_DATE_FORMAT = 'YYYY-MM-DD'

const onDateChange = (value = moment()) => ({
  from_date: value.startOf('month').format(SEARCH_DATE_FORMAT),
  to_date: value.endOf('month').format(SEARCH_DATE_FORMAT),
})

const AttendanceCalender = ({ employee_id }) => {
  const dispatch = useDispatch()

  const initialSearch = onDateChange()

  const [search, setSearch] = React.useState(initialSearch)

  const getIndividualAttendance = React.useCallback(() => {
    dispatch(fetchIndividualAttendance({ ...search, employee_id, response_type: 'json' }))
  }, [dispatch, employee_id, search])

  React.useEffect(() => {
    getIndividualAttendance()
  }, [getIndividualAttendance])

  React.useEffect(() => {
    dispatch(fetchHolidays({ year: `${moment().year()}` }))
  }, [dispatch])

  const { attendanceItems, holidaysItems, isLoading } = useSelector(({ pagination, loading }) => ({
    attendanceItems: pagination[PAGINATION_TYPES.attendance_individual]?.items ?? [],
    holidaysItems: pagination[PAGINATION_TYPES.holidays]?.items ?? [],
    isLoading: loading[PAGINATION_TYPES.attendance_individual],
  }))

  const getHolidaysArray = () => {
    const holidaysArr = []
    for (let j = 0; j < holidaysItems.length; j += 1) {
      const { from, to } = holidaysItems[j]
      if (from === to) {
        holidaysArr.push(moment(from).format('DD-MM-YYYY'))
      } else {
        const start = moment(from)
        const end = moment(to)
        const holidaysRange = end.diff(start, 'days')
        for (let k = 0; k < holidaysRange; k += 1) {
          if (k === 0) {
            holidaysArr.push(moment(from).format('DD-MM-YYYY'))
          }
          holidaysArr.push(
            moment(from)
              .add(`${1 + k}`, 'days')
              .format('DD-MM-YYYY')
          )
        }
      }
    }
    return holidaysArr
  }

  const dateCellColor = (value) => {
    const holidaysArr = getHolidaysArray()
    if (value.format('dddd') === 'Friday') {
      return '#F6EEEB'
    }
    for (let l = 0; l < holidaysArr.length; l += 1) {
      if (holidaysArr[l] === value.format('DD-MM-YYYY')) {
        return '#FFF6F4'
      }
    }
    for (let i = 0; i < attendanceItems.length; i += 1) {
      const { date, leave, remote_work } = attendanceItems[i]
      if (date === value.format('DD-MM-YYYY')) {
        if (leave === 'Yes' || leave === 'Full day') {
          return '#FFF9E3'
        }
        if (remote_work === 'Yes') {
          return '#E9E6F4'
        }
        return '#ffffff'
      }
    }
    return '#ffffff'
  }

  const dateCellIcon = (value) => {
    const holidaysArr = getHolidaysArray()
    if (value.format('dddd') === 'Friday' || holidaysArr.includes(value.format('DD-MM-YYYY'))) {
      return
    }

    for (let i = 0; i < attendanceItems.length; i += 1) {
      const { date, late, absent } = attendanceItems[i] ?? {}
      if (date === value.format('DD-MM-YYYY')) {
        if (late === 'Yes') {
          return <CloseIcon />
        }
        if (absent === 'No' && late === 'No') {
          return <CheckIcon />
        }
      }
    }
  }

  const dateCellIText = (value) => {
    const holidaysArr = getHolidaysArray()
    if (value.format('dddd') === 'Friday') {
      return
    }
    if (holidaysArr.includes(value.format('DD-MM-YYYY'))) {
      return 'Public Holiday'
    }
    for (let i = 0; i < attendanceItems.length; i += 1) {
      const { date, leave, remote_work, late, late_in_minute } = attendanceItems[i]
      if (date === value.format('DD-MM-YYYY')) {
        if (leave === 'Yes' || leave === 'Full day') {
          return 'Leave'
        }
        if (remote_work !== 'No' && late === 'Yes') {
          return (
            <>
              {`${late_in_minute}m late`} <br />
              {`${remote_work}`}
            </>
          )
        }
        if (remote_work !== 'No' && late === 'No') {
          return `${remote_work}`
        }
        if (late === 'Yes') {
          return `${late_in_minute}m late`
        }
      }
    }
  }

  function onPanelChange(value) {
    setSearch(onDateChange(value))
  }

  function dateFullCellRender(value) {
    return (
      <>
        <div
          className="gx-w-100 gx-jr-calendar-cell"
          style={{
            backgroundColor: dateCellColor(value),
          }}
        >
          <div className="gx-d-flex gx-flex-column gx-h-100 gx-justify-content-between">
            <div className="gx-d-flex gx-justify-content-between">
              <div className="gx-ml-1 gx-mt-1">
                {value.date().toString().length === 2 ? value.date() : `0${value.date()}`}
              </div>
              <div className="gx-mr-1 gx-mt-1">{dateCellIcon(value)}</div>
            </div>
            <div className="gx-d-flex gx-justify-content-end gx-align-items-end gx-mr-1 gx-fs-sm">
              {dateCellIText(value)}
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <Calendar
          locale={{
            lang: {
              locale: 'en',
              dayFormat: moment.updateLocale('en', {
                weekdaysMin: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
              }),
            },
          }}
          dateFullCellRender={(v) => dateFullCellRender(v)}
          headerRender={({ value, onChange }) => {
            const start = 0
            const end = 12
            const monthOptions = []
            const current = value.clone()
            const localeData = value.localeData()
            const months = []
            const currentMonth = new Date().getMonth()
            for (let i = 0; i < 12; i += 1) {
              current.month(i)
              months.push(localeData.monthsShort(current))
            }
            for (let index = start; index < end; index += 1) {
              monthOptions.push(
                <Select.Option
                  className="month-item"
                  key={`${index}`}
                  disabled={index > currentMonth}
                >
                  {months[index]}
                </Select.Option>
              )
            }
            const month = value.month()
            const year = value.year()
            const options = []
            for (let i = year - 30; i < year + 30; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>
              )
            }
            return (
              <div style={{ padding: 8 }}>
                <div className="gx-d-flex gx-justify-content-between">
                  <div>
                    <Typography.Text level={4}>ATTENDANCE</Typography.Text>
                  </div>
                  <div>
                    <Row gutter={8}>
                      {/* <Col>
                      <Select
                        size="small"
                        dropdownMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={newYear => {
                          const now = value.clone().year(newYear);
                          onChange(now);
                        }}
                        value={String(year)}
                      >
                        {options}
                      </Select>
                    </Col> */}
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={String(month)}
                          onChange={(selectedMonth) => {
                            const newValue = value.clone()
                            newValue.month(parseInt(selectedMonth, 10))
                            onChange(newValue)
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Divider />
              </div>
            )
          }}
          onPanelChange={(v) => onPanelChange(v)}
          disabledDate={disabledDate}
        />
      </Spin>
    </>
  )
}

AttendanceCalender.propTypes = {
  employee_id: PropTypes.number.isRequired,
}

export default AttendanceCalender
