import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Form, Input, Modal, Radio, Spin, TimePicker } from 'antd'
//
import { showMessage } from 'util/helpers'
import { ALERT_TYPES } from 'util/constants'
import { DATE_FORMAT, FORM_ITEM_LAYOUT, TIME_FORMAT } from '../../util/constants'

const FormModal = ({ visible, formName, width, handleCancel, handleOk, title, form, types }) => {
  const [loading, setLoading] = useState(false)
  const [selectedType, setSelectedType] = useState(0)

  useEffect(() => {
    const typeId = form.getFieldValue('type_id')
    setSelectedType(typeId ?? 0)
  }, [form])

  const onValuesChange = (values) => {
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field)
      if (!error.length) {
        return
      }
      // Clear error message of field
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ])
    })
  }

  const handleSubmit = async (value) => {
    setLoading(true)
    try {
      await handleOk(value)
      form.resetFields()
      handleCancel()
      // history.push(`/kpi/${res?.id}`)
    } catch (error) {
      try {
        console.error('PRINT IN %s=====>', 'Form Submit First', error)
        const eRes = await error.response.json().then((res) => res)
        showMessage(ALERT_TYPES.ERROR, eRes.message)
        const errors = eRes.errors.children
        const data = []
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const v in value) {
          const i = {
            name: v,
            ...errors[v],
          }
          data.push(i)
        }
        form.setFields(data)
      } catch (error2) {
        console.error('PRINT IN %s=====>', 'Form Submit', error2)
        showMessage(ALERT_TYPES.WARN, 'Oops, Something Wrong!')
      }
    }
    setLoading(false)
  }

  return (
    <Modal
      visible={visible}
      width={width}
      title={title}
      okText="Submit"
      cancelText="Cancel"
      confirmLoading={loading}
      destroyOnClose
      onCancel={() => {
        form.resetFields()
        handleCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values)
          })
          .catch((info) => {
            console.error('Validate Failed:', info)
          })
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onValuesChange={onValuesChange}
          onFieldsChange={() => {
            const type = form.getFieldValue('type_id')
            setSelectedType(type)
          }}
          name={formName}
        >
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="type_id"
            label="Type"
            rules={[
              {
                required: true,
                message: 'Select type',
              },
            ]}
          >
            <Radio.Group
              options={types?.map((i) => ({ value: i.id, label: i.name }))}
            />
          </Form.Item>
          {selectedType === 1 && (
            <Form.Item
              {...FORM_ITEM_LAYOUT}
              name="from_date"
              label="Date"
              rules={[{ required: true }]}
            >
              <DatePicker
                className="gx-w-100"
                placeholder="Select date"
                format={DATE_FORMAT}
              />
            </Form.Item>
          )}
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="from"
            label="From"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {selectedType === 1 ? (
              <TimePicker
                className="gx-w-100"
                placeholder="From time"
                format={TIME_FORMAT}
              />
            ) : (
              <DatePicker
                className="gx-w-100"
                placeholder="From date"
                format={DATE_FORMAT}
              />
            )}
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="to"
            label="To"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {selectedType === 1 ? (
              <TimePicker
                className="gx-w-100"
                placeholder="To time"
                format={TIME_FORMAT}
              />
            ) : (
              <DatePicker
                className="gx-w-100"
                placeholder="To date"
                format={DATE_FORMAT}
              />
            )}
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="description"
            label="Description"
          >
            <Input.TextArea allowClear />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

FormModal.propTypes = {
  visible: PropTypes.bool,
  formName: PropTypes.string,
  width: PropTypes.number,
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  types: PropTypes.array,
  handleCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  handleOk: PropTypes.func.isRequired,
}

FormModal.defaultProps = {
  formName: 'dynamic_rule',
  title: 'form title',
  types: [],
  visible: false,
  width: 550,
}

export default FormModal
