import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Col, Drawer, Row } from 'antd'
//
import CustomScrollbars from 'util/CustomScrollbars'

const FilterList = ({ showFilter, setShowFilter, uiFilters, setUiFilters, items }) => {
  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => setShowFilter(false)}
      visible={showFilter}
    >
      <CustomScrollbars className="gx-customizer">
        <Checkbox.Group onChange={(e) => setUiFilters(e)} defaultValue={uiFilters}>
          <Row>
            {Object.keys(items).map((item, index) => {
              return (
                <Col key={`filter-check-box-${index}`} span={16} className="gx-m-2">
                  <Checkbox value={item}> {item} </Checkbox>
                </Col>
              )
            })}
          </Row>
        </Checkbox.Group>
      </CustomScrollbars>
    </Drawer>
  )
}

FilterList.propTypes = {
  items: PropTypes.object,
  showFilter: PropTypes.bool,
  setShowFilter: PropTypes.func,
  uiFilters: PropTypes.array,
  setUiFilters: PropTypes.func,
}

export default FilterList
