import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, Input } from 'antd'
import { Link, useHistory } from 'react-router-dom'
//
import { showMessage } from 'util/helpers'
import { ALERT_TYPES } from 'util/constants'
import IntlMessages from 'util/IntlMessages'
import { AuthService } from '../services'

const FormItem = Form.Item

const ForgotPassword = () => {
  const history = useHistory()

  const [isSendEid, setSendEid] = useState(false)
  const [otp, setOTP] = useState(undefined)
  const [isSendOTP, setSendOTP] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(undefined)
  const [resendDisabled, setResendDisabled] = useState(true)
  const [timer, setTimer] = useState(300)
  const [eid, setEid] = useState(undefined)

  const idName = useMemo(
    () =>
      (isSendEid && 'app.userAuth.OTP') ||
      (isSendOTP && 'app.userAuth.resetNewPassword') ||
      'app.userAuth.forgot',
    [isSendEid, isSendOTP]
  )

  useEffect(() => {
    let intervalId = null
    if (isSendEid) {
      if (timer === 0) {
        setResendDisabled(false)
      }
      intervalId = timer > 0 && setInterval(() => setTimer((prevTimer) => prevTimer - 1), 1000)
    }
    return () => clearInterval(intervalId)
  }, [isSendEid, timer])

  const handleEid = async (values) => {
    try {
      const { eid: Eid } = values ?? {}
      if (Eid) {
        const res = await AuthService.otpRequest({ eid: Eid })
        if (res?.success) {
          setMessage(res.success)
          setSendEid(true)
        }
      }
    } catch (error) {
      console.error('PRINT IN %s=====>', 'ERROR', error)
    }
  }

  const handleOTP = async (values) => {
    try {
      const { OTP } = values ?? {}
      if (OTP) {
        setOTP(OTP)
        const res = await AuthService.verifyToken(OTP)
        if (res?.success) {
          setSendEid(false)
          setSendOTP(true)
        }
      }
    } catch (error) {
      console.error('PRINT IN %s=====>', 'ERROR', error)
    }
  }

  const handlePassword = async (values) => {
    try {
      const payload = {
        otp: parseInt(otp, 10),
        password: values.confirm.toString(),
      }
      const res = await AuthService.passwordReset(payload)
      if (res) {
        showMessage(ALERT_TYPES.SUCCESS, 'Your password has been successfully updated.')
      }
      history.push('/signin')
    } catch (error) {
      console.error('PRINT IN %s=====>', 'ERROR', error)
    }
  }

  const handleResendCode = async () => {
    if (!resendDisabled) {
      setResendDisabled(true)
      setTimer(300)
      try {
        setIsLoading(true)
        await handleEid(eid)
      } catch (error) {
        if (error.name === 400) {
          const response = await error.response.json().then((res) => res)
          setMessage(response.message)
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  const buttonLabelId = useMemo(
    () =>
      (isSendEid && 'app.userAuth.verify') ||
      (isSendOTP && 'app.userAuth.submit') ||
      'app.userAuth.send',
    [isSendOTP, isSendEid]
  )

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true)
      if (isSendOTP) {
        await handlePassword(values)
      } else if (isSendEid) {
        await handleOTP(values)
      } else {
        setEid(values)
        await handleEid(values)
      }
    } catch (error) {
      if (error.name === 400) {
        const response = await error.response.json().then((res) => res)
        setMessage(response.message)
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-login-header">
          {/* <img src={require("assets/images/logo-white.png")} alt="wieldy" title="wieldy"/> */}
        </div>
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>
            <IntlMessages id={idName} />
          </p>
        </div>
        {message && (
          <div>
            <p>{message}</p>
          </div>
        )}
        <Form
          initialValues={{ remember: true }}
          name="basic"
          onFinish={handleSubmit}
          className="gx-signin-form gx-form-row0"
        >
          {!message && !isSendEid && (
            <FormItem
              name="eid"
              rules={[
                { message: 'The input is not valid E-Id!' },
                { required: true, message: 'Please input your E-Id!' },
              ]}
            >
              <Input className="gx-input-lineheight" type="text" placeholder="E-Id" />
            </FormItem>
          )}
          {isSendEid && (
            <FormItem
              name="OTP"
              rules={[
                {
                  required: true,
                  message: 'Please input your OTP!',
                },
                {
                  max: 4,
                  min: 4,
                  message: 'OTP must have a length 4',
                },
              ]}
              hasFeedback
            >
              <Input className="gx-input-lineheight" type="text" placeholder="OTP" />
            </FormItem>
          )}
          {isSendOTP && (
            <>
              <FormItem
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  {
                    max: 20,
                    min: 6,
                    message: 'Password must have a length between 6 and 20',
                  },
                ]}
                hasFeedback
              >
                <Input.Password placeholder="Password" />
              </FormItem>
              <FormItem
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error('The two passwords that you entered do not match!')
                      )
                    },
                  }),
                ]}
              >
                <Input.Password placeholder="Confirm Password" />
              </FormItem>
            </>
          )}
          {isSendEid && (
            <div style={{ textAlign: 'center' }}>
              <p style={{ marginBottom: 0 }}>
                I didn&apos;t receive code.
                {' '}
                <span
                  tabIndex={0}
                  role="button"
                  onClick={handleResendCode}
                  onKeyDown={handleResendCode}
                  style={{ color: 'red', cursor: 'pointer', opacity: resendDisabled ? 0.5 : 1 }}
                >
                  Resend Code
                </span>
              </p>
              <p style={{ color: 'black' }}>
                {Math.floor(timer / 60)}
                :
                {timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                {' '}
                Sec left
              </p>
            </div>
          )}
          <FormItem>
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              <IntlMessages id={buttonLabelId} />
            </Button>
            <Link to="/signin">
              <Button block>Back</Button>
            </Link>
          </FormItem>
        </Form>
      </div>
    </div>
  )
}

export default ForgotPassword
