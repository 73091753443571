/* eslint-disable no-shadow */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */

import React from 'react'
import PropTypes from 'prop-types'
import {Button, Col, Row, Table, Tooltip, Divider, InputNumber } from 'antd'
import { EditOutlined } from '@ant-design/icons'
//
import { capitalizeFirstLetter } from 'util/helpers'
import { EMPLOYEE_RATING_COLOR, LM_RATING_COLOR, RATING_STYLE } from 'util/constants'
import Widget from '../Widget'
const totalPerspective = (id, list = []) =>
list.reduce((a, b) => {
  if (b?.objective_kpis?.[0]?.objective_kpi?.objective?.perspective?.id === id) {
    // eslint-disable-next-line no-param-reassign
    a += b.total_weight
  }
  return a
}, 0)
const SectionA = ({ perspectives, section_a, total, handleActualChange, Objectives, handleEdit, handleSubmit, isSelfEPR, isLM, eprStatusID }) => {
  const { rating, comment, lm_rating, lm_comment, objectives, total_objective } = section_a
  const handleKPISubmit = () => {
    if (![1, 2, 5].includes(eprStatusID)) return null
    handleSubmit(Objectives);
  }

  const columns = [
    {
      title: 'KPI',
      dataIndex: ['objective_kpi', 'name'],
      key: 'name',
      width: 220,
      render: (_, item) => _ == 'Sum' ? <span className='gx-font-weight-medium'>{_}</span> : _
    },
    {
      title: 'Weight',
      dataIndex: ['objective_kpi', 'weight'],
      key: 'weight',
      render: (_, item) => item?.objective_kpi?.name == 'Sum' ? <span className='gx-font-weight-medium'>{_}</span> : _
    },
    {
      title: 'Time',
      dataIndex: ['objective_kpi', 'time'],
      key: 'time',
    },
    {
      title: 'Target unit',
      dataIndex: ['objective_kpi', 'target_unit', 'name'],
    },
    {
      title: 'Target',
      dataIndex: ['objective_kpi', 'target'],
      key: 'target',
    },
    {
      title: 'Actual (Self)',
      dataIndex: 'actual',
      key: 'actual',
      render: (_, item) => isSelfEPR && [1, 5].includes(eprStatusID) &&  _ !== null ? (
        <InputNumber
          defaultValue={Objectives?.find(i => i.objective_id === item.id)?.actual ?? _}
          style={{ fontSize: 'small' }}
          min={0}
          type="number"
          onChange={e => handleActualChange(item.id, _, e)}
        />
      ) : _,
    },
    {
      title: 'Achievement%',
      dataIndex: 'achievement',
      key: 'achievement',
    },
    {
      title: 'Marks',
      dataIndex: 'mark',
      key: 'mark',
      render: (_, item) => item?.objective_kpi?.name == 'Sum' ? <span className='gx-font-weight-medium'>{_}</span> : _
    },
    {
      title: 'Actual (LM)',
      dataIndex: 'lm_actual',
      key: 'lm_actual',
      render: (_, item) => isLM && [2].includes(eprStatusID) && _ !== null ? (
        <InputNumber
          defaultValue={Objectives?.find(i => i.objective_id === item.id)?.lm_actual ?? _}
          style={{ fontSize: 'small' }}
          min={0}
          type="number"
          onChange={e => handleActualChange(item.id, _, e, false)}
        />
      ) : _,
    },
    {
      title: 'Achievement%',
      dataIndex: 'lm_achievement',
      key: 'lm_achievement',
    },
    {
      title: 'Marks',
      dataIndex: 'lm_mark',
      key: 'lm_mark',
      render: (_, item) => item?.objective_kpi?.name == 'Sum' ? <span className='gx-font-weight-medium'>{_}</span> : _
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (_, record) => (
    //     <EditOutlined
    //       // eslint-disable-next-line consistent-return
    //       onClick={() => {
    //         if (eprStatusID >= 3 && !isSelfEPR) return null
    //         if (![1, 5].includes(eprStatusID) && isSelfEPR) return null
    //         handleEdit({ ...record, objective_kpi_id: record.id })
    //       }}
    //     />
    //   ),
    // },
  ]
  const isReverse = React.useMemo(() => {
    if (eprStatusID >= 3 && !isSelfEPR) return true
    if (![1, 5].includes(eprStatusID) && isSelfEPR) return true
    return false
  }, [eprStatusID, isSelfEPR])

  const renderOverAll = () => {
    return (
      <Col xs={24}className='gx-mt-3'>
      <Widget
        title={<div className='gx-pl-3'>Overall rating</div>}
        extra={
          <div  className='gx-pr-3'>
            {[1, 2, 5].includes(eprStatusID) &&<EditOutlined
              // eslint-disable-next-line consistent-return
              onClick={() => {
                if (eprStatusID >= 3 && !isSelfEPR) return null
                if (![1, 5].includes(eprStatusID) && isSelfEPR) return null
                handleEdit(section_a)
              }}
            />}
          </div>
        }
      >
        <Row>
          <Col span={12}>
            <div className='gx-py-3 gx-pl-0' style={{fontSize: 16}}>Eoner's Self Rating</div>
            <Row>
              {/* Rating */}
              <Col span={6}>
                <div style={{ 
                  ...RATING_STYLE, 
                  ...EMPLOYEE_RATING_COLOR, width: '100%', height: '100px', borderWidth: 0, borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(219, 239, 250, 1)' }}>
                  <div style={{ textAlign: 'center', margin: 7, fontSize: '34px', color: 'rgba(16, 149, 223, 1)' }}>0{rating}</div>
                </div>
              </Col>
              <Col span={18}>
                <p className="gx-mb-0">{capitalizeFirstLetter(comment)}</p>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div className='gx-py-3 gx-pl-0' style={{fontSize: 16}}>Line Manager Rating</div>
            <Row>
              {/* Rating */}
              <Col span={6}>
                <div style={{ ...RATING_STYLE, ...LM_RATING_COLOR, width: '100%', borderWidth: 0, height: '100px', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(219, 239, 250, 1)' }}>
                  <div style={{ textAlign: 'center', margin: 7, fontSize: '34px', color: 'rgba(16, 149, 223, 1)' }}>0{lm_rating}</div>
                </div>
              </Col>
              <Col span={18}>
                <p className="gx-mb-0">{capitalizeFirstLetter(lm_comment)}</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Widget>
      {[1, 5].includes(eprStatusID) && isSelfEPR && <div className='gx-d-flex gx-pt-3 gx-justify-content-end'>
        <Button type='primary' onClick={() => handleKPISubmit()}>
          Save
        </Button>
      </div>}
      {[2].includes(eprStatusID) && isLM && <div className='gx-d-flex gx-pt-3 gx-justify-content-end'>
        <Button type='primary' onClick={() => handleKPISubmit()}>
          Save
        </Button>
      </div>}
    </Col>
    )
  } 
  return (
    <Row style={{flexDirection: isReverse ? 'column-reverse' : 'column'}}>
      {/* {isReverse && renderOverAll()} */}
      <Col span={24}>
        <Row>
      {perspectives?.map((per, i) => {
        // return null
        const totalWeight = (
          <Tooltip title="Total weight">
            <Button className='gx-m-0 gx-ml-2' type="fill" shape="circle" style={{background: 'rgba(3, 143, 221, 0.2)'}}>
              {totalPerspective(per.id, objectives)}
            </Button>
          </Tooltip>
        )
          return (
            <Col span={24}>
            <Widget key={`epr_kpi_objective_key_${i}`}>
              <Row align="middle">
                <Col span={22}>
                  <span className="gx-fs-lg">
                    <strong>{per?.name?.toUpperCase()}</strong>
                    {totalWeight}
                  </span>
                </Col>
              </Row>
              {objectives?.map((obj, objIndex) => {
                if (obj?.objective_kpis?.[0]?.objective_kpi?.objective?.perspective?.id === per.id) {
                  const {mark, weight, lm_mark} = total_objective[obj?.objective_id] ?? {}
                  return (
                    <div key={`kpi_objective_perspective_key_${objIndex}`}>
                      <Row align="middle">
                        <Col span={24}>
                          <Divider />
                          <div className="gx-d-flex gx-align-items-center">
                            <span className="gx-fs-lg gx-mr-1">{`${obj?.objective_name}`}</span>
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                                borderRadius: "100%",
                                background: "rgba(3, 143, 221, 0.2)",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgba(16, 149, 223, 1)",
                                  fontSize: "0.85rem",
                                }}
                              >
                                {obj?.total_weight}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24} className="gx-mt-3">
                        <Table
                          scroll={{x: 900}}
                          rowKey={(item) => `${item?.id}`}
                          columns={columns}
                          dataSource={obj?.objective_kpis ? [...obj?.objective_kpis, { objective_kpi: {name: 'Sum', weight: weight?.toFixed(2) ?? '0.00'},  actual: null, lm_actual: null, mark: mark?.toFixed(2) ?? '0.00', lm_mark: lm_mark?.toFixed(2) ?? '0.00' }] : []}
                          pagination={false}
                        />
                        </Col>
                      </Row>
                    </div>
                  )
                }
                return null
              })}
            </Widget>
            </Col>
          )}
      )}
      </Row>
      </Col>
      {/* {objectives?.map((item = {}, index) => {
        const { objective_kpis } = item
        const totalWeight = (
          <Tooltip title="Total weight">
            <Button className='gx-mt-3 gx-ml-2' type="fill" shape="circle" style={{background: 'rgba(3, 143, 221, 0.2)'}}>
              {item.total_weight}
            </Button>
          </Tooltip>
        )
        return (
          <Col key={`epr-kpi-details-${index}`} span={24}>
            <Widget title={<div className="gx-pl-3">{item.objective_name} {totalWeight}</div>}>
              <Table
                rowKey={(item) => `${item?.id}`}
                columns={columns}
                dataSource={objective_kpis}
                pagination={false}
              />
            </Widget>
          </Col>
        )
      })} */}
      <Col span={24}>
      {/* <Table
        rowKey={(item) => `${item?.id}`}
        showHeader={false}
        columns={columns}
        dataSource={[{ objective_kpi: {name: '', weight: ''},  actual: null, lm_actual: null, mark: '0.00', lm_mark: '0.00' }]}
        pagination={false}
      /> */}
        <Row>
          <Col span={12} />
          <Col span={7}><div style={{ fontSize: 16, fontWeight: '600', textAlign: 'right'}}>{isReverse ? 'Obtained Marks (Self): ' : 'Grand Total: '} {total?.total_mark?.toFixed(2) ?? '0.00'}</div></Col>
          <Col span={5} style={{}}><div style={{ fontSize: 16, fontWeight: '600'}}>{isReverse ? 'Obtained Marks (LM): ' : 'Grand Total: '}  {total?.total_lm_mark?.toFixed(2) ?? '0.00'}</div></Col>
        </Row>
      </Col>
      {renderOverAll()}
    </Row>
  )
}

SectionA.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  eprStatusID: PropTypes.number.isRequired,
  isSelfEPR: PropTypes.bool.isRequired,
  section_a: PropTypes.shape({
    rating: PropTypes.number,
    lm_rating: PropTypes.number,
    comment: PropTypes.string,
    lm_comment: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    objectives: PropTypes.any,
  }),
}

SectionA.defaultProps = { 
  section_a: PropTypes.shape({
    rating: 0,
    lm_rating: 0,
    comment: '',
    lm_comment: '',
    stables: {},
  }),
}
export default SectionA
