import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Input, Divider, Select } from 'antd'
//
import UserProfileImage from 'components/UserProfileImage'
import { FORM_ITEM_LAYOUT } from 'util/constants'

const EmployeeBasicForm = ({ bloodGroups }) => {
  return (
    <>
      <Row>
        <Col span={12}>
          <Row justify="center" align="middle">
            <Col>
              <Form.Item name="photo" noStyle>
                <UserProfileImage />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please input your name',
              },
              {
                max: 50,
                min: 4,
                message: 'Name must have a length between 4 and 50',
              },
            ]}
          >
            <Input allowClear placeholder="Please input your name" />
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="mobile"
            label="Mobile"
            rules={[
              {
                required: true,
                message: 'Please input your mobile number',
              },
              () => ({
                validator(_, value) {
                  if (value) {
                    if (!/^\d+$/.test(value)) {
                      return Promise.reject(new Error('Mobile must have be digit'))
                    } else if (!/^\d{11}$/.test(value)) {
                      return Promise.reject(new Error('Mobile must have a length 11'))
                    } else if (!/^01(?!0|2)[0-9]{9}$/.test(value)) {
                      return Promise.reject(new Error('Mobile number is not valid'))
                    }
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input allowClear placeholder="Please input your mobile number" />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      <Row justify="start" align="stretch">
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="eid"
            label="EID"
            rules={[
              {
                required: true,
                message: 'Please input your eid',
              },
            ]}
          >
            <Input allowClear placeholder="Please input your eid" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="email"
            label="E-mail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
            ]}
          >
            <Input allowClear placeholder="Please input your email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="blood_group_id" label="Blood Group">
            <Select allowClear placeholder="Select blood group">
              {bloodGroups.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

EmployeeBasicForm.defaultProps = {
  bloodGroups: [],
}

EmployeeBasicForm.propTypes = {
  bloodGroups: PropTypes.array,
}
export default EmployeeBasicForm
