import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Input, DatePicker, Select } from 'antd'
//
import { FORM_ITEM_LAYOUT, DATE_FORMAT } from 'util/constants'

const EmployeeGeneralForm = ({ genders, religions }) => {
  return (
    <>
      <Row justify="start" align="stretch">
        <Col xs={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="pob" label="POB">
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="joining_date" label="Joining Date">
            <DatePicker allowClear className="gx-w-100" format={DATE_FORMAT} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="gender_id" label="Gender">
            <Select allowClear placeholder="Select one">
              {genders.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="confirmation_date" label="Confirmation Date">
            <DatePicker allowClear className="gx-w-100" format={DATE_FORMAT} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="religion_id" label="Religion">
            <Select allowClear placeholder="Select one">
              {religions.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="dob" label="Birthday">
            <DatePicker allowClear className="gx-w-100" format={DATE_FORMAT} />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="passport_no" label="Passport No">
            <Input allowClear placeholder="Please input passport no." />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="driving_license" label="Driving license">
            <Input allowClear placeholder="Please input briving license." />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="nationality" label="Nationality">
            <Input allowClear placeholder="Please input nationality" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="nid" label="Nid">
            <Input allowClear placeholder="Please input NID" />
          </Form.Item>
        </Col>
        <Col xs={24}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="tin" label="Tin">
            <Input allowClear placeholder="Please input E-TIN" />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

EmployeeGeneralForm.defaultProps = {
  genders: [],
  religions: [],
}

EmployeeGeneralForm.propTypes = {
  genders: PropTypes.array,
  religions: PropTypes.array,
}
export default EmployeeGeneralForm
