// https://dev.to/jam3/managing-env-variables-for-provisional-builds-h37
const {
  REACT_APP_SERVER_URL,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  REACT_APP_ID,
  REACT_APP_SENTRY_DSN,
  REACT_APP_ENV,
  REACT_APP_SENTRY_APP_ENV,
} = process.env

// eslint-disable-next-line import/prefer-default-export
export const CONFIG = {
  APP_ENV: REACT_APP_ENV,
  SENTRY_ENV: REACT_APP_SENTRY_APP_ENV,
  SERVER_URL: REACT_APP_SERVER_URL,
  GOOGLE_MAPS_API_KEY: REACT_APP_GOOGLE_MAPS_API_KEY,
  API_ID: REACT_APP_ID,
  SENTRY_DSN: REACT_APP_SENTRY_DSN,
}
