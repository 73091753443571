import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Col, Row, Table } from 'antd'
//
import Widget from '../../Widget'

const EmploymentHistory = ({ data }) => {
  const { history } = data
  const columns = [
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      // eslint-disable-next-line react/display-name
      render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>,
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      // eslint-disable-next-line react/display-name
      render: (date) => <span>{moment(date).format('DD-MM-YYYY')}</span>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ]

  return (
    <Row>
      <Col span={24}>
        <Widget title="Employment History Information">
          <Table
            className="gx-w-100"
            columns={columns}
            dataSource={history}
            pagination={false}
            rowKey={(item) => item.id}
          />
        </Widget>
      </Col>
    </Row>
  )
}
EmploymentHistory.defaultProps = {
  data: {
    history: [],
  },
}
EmploymentHistory.propTypes = {
  data: PropTypes.object,
}
export default EmploymentHistory
