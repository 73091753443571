import React from 'react'
import PropTypes from 'prop-types'
import { Select } from 'antd'

const { Option } = Select

const SelectOption = ({ placeholder, items, ...rest }) => {
  return (
    <Select
      showSearch
      allowClear
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...rest}
    >
      {items.map((item, index) => (
        <Option key={index} value={item.id}>
          {item.name}
        </Option>
      ))}
    </Select>
  )
}
SelectOption.defaultProps = {
  placeholder: 'Select one',
  items: [],
}

SelectOption.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array.isRequired,
}

export default SelectOption
