import { setLoading } from 'appRedux/reducers/Loading'
import { EmployeeService } from 'services'
import { KEYS } from 'util/constants'
import { addCommonItems } from 'appRedux/reducers/Common'
import { updatePaginationItem } from 'appRedux/reducers/Pagination'
import { ALERT_TYPES, PAGINATION_TYPES } from '../../util/constants'
import { showMessage } from '../../util/helpers'

export const fetchGenders = () => {
  return async (dispatch) => {
    const key = KEYS.genders
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EmployeeService.getGenders()
      dispatch(addCommonItems({ key, value: res }))
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const fetchReligions = () => {
  return async (dispatch) => {
    const key = KEYS.religions
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EmployeeService.getReligions()
      dispatch(addCommonItems({ key, value: res }))
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const fetchStaticItem = (route, ServiceName = EmployeeService, body) => {
  return async (dispatch) => {
    let key = route
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await ServiceName.getItem(route, body)
      dispatch(addCommonItems({ key, value: res }))
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const patchActions = (route, body, key, ServiceName = EmployeeService) => {
  return async (dispatch) => {
    const loadingObj = {
      key,
      value: true,
    }
    let id
    if (key === PAGINATION_TYPES.kpi) {
      id = body.kpi_id
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await ServiceName.patch(route, body)
      res &&
        dispatch(
          updatePaginationItem({
            id,
            key,
            value: { ...res },
          })
        )
      res && showMessage(ALERT_TYPES.SUCCESS, `Status has been updated!`)
    } catch (e) {
      console.error('PRINT IN %s=====>', 'patchActions', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
