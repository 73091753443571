import React from 'react'
import PropTypes from 'prop-types'
import { Col, DatePicker, Form, Input, Row, Select, Switch } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
//
import { FORM_ITEM_LAYOUT, DATE_FORMAT } from 'util/constants'

const EmployeePersonalForm = ({ isMember, bloodGroups, maritalStatuses, genders }) => {
  const member = (
    <>
      <Col xs={12}>
        <Form.Item
          {...FORM_ITEM_LAYOUT}
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Name must not be empty',
            },
            {
              max: 30,
              min: 4,
              message: 'Name must have a length between 4 and 30',
            },
          ]}
        >
          <Input allowClear placeholder="Please input name" />
        </Form.Item>

        <Form.Item {...FORM_ITEM_LAYOUT} name="nid" label="Nid">
          <Input allowClear placeholder="Please input NID" />
        </Form.Item>
        <Form.Item
          {...FORM_ITEM_LAYOUT}
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
          ]}
        >
          <Input allowClear placeholder="Please input email" />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="blood_group_id" label="Blood Group">
          <Select allowClear placeholder="Please select one">
            {bloodGroups.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} label="Emergency">
          <Form.Item name="emergency" valuePropName="checked" noStyle>
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </Form.Item>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          {...FORM_ITEM_LAYOUT}
          name="mobile"
          label="Mobile"
          rules={[
            () => ({
              validator(_, value) {
                if (value) {
                  if (!/^\d+$/.test(value)) {
                    return Promise.reject(new Error('Mobile must have be digit'))
                  } else if (!/^\d{11}$/.test(value)) {
                    return Promise.reject(new Error('Mobile must have a length 11'))
                  } else if (!/^01(?!0|2)[0-9]{9}$/.test(value)) {
                    return Promise.reject(new Error('Mobile number is not valid'))
                  }
                }
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input allowClear placeholder="Please input mobile no." />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="gender_id" label="Gender">
          <Select allowClear placeholder="Select one">
            {genders.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="relation" label="Relation">
          <Input allowClear placeholder="Please input relation" />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="dob" label="Birthday">
          <DatePicker allowClear format={DATE_FORMAT} className="gx-w-100" />
        </Form.Item>
      </Col>
    </>
  )
  const family = (
    <>
      <Col span={12}>
        <Form.Item {...FORM_ITEM_LAYOUT} name="father_name" label="Father's name">
          <Input allowClear />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="marital_status_id" label="Marital Status">
          <Select allowClear placeholder="Select one">
            {maritalStatuses.map((item, index) => (
              <Select.Option key={index} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="marriage_date" label="Marriage Date">
          <DatePicker allowClear format={DATE_FORMAT} className="gx-w-100" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item {...FORM_ITEM_LAYOUT} name="mother_name" label="Mother's Name">
          <Input allowClear />
        </Form.Item>
        <Form.Item {...FORM_ITEM_LAYOUT} name="spouse_name" label="Spouse Name">
          <Input allowClear />
        </Form.Item>
      </Col>
    </>
  )
  return <Row justify="start">{isMember ? member : family}</Row>
}
EmployeePersonalForm.defaultProps = {
  isMember: false,
}
EmployeePersonalForm.propTypes = {
  isMember: PropTypes.bool,
  bloodGroups: PropTypes.array,
  maritalStatuses: PropTypes.array,
  genders: PropTypes.array,
}

export default EmployeePersonalForm
