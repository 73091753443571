import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Select } from 'antd'
//
import { FORM_ITEM_LAYOUT } from 'util/constants'

const EmployeeLanguageForm = ({ languagesProficiency }) => {
  const selectProficiency = (
    <Select allowClear placeholder="Select one">
      {languagesProficiency.map((item, index) => (
        <Select.Option key={index} value={item.id}>
          {item.name}
        </Select.Option>
      ))}
    </Select>
  )
  return (
    <>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="name"
        label="Name"
        rules={[
          {
            required: true,
            message: 'Please input language name',
          },
          {
            max: 50,
            min: 4,
            message: 'Name must have a length between 4 and 50',
          },
        ]}
      >
        <Input allowClear placeholder="Please input name" />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="writing_id"
        label="Writing"
        rules={[
          {
            required: true,
            message: 'Please select one',
          },
        ]}
      >
        {selectProficiency}
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="reading_id"
        label="Reading"
        rules={[
          {
            required: true,
            message: 'Please select one',
          },
        ]}
      >
        {selectProficiency}
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="speaking_id"
        label="Speaking"
        rules={[
          {
            required: true,
            message: 'Please select one',
          },
        ]}
      >
        {selectProficiency}
      </Form.Item>
      <Form.Item {...FORM_ITEM_LAYOUT} name="description" label="Description">
        <Input.TextArea allowClear />
      </Form.Item>
    </>
  )
}

EmployeeLanguageForm.defaultProps = {
  languagesProficiency: [],
}

EmployeeLanguageForm.propTypes = {
  languagesProficiency: PropTypes.array,
}
export default EmployeeLanguageForm
