import antdSA from 'antd/lib/locale-provider/it_IT'
import saMessages from '../locales/it_IT.json'

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'it-IT',
}
export default saLang
