import React from 'react'
import PropTypes from 'prop-types'
//
import Widget from 'components/Widget'
import EmployeeLeave from './EmployeeLeave'

const Leave = ({ data }) => {
  return (
    <Widget title="Leave  Status" styleName="gx-card-full">
      <EmployeeLeave data={data} />
    </Widget>
  )
}

Leave.defaultProps = {
  data: {},
}

Leave.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
}

export default Leave
