import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Upload } from 'antd'
import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
//
import { getBase64, beforeUpload } from 'util/helpers'

const UserProfileImage = ({ isEdit, value, onChange, id }) => {
  const fileList = useMemo(() => {
    return !value
      ? []
      : [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: value,
          },
        ]
  }, [value])

  const [loading, setLoading] = useState(false)

  const handleChange = ({ file }) => {
    setLoading(true)
    file.status !== 'removed' &&
      getBase64(file.originFileObj, (imageUrl) => {
        if (onChange) {
          onChange(imageUrl)
        }
      })
    setLoading(false)
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )
  return (
    <ImgCrop rotate>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={{
          showPreviewIcon: false,
          showRemoveIcon: true,
          removeIcon: <DeleteOutlined onClick={(e) => onChange(undefined)} />,
        }}
        fileList={fileList}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {fileList.length < 1 && uploadButton}
      </Upload>
    </ImgCrop>
  )
}

UserProfileImage.propTypes = {
  isEdit: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
}

export default UserProfileImage
