import React from 'react'
import PropTypes from 'prop-types'
import { Col, Descriptions, Row, Tag } from 'antd'
import { SafetyCertificateOutlined } from '@ant-design/icons'
//
import Widget from '../../Widget'

const Reference = ({ data }) => {
  const { references } = data
  return (
    <Row>
      {references
        .filter((item) => item.grantor)
        .map((item, index) => {
          return (
            <Col key={index} xs={24} sm={12} md={12} lg={12} xl={12}>
              <Widget
                title={
                  item.grantor ? (
                    <Tag icon={<SafetyCertificateOutlined />} color="#87d068">
                      Grantor
                    </Tag>
                  ) : (
                    'Reference'
                  )
                }
              >
                <Descriptions column={1} bordered size="small" labelStyle={{ width: 180 }}>
                  <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
                  <Descriptions.Item label="Mobile">{item.mobile}</Descriptions.Item>
                  <Descriptions.Item label="Nid">{item.nid}</Descriptions.Item>
                  <Descriptions.Item label="E-mail">{item.email}</Descriptions.Item>
                  <Descriptions.Item label="Relation">{item.relation}</Descriptions.Item>
                  <Descriptions.Item label="Address">{item.address}</Descriptions.Item>
                </Descriptions>
              </Widget>
            </Col>
          )
        })}
      {references
        .filter((item) => !item.grantor)
        .map((item, index) => {
          return (
            <Col key={index} xs={24} sm={12} md={12} lg={12} xl={12}>
              <Widget
                title={
                  item.grantor ? (
                    <Tag icon={<SafetyCertificateOutlined />} color="#87d068">
                      Grantor
                    </Tag>
                  ) : (
                    'Reference'
                  )
                }
              >
                <Descriptions column={1} bordered size="small" labelStyle={{ width: 180 }}>
                  <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
                  <Descriptions.Item label="Mobile">{item.mobile}</Descriptions.Item>
                  <Descriptions.Item label="Nid">{item.nid}</Descriptions.Item>
                  <Descriptions.Item label="E-mail">{item.email}</Descriptions.Item>
                  <Descriptions.Item label="Relation">{item.relation}</Descriptions.Item>
                  <Descriptions.Item label="Address">{item.address}</Descriptions.Item>
                </Descriptions>
              </Widget>
            </Col>
          )
        })}
    </Row>
  )
}

Reference.defaultProps = {
  data: {
    references: [],
  },
}

Reference.propTypes = {
  data: PropTypes.object,
}
export default Reference
