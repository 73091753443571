import React from 'react'
import PropTypes from 'prop-types'
import { Col, Descriptions, Row } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
//
import Widget from '../../Widget'
import { FORM_NAMES } from 'util/constants'
import { REST_API } from 'services/rest.api'

const Contact = ({ data, itemAddButton, handleEdit, handleDelete }) => {
  const { contacts } = data
  return (
    <Row>
      {contacts.map((item, index) => {
        return (
          <Col key={index} xs={24} sm={12} md={12} lg={12} xl={12}>
            <Widget
              title={item.emergency ? 'Emergency contact' : 'Contact'}
              extra={
                <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey">
                  <li onClick={() => handleEdit(item, FORM_NAMES.EMPLOYEE_CONTACT)}>
                    <EditOutlined />
                  </li>
                  <li
                    onClick={() =>
                      handleDelete({
                        ...item,
                        body: { contact_id: item.id },
                        route: REST_API.EMPLOYEES_ROUTE.CONTACTS,
                      })
                    }
                  >
                    <DeleteOutlined />
                  </li>
                </ul>
              }
            >
              <Descriptions column={1} bordered size="small">
                <Descriptions.Item label="Name">{item.name}</Descriptions.Item>
                <Descriptions.Item label="Mobile">{item.mobile}</Descriptions.Item>
                <Descriptions.Item label="Nid">{item.nid}</Descriptions.Item>
                <Descriptions.Item label="E-mail">{item.email}</Descriptions.Item>
                <Descriptions.Item label="Relation">{item.relation}</Descriptions.Item>
                <Descriptions.Item label="Emergency">
                  {item.emergency ? 'YES' : 'NO'}
                </Descriptions.Item>
                <Descriptions.Item label="Address">{item.address}</Descriptions.Item>
              </Descriptions>
            </Widget>
          </Col>
        )
      })}
      <Col span={12}>
        <Widget styleName="gx-flex-row gx-justify-content-center">
          <span style={{ fontSize: '13.9rem' }}>
            {itemAddButton('Add Contact', FORM_NAMES.EMPLOYEE_CONTACT)}
          </span>
        </Widget>
      </Col>
    </Row>
  )
}

Contact.defaultProps = {
  data: {
    contacts: [],
  },
}

Contact.propTypes = {
  itemAddButton: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  data: PropTypes.object,
}
export default Contact
