/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker, Form, Input, Modal, Radio, Select, Spin } from 'antd'
import moment from 'moment'
//
import { showMessage } from 'util/helpers'
import { DATE_FORMAT, FORM_ITEM_LAYOUT, DATE_TIME_FORMAT, ALERT_TYPES } from 'util/constants'

const FormModal = ({
  visible,
  formName,
  width,
  handleCancel,
  handleOk,
  title,
  form,
  durations,
  types,
  responsible,
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedFormat, setSelectedFormat] = useState(1)

  const leaveTypes = React.useMemo(
    () =>
      selectedFormat === 1
        ? types?.map((i) => ({ value: i.id, label: i.name }))
        : types
          .filter((i) => i.short_leave_applied)
          .map((leave) => ({ label: leave.name, key: leave.name, value: leave.id })),
    [selectedFormat, types]
  )
  const durationTypes = React.useMemo(
    () => durations.map((v) => ({ value: v.id, label: v.name })),
    [durations]
  )

  useEffect(() => {
    const typeId = form.getFieldValue('leave_format')
    setSelectedFormat(typeId ?? 1)
  }, [form])

  const onValuesChange = (values) => {
    if (values.leave_format) {
      form.resetFields()
      form.setFieldsValue({ leave_format: values.leave_format })
    }
    Object.keys(values).forEach((field) => {
      const error = form.getFieldError(field)
      if (!error.length) {
        return
      }
      // Clear error message of field
      form.setFields([
        {
          name: field,
          errors: [],
        },
      ])
    })
  }

  const handleSubmit = async (value) => {
    setLoading(true)
    try {
      await handleOk(value)
      form.resetFields()
      handleCancel()
      // history.push(`/kpi/${res?.id}`)
    } catch (error) {
      try {
        console.error('PRINT IN %s=====>', 'Form Submit First', error)
        const eRes = await error.response.json().then((res) => res)
        showMessage(ALERT_TYPES.ERROR, eRes.message)
        const errors = eRes.errors.children
        const data = []
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const v in value) {
          const i = {
            name: v,
            ...errors[v],
          }
          data.push(i)
        }
        form.setFields(data)
      } catch (error2) {
        console.error('PRINT IN %s=====>', 'Form Submit', error2)
        showMessage(ALERT_TYPES.WARN, 'Oops, Something Wrong!')
      }
    }
    setLoading(false)
  }

  return (
    <Modal
      visible={visible}
      width={width}
      title={title}
      okText="Save"
      cancelText="Cancel"
      confirmLoading={loading}
      destroyOnClose
      onCancel={() => {
        form.resetFields()
        handleCancel()
      }}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSubmit(values)
          })
          .catch((info) => {
            console.error('Validate Failed:', info)
          })
      }}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          onValuesChange={onValuesChange}
          onFieldsChange={() => {
            const formatID = form.getFieldValue('leave_format')
            const durationID = form.getFieldValue('duration_id')
            const fromDate = form.getFieldValue('from')
            setSelectedFormat(formatID)
            if (formatID === 2 && durationID && fromDate) {
              const selectedDU = durations.filter((d) => d.id === durationID)[0] || {}
              const value = moment(fromDate).add(selectedDU.minutes, 'm')
              form.setFieldsValue({
                to: moment(value, 'DD-MM-YYYY HH:mm'),
              })
            }
          }}
          name={formName}
        >
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="leave_format"
            label="Format"
            rules={[
              {
                required: true,
                message: 'Select format',
              },
            ]}
          >
            <Radio.Group
              options={[
                { value: 1, label: 'Full Day' },
                { value: 2, label: 'Short' },
              ]}
            />
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="type_id"
            label="Type"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              allowClear
              className="gx-w-100"
              placeholder="Select type"
              options={leaveTypes}
            />
          </Form.Item>
          {selectedFormat === 2 && (
            <Form.Item
              {...FORM_ITEM_LAYOUT}
              name="duration_id"
              label="Duration"
              rules={[{ required: true }]}
            >
              <Select
                allowClear
                className="gx-w-100"
                placeholder="Select duration"
                options={durationTypes}
              />
            </Form.Item>
          )}
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="from"
            label="From"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {selectedFormat === 2 ? (
              <DatePicker
                className="gx-w-100"
                showTime
                placeholder="From date time"
                format={DATE_TIME_FORMAT}
              />
            ) : (
              <DatePicker
                className="gx-w-100"
                showTime={false}
                placeholder="From date"
                format={DATE_FORMAT}
              />
            )}
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="to"
            label="To"
            rules={[
              {
                required: true,
              },
            ]}
          >
            {selectedFormat === 2 ? (
              <DatePicker
                className="gx-w-100"
                showTime
                disabled
                placeholder="To date time"
                format={DATE_TIME_FORMAT}
              />
            ) : (
              <DatePicker
                className="gx-w-100"
                showTime={false}
                placeholder="To date"
                format={DATE_FORMAT}
              />
            )}
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="responsible_id"
            label="Responsible"
            rules={[
              {
                required: true,
                message: 'Please select responsible person',
              },
            ]}
          >
            <Select
              allowClear
              className="gx-w-100"
              placeholder="Select responsible person"
              options={responsible}
            />
          </Form.Item>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="leave_address"
            label="Leave address"
            rules={[
              {
                required: true,
              },
              {
                max: 250,
                message: 'length should be less than or equal to 250',
              },
            ]}
          >
            <Input.TextArea allowClear />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

FormModal.propTypes = {
  visible: PropTypes.bool,
  formName: PropTypes.string,
  width: PropTypes.number,
  title: PropTypes.string,
  types: PropTypes.array,
  durations: PropTypes.array,
  responsible: PropTypes.array,
  handleCancel: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  handleOk: PropTypes.func.isRequired,
}

FormModal.defaultProps = {
  formName: 'dynamic_rule',
  title: 'form title',
  types: [],
  durations: [],
  responsible: [],
  visible: false,
  width: 550,
}

export default FormModal
