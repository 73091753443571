/* eslint-disable camelcase */
import { EmployeeService } from 'services'
import { PAGINATION_TYPES } from 'util/constants'
import { pagePaginationSuccess } from 'appRedux/reducers/Pagination'
import { setLoading } from 'appRedux/reducers/Loading'

export const fetchTeamById = ({ page = 1, ...searchParams }, body, viewType) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.team
    const loadingObj = {
      key,
      value: true,
    }
    try {
      const user = JSON.parse(localStorage.getItem('currentUser'))
      const { employee_id } = user ?? {}
      if (!employee_id) {
        return
      }
      dispatch(setLoading(loadingObj))

      const {
        items,
        pagination: { self, last },
      } = getState().pagination[key]
      const res =
        (!(parseInt(self, 10) === last) || Object.values(searchParams).length > 0) &&
        (await EmployeeService.fetchAll(
          { page, ...searchParams },
          {
            ...body,
            id: employee_id,
            team_search: true,
          }
        ))
      const allItems = viewType !== 'table' && page > 1 ? [...items, ...res.items] : res.items
      res.items = allItems
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { ...res },
            key,
          })
        )
    } catch (e) {
      console.error('FETCH TEAM BY ID ===>', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const fetchTeam = () => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.team
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EmployeeService.find()
      dispatch(
        pagePaginationSuccess({
          data: { count: res.length, total: res.length, items: res },
          key,
        })
      )
    } catch (e) {
      console.error('FETCH TEAM ===>', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
