import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createRootReducer from '../reducers'

const createBrowserHistory = require('history').createBrowserHistory

export const history = createBrowserHistory()

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
]
const rootReducer = (state, action) => {
  if (action.type === 'auth/userSignOutSuccess') { // check for action type 
    state = { router: { location: {}, action: {} } }
  }
  return createRootReducer(state, action)
};
const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
})

export default store
