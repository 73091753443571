import request from 'util/request'
import { FETCH_TYPES } from 'util/constants'
import { ServiceBase } from './ServiceBase'

export class Leaves extends ServiceBase {
  async createItem(route, body) {
    const options = { method: FETCH_TYPES.POST, body }
    return await request(this.API_ENDPOINT + route, options)
  }
  async patchItem(id, route, body) {
    const options = { method: FETCH_TYPES.PATCH, body }
    return await request(this.API_ENDPOINT + route + '/' + id, options)
  }
  async removeItem(route, body) {
    const options = { method: FETCH_TYPES.DELETE, body }
    return await request(this.API_ENDPOINT + route, options)
  }
}
