/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Descriptions, Modal, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
//
import { EMPLOYEE_RATING_COLOR, LM_RATING_COLOR, RATING_STYLE } from 'util/constants'
import { capitalizeFirstLetter } from 'util/helpers'
import Widget from '../Widget'

const StableItem = ({ item, index, isReverse, eprStatusID, isSelfEPR, handleEdit }) => {
  const { id, original, rating, comment, lm_rating, lm_comment } = item
  const [visible, setVisible] = React.useState(false)
  return (
    <Col key={`epr-kpi-details-${index}`} xs={24} sm={12} md={12} lg={12} xl={12}>
      <Widget
        className="gx-mb-0"
        title={
          <div className="gx-pl-3">
            {original?.name}{' '}
            <span onClick={() => setVisible(!visible)}>
              {' '}
              <QuestionCircleOutlined />
            </span>
          </div>
        }
        extra={
          !isReverse && (
            <div className="gx-pr-3">
              <EditOutlined
                // eslint-disable-next-line consistent-return
                onClick={() => {
                  if (eprStatusID >= 3 && !isSelfEPR) return null
                  if (![1, 5].includes(eprStatusID) && isSelfEPR) return null

                  handleEdit({ ...item, stable_id: id })
                }}
              />
            </div>
          )
        }
      >
        <Descriptions
          // eslint-disable-next-line react/no-array-index-key
          key={`epr-kpi-ob-details-${index}`}
          className="gx-mb-4"
          bordered
          column={1}
          size="small"
          labelStyle={{ width: 120, color: '#6E6B7B' }}
        >
          <Descriptions.Item label="Rating" style={{ color: '#6E6B7B' }}>
            {rating}
          </Descriptions.Item>
          <Descriptions.Item label="Comment" style={{ color: '#6E6B7B' }}>
            {comment}
          </Descriptions.Item>
          <Descriptions.Item label="LM rating" style={{ color: '#6E6B7B' }}>
            {lm_rating}
          </Descriptions.Item>
          <Descriptions.Item label="LM comment" style={{ color: '#6E6B7B' }}>
            {lm_comment}
          </Descriptions.Item>
        </Descriptions>
      </Widget>
      <Modal
        title={original?.name}
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Descriptions
          // eslint-disable-next-line react/no-array-index-key
          key={`epr-kpi-ob-details-${index}`}
          className="gx-mb-4"
          column={1}
          bordered
          size="small"
          labelStyle={{ width: 150 }}
        >
          <Descriptions.Item label="Objective">
            {original?.objectives?.map((ob = {}) => {
              const { enabled, name } = ob ?? {}
              return (
                <>
                  {enabled && (
                    <div key={name} className="gx-flex-row">
                      <p> {name}</p>
                    </div>
                  )}
                </>
              )
            })}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </Col>
  )
}
const SectionB = ({ section_b, handleEdit, eprStatusID, isSelfEPR }) => {
  const { rating, comment, lm_rating, lm_comment, stables } = section_b
  const isReverse = React.useMemo(() => {
    if (eprStatusID >= 3 && !isSelfEPR) return true
    if (![1, 5].includes(eprStatusID) && isSelfEPR) return true
    return false
  }, [eprStatusID, isSelfEPR])
  return (
    <Row style={{ flexDirection: isReverse ? 'column-reverse' : 'column' }}>
      <Col span={24}>
        <Row>
          {stables?.map((item = {}, index) => {
            return (
              <StableItem
                item={item}
                key={`section_b_stable_${index}`}
                index={index}
                eprStatusID={eprStatusID}
                isSelfEPR={isSelfEPR}
                handleEdit={handleEdit}
                isReverse={isReverse}
              />
            )
          })}
        </Row>
      </Col>
      <Col xs={24} className="gx-mt-3">
        <Widget
          title={<div className="gx-pl-3">Overall rating</div>}
          extra={
            !isReverse && (
              <div className="gx-pr-3">
                <EditOutlined
                  // eslint-disable-next-line consistent-return
                  onClick={() => {
                    if (eprStatusID >= 3 && !isSelfEPR) return null
                    if (![1, 5].includes(eprStatusID) && isSelfEPR) return null
                    handleEdit(section_b)
                  }}
                />
              </div>
            )
          }
        >
          <Row>
            <Col span={12}>
              <div className="gx-py-3 gx-pl-0" style={{ fontSize: 16 }}>
                Eoner's Self Rating
              </div>
              <Row>
                {/* Rating */}
                <Col span={6}>
                  <div
                    style={{
                      ...RATING_STYLE,
                      ...EMPLOYEE_RATING_COLOR,
                      borderWidth: 0,
                      width: '100%',
                      height: '100px',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(219, 239, 250, 1)',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        margin: 7,
                        fontSize: '34px',
                        color: 'rgba(16, 149, 223, 1)',
                      }}
                    >
                      0{rating}
                    </div>
                  </div>
                </Col>
                <Col span={18}>
                  <p className="gx-mb-0">{capitalizeFirstLetter(comment)}</p>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <div className="gx-py-3 gx-pl-0" style={{ fontSize: 16 }}>
                Line Manager Rating
              </div>
              <Row>
                {/* Rating */}
                <Col span={6}>
                  <div
                    style={{
                      ...RATING_STYLE,
                      ...LM_RATING_COLOR,
                      borderWidth: 0,
                      width: '100%',
                      height: '100px',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(219, 239, 250, 1)',
                    }}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        margin: 7,
                        fontSize: '34px',
                        color: 'rgba(16, 149, 223, 1)',
                      }}
                    >
                      0{lm_rating}
                    </div>
                  </div>
                </Col>
                <Col span={18}>
                  <p className="gx-mb-0">{capitalizeFirstLetter(lm_comment)}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Widget>
      </Col>
    </Row>
  )
}

SectionB.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  eprStatusID: PropTypes.number.isRequired,
  isSelfEPR: PropTypes.bool.isRequired,
  section_b: PropTypes.shape({
    rating: PropTypes.number,
    lm_rating: PropTypes.number,
    comment: PropTypes.string,
    lm_comment: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    stables: PropTypes.any,
  }),
}

SectionB.defaultProps = {
  section_b: PropTypes.shape({
    rating: 0,
    lm_rating: 0,
    comment: '',
    lm_comment: '',
    stables: {},
  }),
}
export default SectionB
