import { createSlice } from '@reduxjs/toolkit'
//
import {
  LAYOUT_TYPE_FULL,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE_SEMI_DARK,
} from 'util/constants/ThemeSetting'

const INIT_STATE = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: THEME_COLOR,

  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
}

export const settingsSlice = createSlice({
  name: 'themeSettings',
  initialState: INIT_STATE,
  reducers: {
    switchLanguage: (state, { payload }) => ({ ...state, locale: payload }),
    setThemeType: (state, { payload }) => ({ ...state, themeType: payload }),
    onNavStyleChange: (state, { payload }) => ({ ...state, navStyle: payload }),
    setThemeColor: (state, { payload }) => ({ ...state, themeColor: payload }),
    onLayoutTypeChange: (state, { payload }) => ({ ...state, layoutType: payload }),
  },
})

export const { switchLanguage, setThemeType, onNavStyleChange, setThemeColor, onLayoutTypeChange } =
  settingsSlice.actions

export default settingsSlice.reducer
