import { createSlice } from '@reduxjs/toolkit'
const user = JSON.parse(localStorage.getItem('currentUser'))
const INIT_STATE = {
  initURL: '',
  authUser: { user },
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: INIT_STATE,
  reducers: {
    userSignInSuccess: (state, { payload }) => ({ ...state, authUser: payload }),
    userSignOutSuccess: (state) => ({ ...state, authUser: { user: null }, initURL: '/' }),
    setInitUrl: (state, { payload }) => ({ ...state, initURL: payload }),
  },
})

export const { userSignInSuccess, userSignOutSuccess, setInitUrl } = authSlice.actions
export default authSlice.reducer
