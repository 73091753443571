/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Form, Input, InputNumber, Modal, Radio, Spin } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
//
import Auxiliary from 'util/Auxiliary'
import FormModal from 'components/FormModal'
import { FORM_ITEM_LAYOUT, PAGINATION_TYPES, SECTION_TAB } from 'util/constants'
import { fetchEPRById, updateEPRSection, eprPatchActions } from 'appRedux/actions'
import { selectPaginationItem } from 'appRedux/reducers/Pagination'
import { fetchStaticItem } from '../../appRedux/actions/Common'
import { REST_API } from '../../services/rest.api'
import { ObjectiveService } from '../../services'
import { ERPEmployeeBanner, SectionA, SectionB, SectionC, SectionD } from '../../components/EPR'
import { showDeleteConfirm, showMessage } from '../../util/helpers'
import { ALERT_TYPES } from '../../util/constants'
import { deleteSectionDDevPlane } from '../../appRedux/actions/EPR'

const EPRRatingForm = ({ isNumber }) => {
  return (
    <>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="rating"
        label="Rating"
        rules={[
          {
            required: true,
          },
        ]}
      >
        {isNumber ? (
          <InputNumber min={1} className="gx-w-100" />
        ) : (
          <Radio.Group options={[1, 2, 3]?.map((i) => ({ value: i, label: i }))} />
        )}
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="comment"
        label="Comment"
        rules={[
          {
            max: 2000,
            min: 0,
            message: 'Comment must be less then 2001 characters',
          },
        ]}
      >
        <Input.TextArea allowClear />
      </Form.Item>
    </>
  )
}
const EPRSectionCForm = () => {
  return (
    <>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="kpi_rating"
        label="KPI Rating"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group options={[1, 2, 3]?.map((i) => ({ value: i, label: i }))} />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="stable_rating"
        label="Stable Rating"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Radio.Group options={[1, 2, 3]?.map((i) => ({ value: i, label: i }))} />
      </Form.Item>
    </>
  )
}
const EPRSectionDForm = ({ isLM, devAreas }) => {
  return (
    <>
      {isLM ? (
        <>
          <Form.Item {...FORM_ITEM_LAYOUT} name="lm_remarks"
label="Comment">
            <Input.TextArea allowClear />
          </Form.Item>
        </>
      ) : (
        <>
          <Form.Item {...FORM_ITEM_LAYOUT} name="remarks"
label="Comment">
            <Input.TextArea allowClear />
          </Form.Item>
        </>
      )}
    </>
  )
}

const EPRSectionDDevPlanForm = () => {
  return (
    <>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="action_plan"
        label="Action plan"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="dev_area"
        label="Dev area"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="who"
        label="Who"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item
        {...FORM_ITEM_LAYOUT}
        name="when"
        label="When"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input allowClear />
      </Form.Item>
    </>
  )
}

const EPRDetails = () => {
  const { eprId } = useParams()
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [activeTabKey, setActiveTabKey] = useState('section_a')
  const [visible, setVisible] = useState(false)
  const [isEditActual, setEditActual] = useState([])
  const [editItem, setEditItem] = useState(null)

  useEffect(() => {
    dispatch(fetchEPRById(eprId))
    dispatch(fetchStaticItem(REST_API.objective_perspectives, ObjectiveService))
    // dispatch(fetchStaticItem(REST_API.EPRS.DEVELOPMENT_AREA, EprService))
  }, [eprId, dispatch])

  const EPR = useSelector(selectPaginationItem({ key: PAGINATION_TYPES.epr, id: eprId }))
  const { authUser, devAreas, perspectives, isLoading } = useSelector(({ auth, common, loading }) => ({
    authUser: auth?.authUser?.user ?? {},
    perspectives: common[REST_API.objective_perspectives],
    // devAreas: common[REST_API.EPRS.DEVELOPMENT_AREA],
    isLoading: loading[PAGINATION_TYPES.epr],
  }))

  const employee = useMemo(() => EPR?.employee, [EPR])
  const isSelfEPR = useMemo(() => employee?.id === authUser?.employee_id, [authUser, employee])
  const isLM = useMemo(() => authUser?.employee_id === EPR?.line_manager?.id, [authUser, EPR])
  const isOneOverOne = useMemo(
    () => authUser?.employee_id === EPR?.one_over_one?.id,
    [authUser, EPR]
  )

  const handleEdit = (item) => {
    const { rating, lm_rating, comment, lm_comment, dev_areas, lm_dev_areas, section_d_id } = item
    const value = { ...item }

    switch (activeTabKey) {
      case 'section_a':
        value.title = 'Section A Rating & Comment'
        value.formName = 'epr_section_a_form'
        value.component = <EPRRatingForm isNumber={!!item.objective_kpi_id} />
        break
      case 'section_b':
        value.title = 'Section B Rating & Comment'
        value.formName = 'epr_section_b_form'
        value.component = <EPRRatingForm />
        break
      case 'section_c':
        value.title = 'Section C Rating'
        value.formName = 'epr_section_c_form'
        value.component = <EPRSectionCForm />
        break
      case 'section_d':
        value.title = 'Section D'
        value.formName = 'epr_section_d_form'
        value.component = section_d_id ? (
          <EPRSectionDDevPlanForm />
        ) : (
          <EPRSectionDForm isLM={isLM} devAreas={[]} />
        )
        break
      default:
        break
    }
    setEditItem(value)
    form.setFieldsValue({
      ...item,
      dev_areas: dev_areas?.map((v) => v.id),
      lm_dev_areas: lm_dev_areas?.map((v) => v.id),
      rating: isLM ? lm_rating : rating,
      comment: isLM ? lm_comment : comment,
    })
    setVisible(true)
    return null
  }

  const handleActions = (route) => {
    if (route !== 'Approve') {
      dispatch(eprPatchActions(route, { epr_id: eprId }))
    } else {
      const route_path = isLM
        ? REST_API.KPI_ACTIONS.KPI_APPROVE_LM
        : REST_API.KPI_ACTIONS.KPI_APPROVE
      dispatch(eprPatchActions(route_path, { epr_id: eprId }))
    }
  }

  const eprStatusID = useMemo(() => EPR?.status?.id ?? '0', [EPR])
  const handleSubmitSectionA = async (value) => {
    try {
      const id = EPR?.id
      const values = {
        objectives: value,
        id,
        section: 'section_a',
        route: `/${id}${REST_API.EPR_ACTIONS.SECTION_A_OBJECTIVE}`,
      }
      setEditActual([])
      const res = await dispatch(updateEPRSection(values))
      showMessage(ALERT_TYPES.SUCCESS, 'Section A has been updated!')
      return res
    } catch (error) {
      console.error('PRINT IN %s=====>', 'Objective Submit ERROR*** ***', error)
    }
    return null
  }
  const handleSubmit = (value) => {
    const { rating, comment } = value
    const { objective_kpi_id, stable_id, section_d_id, dev_plan_id } = editItem
    const id = EPR?.id
    let values = {}
    if (!isSelfEPR && activeTabKey !== 'section_d' && activeTabKey !== 'section_c') {
      values = {
        lm_rating: rating,
        lm_comment: comment,
      }
    } else if (activeTabKey !== 'section_d' && activeTabKey !== 'section_c') {
      values = { rating, comment }
    }
    values.section = activeTabKey
    values.id = id // EPR ID;
    switch (activeTabKey) {
      case 'section_a':
        values.route = objective_kpi_id
          ? `${REST_API.EPR_ACTIONS.SECTION_A_OBJECTIVE}/${objective_kpi_id}`
          : `/${id}${REST_API.EPR_ACTIONS.SECTION_A}`
        break
      case 'section_b':
        values.route = stable_id
          ? `${REST_API.EPR_ACTIONS.SECTION_B_STABLE}/${stable_id}`
          : `/${id}${REST_API.EPR_ACTIONS.SECTION_B}`
        values.stable_id = stable_id
        break
      case 'section_c':
        values = { ...values, ...value }
        values.route = `/${id}${REST_API.EPR_ACTIONS.SECTION_C}`
        break
      case 'section_d':
        values = { ...values, ...value, section_d_id, dev_plan_id }
        values.route = section_d_id
          ? REST_API.EPR_ACTIONS.SECTION_D_DEV_PLAN(section_d_id)
          : `/${id}${REST_API.EPR_ACTIONS.SECTION_D}`
        break
      default:
        break
    }

    const res = dispatch(updateEPRSection(values))
    return res
  }
  const handleDelete = (item) => {
    showDeleteConfirm(<ExclamationCircleOutlined />, item.action_plan, () => {
      return dispatch(deleteSectionDDevPlane({ id: EPR.id, dev_plan_id: item.id }))
    })
  }

  const handleActualChange = (id, oldValue, value, isSelf = true) => {
    const newObjectives = [...isEditActual]
    const element = {
      objective_id: id,
    }
    isSelf ? element.actual = value : element.lm_actual = value
    const i = newObjectives.findIndex((item) => item.objective_id === id)
    if (i > -1) {
      newObjectives[i] = element
    } else {
      newObjectives.push(element)
    }
    setEditActual(newObjectives)
  }

  const getActiveTab = (tabKey) => {
    let node
    switch (tabKey) {
      case 'section_a':
        node = (
          <SectionA
            perspectives={perspectives}
            section_a={EPR?.section_a}
            total={EPR}
            handleEdit={handleEdit}
            handleSubmit={handleSubmitSectionA}
            isSelfEPR={isSelfEPR}
            isLM={isLM}
            eprStatusID={eprStatusID}
            handleActualChange={handleActualChange}
            Objectives={isEditActual}
          />
        )
        break
      case 'section_b':
        return (
          <SectionB
            section_b={EPR?.section_b}
            handleEdit={handleEdit}
            isSelfEPR={isSelfEPR}
            isLM={isLM}
            eprStatusID={eprStatusID}
          />
        )
      case 'section_c':
        node = (
          <SectionC
            section_c={EPR?.section_c}
            section_a={EPR?.section_a}
            section_b={EPR?.section_b}
            handleEdit={handleEdit}
            isLM={isLM}
            eprStatusID={eprStatusID}
          />
        )
        break
      case 'section_d':
        node = (
          <SectionD
            section_d={EPR?.section_d}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            isLM={isLM}
            isSelfEPR={isSelfEPR}
            eprStatusID={eprStatusID}
          />
        )
        break
      default:
        break
    }
    return node
  }

  const user = React.useMemo(() => ({
    ...employee,
    epr_name: EPR?.name,
    epr_type: EPR?.type?.name,
    epr_status: EPR?.status?.name,
    epr_status_id: EPR?.status?.id,
    EPR: {
      name: EPR?.name,
      description: `(${EPR?.type?.name} : ${moment(EPR?.from_date).format('YYYY')}-${moment(EPR?.to_date).format('YYYY')})`
    },
    KPI: {
      name: EPR?.kpi?.name,
      description: undefined,
    }
  }), [EPR, employee])

  const confirm = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: (<><div>Are you sure you want to submit EPR? </div><div> You won't be able to edit it once submitted.</div></>),
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleActions(REST_API.KPI_ACTIONS.KPI_SUBMIT),
    })
  }
  const confirmActual = () => {
    Modal.confirm({
      title: 'Unsaved Actual Value',
      icon: <ExclamationCircleOutlined />,
      content: "It's seems you forgot to save last update, Please Save it to Submit.",
      okText: 'OK',
      cancelText: 'Cancel',
      onOk: () => handleSubmitSectionA(isEditActual),
      onCancel: () => setEditActual([]),
    })
  }
  const renderRightContent = React.useCallback(() => {
    return (
      <>
      {[1, 5].includes(eprStatusID) && isSelfEPR ? (
        <Button type='primary' onClick={() => isEditActual.length > 0 ? confirmActual() : confirm()}>
          Submit EPR
        </Button>
      ) : (
        <>
          {eprStatusID === 2 && isLM && (
            <>
              <Button
                onClick={() => handleActions(REST_API.KPI_ACTIONS.KPI_UPDATE_REQUEST)}
              >
                Update Request
              </Button>
              <Button 
               type='primary' onClick={() => isEditActual.length > 0 ? confirmActual() : handleActions('Approve')}>Approve</Button>
            </>
          )}
          {eprStatusID === 3 && isOneOverOne && (
            <>
              <Button
                onClick={() => handleActions(REST_API.KPI_ACTIONS.KPI_UPDATE_REQUEST)}
              >
                Update Request
              </Button>
              <Button
               type='primary' onClick={() => handleActions('Approve')}>Approve</Button>
            </>
          )}
        </>
      )}
      </>
    )
  }, [EPR, isEditActual])
  return (
    <Auxiliary>
      <Spin spinning={isLoading}>
        <ERPEmployeeBanner {...{ user, editable: false, rightContent: renderRightContent() }} />
        <Card
          tabList={SECTION_TAB}
          bodyStyle={{ background: '#F8F8F8' }}
          activeTabKey={activeTabKey}
          onTabChange={(key) => {
            setActiveTabKey(key)
          }}
        >
          <div className="gx-mt-0">{EPR && getActiveTab(activeTabKey)}</div>
        </Card>
      </Spin>
      {visible && (
        <FormModal
          {...{
            visible,
            handleCancel: () => setVisible(false),
            handleOk: (value) => {
              handleSubmit(value)
            },
            title: editItem.title,
            formName: editItem.formName,
            form,
            // eslint-disable-next-line react/display-name
            component: () => editItem.component,
          }}
        />
      )}
    </Auxiliary>
  )
}

export default EPRDetails
