import React from 'react'
import PropTypes from 'prop-types'
//
import ContactCell from './ContactCell'

const ContactList = ({ contactList, handleEdit, handleDelete }) => {
  return (
    <div className="gx-contact-main-content">
      {contactList.map((contact, index) => (
        <ContactCell
          key={index}
          contact={contact}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      ))}
    </div>
  )
}

ContactList.propTypes = {
  contactList: PropTypes.array,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
}

export default ContactList
