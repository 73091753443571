import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, TreeSelect } from 'antd'
//
import { FORM_ITEM_LAYOUT } from 'util/constants'
const { TreeNode } = TreeSelect

export const ShowTree = (item) => {
  return (
    <TreeNode
      value={`${item.title}#${item.key}`}
      key={`${item.title}#${item.key}`}
      disabled={item.isGroup}
      title={item.title}
    >
      {item.children && item.children.map((item) => ShowTree(item))}
    </TreeNode>
  )
}

const EmployeeGroupsForm = ({ groups }) => {
  return (
    <>
      <Row>
        <Col span={18}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="tree_id" label="Select Group">
            <TreeSelect
              showSearch
              allowClear
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Please select"
              // treeData={groups2}
            >
              {groups.map((item) => ShowTree(item))}
            </TreeSelect>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

EmployeeGroupsForm.defaultProps = {
  groups: [],
}

EmployeeGroupsForm.propTypes = {
  groups: PropTypes.array,
}
export default EmployeeGroupsForm
