import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Table } from 'antd'
//
import Widget from '../../Widget'

const AssetCard = ({ item: { name, description }, bgColor, handleDelete }) => (
  <Widget styleName={'gx-card-full'}>
    <div className={`gx-fillchart gx-bg-${bgColor} gx-fillchart-nocontent`}>
      <div className="ant-card-head-title">{name}</div>
      <p className="gx-ml-3 gx-fs-sm">{description}</p>
      <div className="gx-fillchart-btn-close" onClick={() => handleDelete()}>
        <span className="gx-fs-lg">
          <i className="icon icon-trash" />
        </span>
      </div>
    </div>
  </Widget>
)

AssetCard.propTypes = {
  handleDelete: PropTypes.func,
  item: PropTypes.object,
  bgColor: PropTypes.string,
}

const Assets = ({ data }) => {
  const { assets } = data
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 150,
      key: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
  ];
  const renderAssets = () => (
    <Row
      justify="center"
      align="middle"
    >
      <Col span={23}>
        <Table
          columns={columns}
          dataSource={assets}
          pagination={false}
          rowKey={item => item.id}
        />
      </Col>
    </Row>
  );
  return (
    <>
      <Widget>
        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-mb-sm-4 gx-dash-search">
          <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2">Assets Information</h2>
        </div>
        {renderAssets()}
      </Widget>
    </>
  )
}

Assets.defaultProps = {
  data: {
    assets: [],
  },
  assetList: [],
}

Assets.propTypes = {
  data: PropTypes.object,
}
export default Assets
