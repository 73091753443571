import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Col, Descriptions, Row } from 'antd'
//
import { DATE_FORMAT } from 'util/constants'
import Widget from '../../Widget'

const generalLabel = {
  Birthday: 'dob',
  POB: 'pob',
  'Joining Date': 'joining_date',
  'Confirmation Date': 'confirmation_date',
  Gender: 'gender',
  Religion: 'religion',
  'Passport No': 'passport_no',
  Nid: 'nid',
  'Driving license': 'driving_license',
  Tin: 'tin',
  Nationality: 'nationality',
}
const General = ({ data }) => {
  const { general, address } = data
  const keys = Object.keys(generalLabel)
  const generalValue = (key) => {
    let value
    const keyValue = general[generalLabel[key]]
    switch (key) {
      case 'Gender':
        value = keyValue ? keyValue.name : ''
        break
      case 'Religion':
        value = keyValue ? keyValue.name : ''
        break
      case 'Joining Date':
        value = keyValue && moment(keyValue).format(DATE_FORMAT)
        break
      case 'Confirmation Date':
        value = keyValue && moment(keyValue).format(DATE_FORMAT)
        break
      case 'Birthday':
        value = keyValue && moment(keyValue).format('DD, MMMM')
        break
      default:
        value = keyValue
        break
    }
    return value
  }

  return (
    <Row>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="General Information">
          <Descriptions column={1} bordered size="small" labelStyle={{ width: 180 }}>
            {keys.map((item) => (
              <Descriptions.Item key={`employee_general_${item}`} label={item}>
                {generalValue(item)}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Address Information">
          <Descriptions column={1} bordered labelStyle={{ width: 150 }} size="small">
            <Descriptions.Item label="Present">{address?.present}</Descriptions.Item>
            <Descriptions.Item label="Permanent">{address?.permanent}</Descriptions.Item>
          </Descriptions>
        </Widget>
      </Col>
    </Row>
  )
}

General.defaultProps = {
  data: {
    general: {},
  },
}

General.propTypes = {
  data: PropTypes.object,
}

export default General
