import React from 'react'
import PropTypes from 'prop-types'
// import loader from "../../assets/images/loader.svg"

const CircularProgress = ({ className }) => (
  <div className={`loader ${className}`}>
    {/* <h3>Loading....</h3> */}
    {/* <img src={loader} alt="loader"/> */}
  </div>
)

CircularProgress.defaultProps = {
  className: '',
}
CircularProgress.propTypes = {
  className: PropTypes.string,
}

export default CircularProgress
