import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Popover } from 'antd'
import PropTypes from 'prop-types'
import { userSignOut } from 'appRedux/actions/Auth'

const UserInfo = ({ showName }) => {
  const res = localStorage.getItem('currentUser')
  const user = JSON.parse(res)
  const { employee_id: employeeId } = user || {}

  const dispatch = useDispatch()
  const history = useHistory()
  const { auth } = useSelector(({ common }) => ({
    auth: common[`EMPLOYEE_/${employeeId}`],
  }))
  const goToProfile = () => {
    if (!user?.employee_id) return
    history.push(`/profile`)
  }
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => goToProfile()}>My Profile</li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  )

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar
          src={auth?.photo?.medium ?? 'https://via.placeholder.com/150'}
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        {showName && (
          <span className="gx-avatar-name">
            {user?.username}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
          </span>
        )}
      </Popover>
    </div>
  )
}

UserInfo.propTypes = {
  showName: PropTypes.bool,
}

export default UserInfo
