import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { PieChart } from 'react-minimal-pie-chart'
import { Row, Col, Descriptions } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
//
import { GroupCard } from 'components/Employee'
import { BG_COLOR_LIST } from 'util/constants'
import { getRandomInt } from 'util/helpers'
import Widget from 'components/Widget'

const leaveData = [
  {
    name: 'Annual Leave',
    days: [
      { title: 'Remaining', value: 6, color: '#038fde', hoveredColor: '#52c6f7' },
      { title: 'Passing', value: 9, color: '#fa8c15', hoveredColor: '#d87205' },
    ],
  },
  {
    name: 'Medical Leave',
    days: [
      { title: 'Remaining', value: 6, color: '#368011', hoveredColor: '#2c6a0e' },
      { title: 'Passing', value: 2, color: '#20c997', hoveredColor: '#199d76' },
    ],
  },
  {
    name: 'Other Leave',
    days: [
      { title: 'Remaining', value: 15, color: '#3c1991', hoveredColor: '#2a1166' },
      { title: 'Passing', value: 2, color: '#fadb14', hoveredColor: '#d6ba05' },
    ],
  },
]

// eslint-disable-next-line react/prop-types
function FullOption(props) {
  const [hovered, setHovered] = useState(undefined)

  // eslint-disable-next-line react/prop-types
  const data = props.data.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: entry.hoveredColor,
      }
    }
    return entry
  })

  const lineWidth = 55

  return (
    <Widget
      title={
        <h2 className="h4 gx-text-capitalize gx-mb-0">
          {/*eslint-disable-next-line react/prop-types */}
          {props.title}
        </h2>
      }
      styleName="gx-card-full gx-text-center gx-pt-4 gx-pb-3 gx-px-3"
    >
      <PieChart
        style={{ fontSize: '8px' }}
        data={data}
        radius={PieChart.defaultProps.radius - 6}
        lineWidth={lineWidth}
        lengthAngle={180}
        startAngle={180}
        animate
        label={({ dataEntry }) => dataEntry.value}
        labelPosition={100 - lineWidth / 2}
        labelStyle={{
          fill: '#fff',
          opacity: 0.75,
          pointerEvents: 'none',
        }}
        onMouseOver={(_, index) => {
          setHovered(index)
        }}
        onMouseOut={() => {
          setHovered(undefined)
        }}
      />
    </Widget>
  )
}

// export default FullOption;
const ProfileTab = ({ data, employeeGroups }) => {
  const { address } = data
  return (
    <Row>
      <Col xs={24}>
        <Widget title="Leave status">
          <Row>
            {leaveData.map((item) => (
              <Col span={8} key={item.name}>
                <FullOption title={item.name} data={item.days} />
              </Col>
            ))}
          </Row>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Attendance Information">
          <Descriptions column={1} bordered size="small" labelStyle={{ width: 180 }}>
            <Descriptions.Item label={moment().weekday(6).format('dddd, MMMM Do YYYY')}>
              <CloseOutlined />
            </Descriptions.Item>
            <Descriptions.Item label={moment().weekday(5).format('dddd, MMMM Do YYYY')}>
              <CloseOutlined />
            </Descriptions.Item>
            <Descriptions.Item label={moment().weekday(4).format('dddd, MMMM Do YYYY')}>
              <CheckOutlined />
            </Descriptions.Item>
            <Descriptions.Item label={moment().weekday(3).format('dddd, MMMM Do YYYY')}>
              <CloseOutlined />
            </Descriptions.Item>
            <Descriptions.Item label={moment().weekday(2).format('dddd, MMMM Do YYYY')}>
              <CheckOutlined />
            </Descriptions.Item>
            <Descriptions.Item label={moment().weekday(1).format('dddd, MMMM Do YYYY')}>
              <CheckOutlined />
            </Descriptions.Item>
            <Descriptions.Item label={moment().weekday(0).format('dddd, MMMM Do YYYY')}>
              <CheckOutlined />
            </Descriptions.Item>
          </Descriptions>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Groups Information">
          <Row>
            {employeeGroups.map((item, index) => (
              <Col key={index} xl={12} lg={12} md={12} sm={12} xs={24}>
                <GroupCard
                  name={item.name}
                  group={item.group.name}
                  bgColor={BG_COLOR_LIST[getRandomInt(BG_COLOR_LIST.length)]}
                  handleDelete={() => null}
                />
              </Col>
            ))}
          </Row>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Address Information">
          <Descriptions column={1} bordered size="small" labelStyle={{ width: 180 }}>
            <Descriptions.Item label="Present">{address?.present}</Descriptions.Item>
            <Descriptions.Item label="Permanent">{address?.permanent}</Descriptions.Item>
          </Descriptions>
        </Widget>
      </Col>
    </Row>
  )
}

ProfileTab.defaultProps = {
  data: {
    general: {},
    address: {},
  },
  employeeGroups: [],
}

ProfileTab.propTypes = {
  data: PropTypes.object,
  employeeGroups: PropTypes.array,
}

export default ProfileTab
