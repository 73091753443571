/* eslint-disable camelcase */
import React, { useEffect, useCallback, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, Col, Dropdown, Form, Menu, Radio, Row, Table, Tooltip } from 'antd'
import {
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'
//
import { fetchKPI, fetchStaticItem, addOrUpdateKPI, deleteKPI } from 'appRedux/actions'
import { PAGINATION_TYPES, EPR_KPI_STATUS_BG, DATE_FORMAT, getDateFormat } from 'util/constants'
import { sortColumnObject, showDeleteConfirm } from 'util/helpers'
import FormModal from 'components/KPIFormModal'
import EmployeeCell from 'components/EmployeeCell'

import Widget from 'components/Widget'
import Auxiliary from 'util/Auxiliary'
import { REST_API } from 'services/rest.api'
import { KpiService } from 'services'
import { PAGINATION_LIMIT } from '../../util/constants'
import FilterWidget from '../../components/FilterWidget/KPIAndEPR'

const initialSearch = {
  employee_id: '',
  type_id: '',
  status_id: '',
  level_id: '',
  grade_id: '',
  company_id: '',
  group_ids: [],
  from_range: [],
  to_range: [],
}

const KPIPage = () => {
  const KPIListType = localStorage.getItem('KPI_LIST_TYPE') ?? 'self'
  const dispatch = useDispatch()
  const { url } = useRouteMatch()
  const [form] = Form.useForm()
  const [list_type, setListType] = useState(KPIListType)
  const [search, setSearch] = useState(initialSearch)
  const [visible, setVisible] = useState(false)
  const [editItem, setEditItem] = useState(undefined)
  const [current, setCurrent] = useState(1)
  const [sort, setSort] = useState(JSON.parse(localStorage.getItem(`${url}/sort`)) || '')

  const handleInitial = useCallback(
    (_page, limit = PAGINATION_LIMIT) => {
      setCurrent(_page)
      const query = {
        list_type,
        page: _page,
        limit,
        ...(sort && { sort }),
        ...search,
      }
      dispatch(fetchKPI(query))
    },
    [dispatch, list_type, search, sort]
  )

  useEffect(() => {
    handleInitial(1)
  }, [handleInitial])

  useEffect(() => {
    if (sort) {
      localStorage.setItem(`${url}/sort`, JSON.stringify(sort))
    }
  }, [sort])

  useEffect(() => {
    dispatch(fetchStaticItem(REST_API.kpis_years, KpiService))
    dispatch(fetchStaticItem(REST_API.kpis_type, KpiService))
  }, [dispatch])

  const handleEdit = (item) => {
    const { type, year, from, to, ...rest } = item
    form.setFieldsValue({
      ...rest,
      type_id: type.id,
      year_id: year?.id,
      from: getDateFormat(from),
      to: getDateFormat(to),
    })
    setVisible(true)
    setEditItem(item)
  }

  const handleDelete = (item) => {
    showDeleteConfirm(<ExclamationCircleOutlined />, item.name, () => {
      return dispatch(deleteKPI(item.id))
    })
  }

  const { KPI, years, types, isLoading } = useSelector(({ common, pagination, loading }) => ({
    KPI: pagination[PAGINATION_TYPES.kpi],
    years: common[REST_API.kpis_years],
    types: common[REST_API.kpis_type],
    isLoading: loading[PAGINATION_TYPES.kpi],
  }))
  const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      width: 130,
      render: (_) => {
        // eslint-disable-next-line react/jsx-max-props-per-line
        return <Badge count={_?.name} style={{ backgroundColor: EPR_KPI_STATUS_BG[_?.id] }} />
      },
      ...sortColumnObject('status_id', setSort, sort),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (_) => _?.name,
      ...sortColumnObject('type_id', setSort, sort),
    },
    {
      title: 'KPI',
      dataIndex: 'name',
      render: (name, _) => (
        <Link to={`/kpi/${_?.id}`}>
          <div className="gx-link">{name}</div>
        </Link>
      ),
    },
    {
      title: 'Employee',
      dataIndex: 'employee',
      render: (employee) => {
        const { name, photo, eid } = employee ?? {}
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('employee_id', setSort, sort),
    },
    {
      title: 'Line manager',
      dataIndex: 'line_manager',
      render: (line_manager) => {
        const { name, photo, eid } = line_manager ?? {}
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('line_manager_id', setSort, sort),
    },
    {
      title: 'O&O',
      dataIndex: 'one_over_one',
      render: (one_over_one) => {
        const { name, photo, eid } = one_over_one ?? {}
        return <EmployeeCell {...{ name, photo: photo?.small, eid }} />
      },
      ...sortColumnObject('one_over_one_id', setSort, sort),
    },
    {
      title: 'Fiscal Year',
      dataIndex: 'from',
      render: (_, item) => {
        const date = `${moment(_).format('YYYY')}-${moment(item?.to).format('YYYY')}`
        return <div>{date}</div>
      },
      ...sortColumnObject('from', setSort, sort),
    },
    {
      title: 'Action',
      width: 100,
      key: 'action',
      // eslint-disable-next-line react/display-name
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              disabled={![1, 5].includes(record?.status?.id)}
              onClick={() => handleEdit(record)}
            >
              <EditOutlined />
              EDIT
            </Menu.Item>
            <Menu.Item disabled={record?.status?.id !== 1} onClick={() => handleDelete(record)}>
              <DeleteOutlined />
              DELETE
            </Menu.Item>
          </Menu>
        )
        return (
          <Dropdown
            overlay={menu}
            disabled={false}
            className="gx-pl-1"
            placement="bottomCenter"
            arrow
          >
            <ControlOutlined />
          </Dropdown>
        )
      },
    },
  ]
  const renderColumns = React.useMemo(() => {
    const IndexesToBeRemoved = [4, 7]
    if (list_type !== 'self') {
      while (IndexesToBeRemoved.length) {
        columns.splice(IndexesToBeRemoved.pop(), 1)
      }
    } else {
      columns.splice(3, 1)
    }
    return columns
  }, [columns, list_type])

  const pagination = {
    current,
    hideOnSinglePage: true,
    position: 'both',
    total: KPI.total,
    pageSizeOptions: [10, 20, 50],
    showQuickJumper: false,
    onChange: (page, pageSize) => handleInitial(page, pageSize),
  }

  const renderHeader = () => (
    <div className="gx-d-flex">
      <Tooltip title="Create KPI">
        <Button
          shape="circle"
          type="primary"
          onClick={() => {
            setEditItem(null)
            setVisible(true)
          }}
        >
          <span className="gx-fs-xxl">+</span>
        </Button>
      </Tooltip>
      <Radio.Group
        style={{ minWidth: '130px' }}
        options={[
          { label: 'Self', value: 'self' },
          { label: 'Team', value: 'team' },
        ]}
        onChange={(e) => {
          setListType(e?.target?.value)
          localStorage.setItem('KPI_LIST_TYPE', e?.target?.value)
        }}
        value={list_type}
        optionType="button"
        buttonStyle="solid"
      />
      <div className="gx-w-100">
        <FilterWidget
          employeeDisable={list_type === 'self'}
          clearSearch={() => setSearch(initialSearch)}
          onClick={(values) => setSearch(values)}
        />
      </div>
    </div>
  )
  const renderKPI = () => (
    <Row align="middle">
      <Col span={24}>
        <Table
          loading={isLoading}
          columns={renderColumns}
          dataSource={KPI.items}
          pagination={pagination}
          rowKey={(item) => item?.id}
        />
      </Col>
    </Row>
  )

  return (
    <Auxiliary>
      {renderHeader()}
      <Widget>{renderKPI()}</Widget>
      {visible && (
        <FormModal
          {...{
            visible,
            formName: 'kpi-create-form',
            handleCancel: () => setVisible(false),
            handleOk: (fieldsValue) => {
              const { from, to } = fieldsValue
              const values = {
                ...fieldsValue,
                from: from && fieldsValue.from.format(DATE_FORMAT),
                to: to && fieldsValue.to.format(DATE_FORMAT),
              }
              if (editItem) values.id = editItem.id
              return dispatch(addOrUpdateKPI(values))
            },
            title: !editItem ? 'Create New KPI' : 'Update KPI',
            form,
            types,
            years,
          }}
        />
      )}
    </Auxiliary>
  )
}

export default KPIPage
