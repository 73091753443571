import React from 'react'
import PropTypes from 'prop-types'
import { Col, Rate, Row, Table, Timeline } from 'antd'
//
import Widget from '../../Widget'
import { LANGUAGES_PROFICIENCY } from 'util/constants'

const Educations = ({ data }) => {
  const { educations, languages } = data
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Writing',
      dataIndex: 'writing',
      // eslint-disable-next-line react/display-name, react/prop-types
      render: ({ id }) => (
        <Rate
          style={{ fontSize: 'small' }}
          tooltips={LANGUAGES_PROFICIENCY}
          count={4}
          disabled
          defaultValue={5 - id}
        />
      ),
    },
    {
      title: 'Reading',
      dataIndex: 'reading',
      // eslint-disable-next-line react/display-name, react/prop-types
      render: ({ id }) => (
        <Rate
          style={{ fontSize: 'small' }}
          tooltips={LANGUAGES_PROFICIENCY}
          count={4}
          disabled
          defaultValue={5 - id}
        />
      ),
    },
    {
      title: 'Speaking',
      dataIndex: 'speaking',
      // eslint-disable-next-line react/display-name, react/prop-types
      render: ({ id }) => (
        <Rate
          style={{ fontSize: 'small' }}
          tooltips={LANGUAGES_PROFICIENCY}
          count={4}
          disabled
          defaultValue={5 - id}
        />
      ),
    },
  ]

  return (
    <Row>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Education Information">
          <Timeline>
            {educations
              .map((item, index) => (
                <Timeline.Item color="green" key={index}>
                  <div className="ant-row-flex ant-row-space-between">
                    <h2 className="h4 gx-mr-1 gx-mb-1">{item.institute}</h2>
                  </div>
                  <p className="gx-mb-1 gx-text-grey">{item.exam.name}</p>
                  <p className="gx-mb-1 gx-text-grey gx-fs-sm">{item.passing_year}</p>
                </Timeline.Item>
              ))
              .reverse()}
          </Timeline>
        </Widget>
      </Col>
      <Col xs={24} sm={12} md={12} lg={12} xl={12}>
        <Widget title="Languages Information">
          <Table
            className="gx-w-100"
            columns={columns}
            dataSource={languages}
            pagination={false}
            rowKey={(item) => item.id}
          />
        </Widget>
      </Col>
    </Row>
  )
}

Educations.defaultProps = {
  data: {
    educations: [],
  },
}

Educations.propTypes = {
  data: PropTypes.object,
}

export default Educations
