export const REST_API = {
  loginUri: 'login_check',
  discovery: 'discovery/employee',
  otpRequest: 'reset/password/otp/request',
  verifyToken: 'reset/password/otp/validate/',
  passwordReset: 'reset/password/otp',
  ADD_USER: 'users/employees',
  assets: 'employees/assets',
  pay_schedules: 'employees/pay-schedules',
  pay_schedule_type: 'employees/pay-schedules/types',
  pay_schedule_status: 'PAY-SCHEDULES_/statuses',
  PAY_SCHEDULES_TYPES_NATURES: 'PAY-SCHEDULES_/natures',
  loans: 'employees/loans',
  loan_status: 'LOAN_/statuses',
  LOANS_ROUTE: {
    LOANS_SUBMIT: '/submit',
    LOANS_APPROVE: '/approve',
  },
  salaries: 'salaries',
  salaries_status: 'LOAN_/statuses',
  SALARY_ROUTE: {
    SALARY_HOLD: '/employees/hold',
    SALARY_APPROVE: '/approve',
    SALARY_STATUS: 'SALARY_/statuses',
    SALARY_PROCESS_STATUS: 'SALARY-PROCESS_/process-statuses',
  },
  employees: 'employees',
  EMPLOYEES_ROUTE: {
    SHIFTS: 'EMPLOYEE_/shifts',
    LEVELS: 'EMPLOYEE_/levels',
    GRADES: 'EMPLOYEE_/grades',
    ENABLE: '/enable',
    DISABLE: '/disable',
    ACHIEVEMENTS: '/achievements',
    REFERENCES: '/references',
    CONTACTS: '/contacts',
    FILES: '/files',
    ADDRESS: '/address',
    EDUCATIONS: '/educations',
    FAMILY: '/family',
    FAMILY_MEMBER: '/family/members',
    GENERAL: '/general',
    SEPARATION: '/separation',
    HISTORIES: '/histories',
    LANGUAGES: '/languages',
    SKILLS: '/skills',
    TRAININGS: '/trainings',
    ASSIGN_COMPANY: '/assign/company',
    ASSIGN_OFFICE: '/assign/office',
    ASSIGN_GROUP: '/assign/group',
    REMOVE_GROUP: '/remove/group',
    ASSETS_ASSIGN: '/assets/assign',
    ASSETS_REMOVE: '/assets/remove',
    ASSIGN_SHIFT: '/shifts/assign',
    ASSIGN_SALARY_BASIC: '/salaries/assign/basic',
    ASSIGN_SALARY_TAX: '/salaries/assign/tax',
    // GET STATIC VALUE
    LANGUAGES_PROFICIENCY: 'EMPLOYEE_/languages/proficiencies',
    SEPARATION_TYPES: 'EMPLOYEE_/separation/types',
    SEPARATION_STATUSES: 'EMPLOYEE_/separation/statuses',
    SEPARATION_MODES: 'EMPLOYEE_/separation/modes',
    GENDERS: 'EMPLOYEE_/general/genders',
    RELIGIONS: 'EMPLOYEE_/general/religions',
    EDUCATION_TYPES: 'EMPLOYEE_/educations/types',
    EDUCATION_DURATION_UNITS: 'EMPLOYEE_/educations/duration-units',
    BLOOD_GROUPS: 'EMPLOYEE_/blood-groups',
    MARITAL_STATUSES: 'EMPLOYEE_/family/marital-statuses',
  },
  remote_work: 'remote-work',
  remote_work_type: 'REMOTE-WORK_/types',
  leave: 'leave',
  LEAVE: {
    TYPES: 'LEAVE_/types',
    DURATIONS: 'LEAVE_/durations',
  },
  kpis: 'kpis',
  kpis_years: 'KPIS_/years',
  kpis_type: 'KPIS_/types',
  kpis_status: 'KPIS_/statuses',
  KPI_ACTIONS: {
    KPI_SUBMIT: 'actions/submit',
    KPI_APPROVE: 'actions/approve',
    KPI_UPDATE_REQUEST: 'actions/update-requested',
    KPI_APPROVE_LM: 'actions/approve-line-manager',
  },
  objective: 'kpis/objectives',
  objective_perspectives: 'Objective_/perspectives',
  objective_kpi: 'kpis/objectives/kpis',
  target_units: 'Objective-KPI_/target-units',
  COMMENTS: '/comments',
  eprs: 'eprs',
  eprs_type: 'EPRS_/types',
  eprs_status: 'EPRS_/statuses',
  EPRS: {
    DEVELOPMENT_AREA: 'EPRS_/development-areas',
  },
  EPR_ACTIONS: {
    SECTION_A: '/sections/a',
    SECTION_A_OBJECTIVE: '/sections/a/objectives',
    SECTION_B: '/sections/b',
    SECTION_B_STABLE: '/sections/b/stables',
    SECTION_C: '/sections/c',
    SECTION_D: '/sections/d',
    SECTION_D_DEV_PLAN: section_d_id =>
      !section_d_id
        ? '/sections/d/dev-plan'
        : `/sections/d/${section_d_id}/dev-plan`,
  },
  groups: 'groups',
  GROUPS: {
    TREES: '/trees',
    TYPES: 'GROUPS_/types',
  },
  exams: 'employees/educations/exams',
  subjects: '/subjects',
  offices: 'offices',
  shifts: 'shifts',
  holidays: 'holidays',
  companies: 'companies',
  report: 'report',
  REPORT: {
    ATTENDANCE_INDIVIDUAL: 'REPORT_/attendance/individual',
    ATTENDANCE_SUMMARY: 'REPORT_/attendance/summary',
  },
  activity: 'employee/activities',
}
