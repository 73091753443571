import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Divider, Spin, Button, Tooltip, Select, Modal } from 'antd'
import { EnvironmentOutlined, UnorderedListOutlined, AimOutlined } from '@ant-design/icons'
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api'
import InfiniteScroll from 'react-infinite-scroll-component'

//
import Widget from '../../Widget'
import { getActivity } from '../../../appRedux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { PAGINATION_TYPES, viewDateFormat, viewTimeFormat } from 'util/constants'
import '../../../styles/pages/map.css'

const filterItems = [
  { label: 'This Week', kay: 'this_week', value: 'this_week' },
  { label: 'This Month', kay: 'this_month', value: 'this_month' },
  { label: 'Last Month', kay: 'last_month', value: 'last_month' },
  { label: 'Last Three Month', kay: 'last_three_month', value: 'last_three_month' },
]

const mapOption = {
  mapTypeControl: false,
  panControl: false,
  streetViewControl: false,
  zoomControl: false,
  fullscreenControl: false,
  keyboardShortcuts: false,
  MapTypeId: 'ROADMAP',
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'transit',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative',
      stylers: [{ visibility: 'off' }],
    },
  ],
}

const Header = ({ setActiveTab, activeTab, setFilter }) => {
  return (
    <Row style={{ marginTop: -33, marginRight: 0, marginLeft: 0 }} justify="end">
      <Col>
        <div className="gx-d-flex">
          <Tooltip title="List View">
            <Button
              style={{ backgroundColor: activeTab === 1 ? '#038FDD' : '#FFFFFF' }}
              icon={<UnorderedListOutlined style={{ color: activeTab === 1 ? '#FFFFFF' : '' }} />}
              onClick={() => setActiveTab(1)}
            />
          </Tooltip>
          <Tooltip title="Map View">
            <Button
              style={{ backgroundColor: activeTab === 2 ? '#038FDD' : '#FFFFFF' }}
              icon={<EnvironmentOutlined style={{ color: activeTab === 2 ? '#FFFFFF' : '' }} />}
              onClick={() => setActiveTab(2)}
            />
          </Tooltip>
          <Select
            style={{
              alignItems: 'center',
              marginBottom: 15,
            }}
            allowClear
            placeholder="Select month"
            options={filterItems}
            onSelect={(value) => {
              setFilter({ [value]: value })
            }}
          />
        </div>
      </Col>
    </Row>
  )
}
Header.defaultProps = {
  activeTab: undefined,
  setActiveTab: () => {},
  setFilter: () => {},
}

Header.propTypes = {
  setActiveTab: PropTypes.func,
  setFilter: PropTypes.func,
  activeTab: PropTypes.bool,
}

const renderItem = ({ item, isInfoWindow, modal }) => {
  const { category, type, description, address, created } = item
  const stringLength = isInfoWindow ? 80 : 150
  return (
    <>
      <Row align="center" justify="center">
        <Col style={{ fontSize: 18 }} xs={24}>
          <span>{category?.name}</span>
          <span style={{ marginRight: 20, marginLeft: 20 }}>|</span>
          <span>{type?.name}</span>
        </Col>
      </Row>
      {/* Date and Time */}
      <Row align="center" justify="center">
        <Col style={{ fontSize: 14, color: '#6E6B7BB2', marginTop: 5, marginBottom: 5 }} xs={24}>
          <span>{viewDateFormat(created)}</span>
          <span style={{ marginRight: 15, marginLeft: 15 }}>|</span>
          <span>{viewTimeFormat(created)}</span>
        </Col>
      </Row>
      {/* Description */}
      <Row>
        <Col style={{ fontSize: 14, color: '#6E6B7BE5' }} xs={24}>
          {modal ? (
            <span>{description}</span>
          ) : (
            <span>
              {description.length <= stringLength
                ? description?.replace(/\n+/g, '\n')
                : description?.replace(/\n+/g, '\n')?.substr(0, stringLength) + '...'}
            </span>
          )}
        </Col>
      </Row>
      <Divider></Divider>
      {/* Location icon and address */}
      <Row>
        <Col
          style={{ fontSize: 14, color: '#6E6B7BB2', display: 'flex', flexDirection: 'row' }}
          xs={24}
        >
          <div>
            <span style={{ marginRight: 5 }}>
              <EnvironmentOutlined />
            </span>
          </div>
          <div>
            <span>{address}</span>
          </div>
        </Col>
      </Row>
    </>
  )
}

const Activity = ({ employeeId }) => {
  const dispatch = useDispatch()
  const [filterItem, setFilter] = React.useState({})
  const [activeTab, setActiveTab] = React.useState(1)
  const [activeMarker, setActiveMarker] = React.useState(null)
  const [modalId, setModalId] = React.useState(undefined)
  const [mapRef, setMapRef] = React.useState(undefined)

  const { isLoading, activity } = useSelector(({ pagination, loading }) => ({
    activity: pagination[PAGINATION_TYPES.activity],
    isLoading: loading[PAGINATION_TYPES.activity],
  }))

  const getActivityList = React.useCallback(
    (_page = 1) => {
      const query = {
        page: _page,
        limit: activeTab === 1 ? 10 : 100,
        employee_id: employeeId,
        ...filterItem,
      }
      if (employeeId) {
        dispatch(getActivity(query))
      }
    },
    [dispatch, employeeId, filterItem, activeTab]
  )
  React.useEffect(() => {
    getActivityList()
  }, [getActivityList])

  const loadMore = () => {
    if (activity?.pagination?.self >= activity?.pagination?.last) {
      return
    }
    getActivityList(activity?.pagination?.next)
  }

  React.useEffect(() => {
    if (mapRef && activeTab === 2) {
      const bounds = new window.google.maps.LatLngBounds()
      const coordinates = activity?.items?.map(({ latitude, longitude }) => ({
        position: {
          lat: parseFloat(latitude),
          lng: parseFloat(longitude),
        },
      }))
      coordinates.forEach(({ position }) => bounds.extend(position))
      if (activity?.items?.length === 1) {
        const zoomListener = new window.google.maps.event.addListener(
          mapRef,
          'bounds_changed',
          function (event) {
            this.setZoom(15)
            new window.google.maps.event.removeListener(zoomListener)
          }
        )
      }
      if (activity?.items?.length === 0) {
        const zoomListener = new window.google.maps.event.addListener(
          mapRef,
          'bounds_changed',
          function (event) {
            this.setZoom(2)
            new window.google.maps.event.removeListener(zoomListener)
          }
        )
      }
      mapRef.fitBounds(bounds)
    }
  }, [activity, activeTab, mapRef])

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return
    }
    setActiveMarker(marker)
  }

  const modalView = (item) => (
    <Modal
      visible={modalId === item.id && item.description.length > 150}
      onCancel={() => {
        setModalId(undefined)
      }}
      footer={null}
      centered
    >
      {renderItem({ item, modal: true })}
    </Modal>
  )

  const ListView = () => (
    <InfiniteScroll
      dataLength={activity?.items?.length}
      next={loadMore}
      style={{ overflow: 'hidden' }}
      hasMore={activity?.items?.length !== activity?.total}
      scrollableTarget="scrollableDiv"
    >
      <Row>
        {activity?.items?.map((item) => {
          return (
            <Col key={item.id} xs={24} sm={24} md={12} lg={12} xl={12}>
              <a
                onClick={() => {
                  setModalId(item.id)
                }}
              >
                <Widget>{renderItem({ item })}</Widget>
              </a>
              {modalView(item)}
            </Col>
          )
        })}
      </Row>
    </InfiniteScroll>
  )

  // navigator.geolocation.getCurrentPosition((position) => {
  // 	setUserLocation({
  // 		lat: position.coords.latitude,
  // 		lng: position.coords.longitude,
  // 	})
  // });

  return (
    <>
      <Header setFilter={setFilter} activeTab={activeTab} setActiveTab={setActiveTab} />
      <Spin spinning={isLoading}>
        {activeTab === 1 ? (
          <div id="scrollableDiv" style={{ height: '35vh', overflow: 'auto' }}>
            <ListView />
          </div>
        ) : (
          <div>
            <div style={{ position: 'absolute', right: 20, top: 20, zIndex: 1 }}>
              <Button
                icon={<AimOutlined />}
                onClick={() => {
                  getActivityList(activity?.pagination?.self)
                }}
              />
            </div>
            <GoogleMap
              mapContainerStyle={{ height: '60vh', width: '100%' }}
              onLoad={(map) => {
                setMapRef(map)
              }}
              onClick={() => setActiveMarker(null)}
              options={mapOption}
            >
              {activity?.items?.map((item) => {
                const { latitude, longitude, id } = item
                return (
                  <Marker
                    id={id}
                    position={{
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    }}
                    onClick={() => handleActiveMarker(id)}
                    icon={require('./../../../assets/images/icons/Group1000002708.svg')}
                  >
                    {activeMarker === id ? (
                      <InfoWindow
                        onCloseClick={() => setActiveMarker(null)}
                        options={{
                          minWidth: 334,
                          maxWidth: 334,
                        }}
                      >
                        <div style={{ overflow: 'hidden' }}>
                          {renderItem({ item, isInfoWindow: true })}
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                )
              })}
            </GoogleMap>
          </div>
        )}
      </Spin>
    </>
  )
}

Activity.defaultProps = {
  employeeId: undefined,
}

Activity.propTypes = {
  employeeId: PropTypes.number,
}
export default Activity
