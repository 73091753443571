export const FILTER_LIST = {
  Eid: {
    name: 'eid',
    label: 'Eid',
    value: 'eid',
    placeholder: 'Employee ID',
  },
  Status: {
    name: 'status',
    label: 'Status',
    value: '1',
    placeholder: 'Select status',
  },
  'LM Team': {
    name: 'parent_id',
    label: 'LM Team',
    placeholder: 'LM Team',
  },
  'Blood Group': {
    name: 'blood_group_id',
    label: 'Blood Group',
    value: '1',
    placeholder: 'Select Blood Group',
  },
  Gender: {
    name: 'gender_id',
    label: 'Gender',
    value: '1',
    placeholder: 'Select gender',
  },
  'Joining date': {
    name: 'joining_date',
    label: 'Joining date',
    value: '1',
    placeholder: 'Select date',
  },
  'Confirmation date': {
    name: 'confirmation_date',
    label: 'Confirmation date',
    value: '1',
    placeholder: 'Select date',
  },
  'Service length': {
    name: 'service_length',
    label: 'Service length',
    value: '1',
    placeholder: 'Select service length',
  },
  Age: {
    name: 'age',
    label: 'Age',
    value: '1',
    placeholder: 'Select age',
  },
  'Marital status': {
    name: 'marital_status_id',
    label: 'Marital status',
    value: '1',
    placeholder: 'Select marital status',
  },
  Religion: {
    name: 'religion_id',
    label: 'Religion',
    value: '1',
    placeholder: 'Select religion',
  },
  Birthday: {
    name: 'birthday',
    label: 'Birthday',
    value: '1',
    placeholder: 'Select date',
  },
  'Education type': {
    name: 'education_type_id',
    label: 'Education type',
    value: '1',
    placeholder: 'Select education type',
  },
  Exam: {
    name: 'exam_id',
    label: 'Exam',
    value: '1',
    placeholder: 'Select exam',
  },
  Separation: {
    name: 'separation_id',
    label: 'Separation',
    value: '1',
    placeholder: 'Select separation',
  },
  // --------------------------------- DYNAMIC GROUPS -----------------
  // {
  //   name: 'group_id',
  //   label: 'Group',
  //   value: '1',
  //   placeholder: 'Select group'
  // },
  // {
  //   name: '_id',
  //   label: '',
  //   value: '1',
  //   placeholder: 'Select '
  // }
}

export const FILTER_LIST_EPR_KPI = {
  // Company: {
  //   name: 'company_id',
  //   label: 'Company',
  //   value: '1',
  //   placeholder: 'Select company',
  // },
  'KPI Type': {
    name: 'type_id',
    label: 'KPI Type',
    value: '1',
    placeholder: 'Select kpi type',
  },
  'EPR Type': {
    name: 'type_id',
    label: 'EPR Type',
    value: '1',
    placeholder: 'Select epr type',
  },
  'KPI Status': {
    name: 'status_id',
    label: 'KPI Status',
    value: '1',
    placeholder: 'Select kpi status',
  },
  'EPR Status': {
    name: 'status_id',
    label: 'EPR Status',
    value: '1',
    placeholder: 'Select epr status',
  },
  'From date': {
    name: 'from_date',
    label: 'From date',
    value: '1',
    placeholder: 'Select date',
  },
  'To date': {
    name: 'to_date',
    label: 'To date',
    value: '1',
    placeholder: 'Select date',
  },
  // Level: {
  //   name: 'level_id',
  //   label: 'Level',
  //   value: '1',
  //   placeholder: 'Select level',
  // },
  // Grade: {
  //   name: 'grade_id',
  //   label: 'Grade',
  //   value: '1',
  //   placeholder: 'Select grade',
  // },
}

export const FILTER_LIST_ATTENDANCE = {
  'Company Office': {
    name: 'office_id',
    label: 'Company Office',
    value: '1',
    placeholder: 'Select office',
  },
  Shift: {
    name: 'company_id',
    label: 'Shift',
    value: '1',
    placeholder: 'Select shift',
  },
  Date: {
    name: '_date',
    label: 'From date',
    value: '1',
    placeholder: 'Select date',
  },
}

export const FILTER_LIST_PAY_SCHEDULE = {
  Company: {
    name: 'company_id',
    label: 'Company',
    value: '1',
    placeholder: 'Select company',
  },
  Status: {
    name: 'status_id',
    label: 'Status',
    value: '1',
    placeholder: 'Select status',
  },
  Type: {
    name: 'type_id',
    label: 'Type',
    value: '1',
    placeholder: 'Select Type',
  },
  Date: {
    name: '_date',
    label: 'Date',
    value: '1',
    placeholder: 'Select date',
  },
  Level: {
    name: 'level_id',
    label: 'Level',
    value: '1',
    placeholder: 'Select level',
  },
  // 'Grade': {
  //   name: 'grade_id',
  //   label: 'Grade',
  //   value: '1',
  //   placeholder: 'Select grade'
  // },
}

export const FILTER_LIST_LOAN = {
  Status: {
    name: 'status_id',
    label: 'Status',
    value: '1',
    placeholder: 'Select status',
  },
  Date: {
    name: 'start_date',
    label: 'Date',
    value: '1',
    placeholder: 'Select date',
  },
  Amount: {
    name: 'amount',
    label: 'Amount',
    value: '1',
    placeholder: 'Input amount',
  },
  'Interest percent': {
    name: 'interest_percent',
    label: 'Interest percent',
    value: '1',
    placeholder: 'Input interest percent',
  },
  Interest: {
    name: 'interest',
    label: 'Interest',
    value: '1',
    placeholder: 'Input interest',
  },
  tenure: {
    name: 'tenure',
    label: 'Tenure',
    value: '1',
    placeholder: 'Input tenure',
  },
  Interval: {
    name: 'interval',
    label: 'Interval',
    value: '1',
    placeholder: 'Input interval',
  },
}
