import React from 'react'
import PropTypes from 'prop-types'
import { Col, Rate, Row } from 'antd'

const ContactCell = ({ contact }) => {
  const { name, email, mobile, relation, emergency, grantor } = contact
  return (
    <Row className="gx-contact-item">
      <Col flex="auto">
        <div className="gx-module-contact-content">
          <p className="gx-mb-1">
            <span className="gx-text-truncate gx-contact-name"> {name} </span>
            <span className="gx-toolbar-separator">&nbsp;</span>
            <span className="gx-text-truncate gx-job-title">{relation}</span>
            {(emergency || grantor) && (
              <>
                <span className="gx-toolbar-separator">&nbsp;</span>
                <span>
                  <Rate style={{ fontSize: 'small' }} count={1} defaultValue={1} />
                </span>
              </>
            )}
          </p>
          <div className="gx-text-muted">
            {email && <span className="gx-email gx-d-inline-block gx-mr-2">{email},</span>}
            <span className="gx-phone gx-d-inline-block">{mobile}</span>
          </div>
        </div>
      </Col>
    </Row>
  )
}

ContactCell.defaultProps = {
  contact: {},
}

ContactCell.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  contact: PropTypes.object,
}

export default ContactCell
