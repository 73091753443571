import React, { useEffect, useMemo, useState } from 'react'
import { Input, Spin } from 'antd'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { unionBy } from 'lodash'
//
import CustomScrollbars from 'util/CustomScrollbars'
import { selectPaginationItem } from 'appRedux/reducers/Pagination'
import { ObjectiveService, ObjectiveKpiService } from '../../services'
import { sendComment } from '../../appRedux/actions/KPI'
import { ALERT_TYPES, KEYS, PAGINATION_TYPES } from '../../util/constants'
import { db } from '../../services/Firebase'
import { showMessage } from '../../util/helpers'
import Conversation from './Conversation'

const Communication = ({ id, name, weight, type, objectiveID }) => {
  const { kpiId } = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [fbComment, setFBComment] = useState([])
  const [comment, setMessage] = useState('')
  const KPI = useSelector(selectPaginationItem({ key: PAGINATION_TYPES.kpi, id: kpiId }))

  const { FB_REF } = useSelector(({ common }) => ({
    FB_REF: common[KEYS.FIREBASE]?.ref ?? '',
  }))

  const comments = useMemo(() => {
    let item
    if (type !== 'objective') {
      // eslint-disable-next-line no-unused-expressions
      KPI?.objectives?.map((o) => {
        if (o.id === objectiveID) {
          item = o?.kpis?.filter((k) => k.id === id)[0]
          console.log(' 2 dbComments comment : ', item)
        }
      })
    } else {
      item = KPI?.objectives?.filter((o) => o.id === id)[0]
    }
    // const kpi = KPI?.objectives?
    const dbComment = item?.comments ?? []
    console.log(' 1 dbComments comment : ', dbComment)
    return unionBy(dbComment, fbComment, 'id')
  }, [KPI, fbComment])
  const submitComment = async () => {
    try {
      if (!comment.trim()) {
        showMessage(ALERT_TYPES.WARN, 'Please write your comment!')
        return
      }
      setLoading(true)
      const serviceName = type === 'objective' ? ObjectiveService : ObjectiveKpiService
      const res = await dispatch(sendComment(serviceName, id, comment, kpiId))
      if (res?.comments) {
        setMessage('')
      }
    } catch (error) {
      console.error('ERROR***=====>', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const unSubscribe = db?.ref(FB_REF)?.on('value', (snapshot) => {
      if (snapshot?.val()) {
        const {
          object: { data, event },
        } = snapshot.val() || { data: {}, event: '' }
        // console.log('1 FB comment: ', snapshot.val());

        const { comment, employee, id: FB_COMMENT_ID, objective: FB_OBJECTIVE } = data || {}
        // console.log('PRINT IN %s=====>','Chat START ***', id);
        // console.log('PRINT IN %s=====>','isObjective', id === FB_OBJECTIVE.id);
        // console.log('PRINT IN %s=====>','Chat END ***', FB_OBJECTIVE.id);
        // if (id && type ===  && event === FB_EVENTS.OB_COMMENT) {
        //   const FB_OBJECTIVE = FB_KRA?.objectives?.filter(
        //     o => o.id === parseInt(objective_id, 10),
        //   )[0];
        //   if (objective_id === FB_OBJECTIVE?.id) {
        //     setFBComment(list => [
        //       {employee, id, comment: FB_COMMENT},
        //       ...list,
        //     ]);
        //   }
        // } else
        // if (id === FB_OBJECTIVE.id && event === FB_EVENTS.OB_COMMENT) {
        //   console.log('2 FB comment: ', data)
        //   setFBComment((list) => [{ employee, id: FB_COMMENT_ID, comment }, ...list])
        // }
      }
    })
    // Stop listening for updates when no longer required
    return () => db?.ref(FB_REF)?.off('value', unSubscribe)
  }, [FB_REF, kpiId])
  // }, [FB_REF, dbComment, kra.id, objective_id]);
  // console.log('fb comment : ', fbComment);

  return (
    <div className="gx-chat-main">
      <div className="gx-chat-main-header">
        <div className="gx-chat-main-header-info">
          <div className="gx-chat-avatar gx-mr-2">
            <div className="gx-status-pos">
              <span className="gx-fs-lg">{name}</span>
              <span className="gx-fs-xl"> ({weight})</span>
            </div>
          </div>
        </div>
      </div>

      <CustomScrollbars className="gx-chat-list-scroll">
        <Conversation
          conversationData={comments}
          // conversationData={CON.conversationData}
        />
      </CustomScrollbars>

      <div className="gx-chat-main-footer">
        <Spin spinning={isLoading}>
          <div className="gx-flex-row gx-align-items-center" style={{ maxHeight: 51 }}>
            <div className="gx-col">
              <Input.TextArea
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
                value={comment}
                placeholder="Type and hit icon to send message"
              />
            </div>
            <i
              className="gx-icon-btn icon icon-sent"
              style={{ fontSize: '18px', color: '#060' }}
              onClick={() => {
                submitComment()
              }}
            />
          </div>
        </Spin>
      </div>
    </div>
  )
}

const Chat = ({ id, name, weight, type, objectiveID }) => {
  const showCommunication = () => (
    <div className="gx-chat-box">
      <Communication {...{ id, name, weight, type, objectiveID }} />
    </div>
  )
  return (
    <div className="gx-main-content gx-p-5">
      <div className="gx-app-module gx-chat-module">
        <div className="gx-chat-module-box">{showCommunication()}</div>
      </div>
    </div>
  )
}

export default Chat
