import React from 'react'
import UserProfile from 'routes/Profile'
import Team from 'routes/Team'
import KPI from 'routes/KPI'
import KPIDetails from 'routes/KPI/Details'
import EPR from 'routes/EPR'
import EPRDetails from 'routes/EPR/Details'
import RemoteWork from 'routes/RemoteWork'
import Leave from 'routes/Leave'
import Attendance from 'routes/Attendance'
// import DEFAULT_PAGE from 'routes/EX-DEFAULT'
import KPIIcon from '../assets/images/icons/KPI.svg'
import EPRIcon from '../assets/images/icons/EPR.svg'
import LeaveIcon from '../assets/images/icons/Leave.svg'
import RemoteWorkIcon from '../assets/images/icons/RemoteWork.svg'
import AttendanceIcon from '../assets/images/icons/Attendance.svg'
import { history } from '../appRedux/store'

const Impersonate = () => {
  React.useEffect(() => {
    history.push('/profile')
  }, [])
  return null
}

export default [
  {
    path: '/impersonate',
    title: 'KPI-Details',
    isVisibleInSidebar: false,
    component: Impersonate,
  },
  {
    path: '/profile',
    title: 'Profile',
    icon: 'icon-profile',
    isVisibleInSidebar: true,
    component: UserProfile,
  },
  {
    path: '/team',
    title: 'Team',
    icon: 'icon-team',
    isVisibleInSidebar: true,
    component: Team,
  },
  {
    path: '/profile/:profileId',
    title: 'Profile',
    icon: 'icon-profile',
    isVisibleInSidebar: false,
    component: UserProfile,
  },
  {
    path: '/kpi',
    title: 'KPI',
    icon: KPIIcon,
    isVisibleInSidebar: true,
    component: KPI,
  },
  {
    path: '/kpi/:kpiId',
    title: 'KPI-Details',
    icon: EPRIcon,
    isVisibleInSidebar: false,
    component: KPIDetails,
  },
  {
    path: '/epr',
    title: 'EPR',
    icon: EPRIcon,
    isVisibleInSidebar: true,
    component: EPR,
  },
  {
    path: '/epr/:eprId',
    title: 'EPR-Details',
    icon: 'icon-',
    isVisibleInSidebar: false,
    component: EPRDetails,
  },
  {
    path: '/leave',
    title: 'Leave',
    icon: LeaveIcon,
    isVisibleInSidebar: true,
    component: Leave,
  },
  {
    path: '/remote-work',
    title: 'Remote work',
    icon: RemoteWorkIcon,
    isVisibleInSidebar: true,
    component: RemoteWork,
  },
  {
    path: '/attendance',
    title: 'Attendance',
    icon: AttendanceIcon,
    isVisibleInSidebar: true,
    component: Attendance,
  },
]
