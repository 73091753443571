import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Tag } from 'antd'
//
import Widget from '../../Widget'

const Document = ({ data }) => {
  const { files } = data
  return (
    <>
      <Row>
        {files.map((item, index) => {
          return (
            <Col key={index} xs={12} sm={12} md={6} lg={6} xl={6}>
              <Widget styleName="gx-card-full">
                <div className="gx-featured-thumb gx-mr-0 gx-mb-0">
                  <img
                    className="gx-rounded-lg gx-w-100"
                    src={item.file && item.file.medium}
                    alt={item.name}
                  />
                  <Tag className="gx-rounded-xs gx-text-uppercase" color="#06BB8A">
                    {item.name}
                  </Tag>
                </div>
              </Widget>
            </Col>
          )
        })}
      </Row>
    </>
  )
}

Document.defaultProps = {
  data: {
    files: [],
  },
}

Document.propTypes = {
  data: PropTypes.object,
}
export default Document
