import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Form, Input, DatePicker, Select, InputNumber } from 'antd'
//
// import { FORM_ITEM_LAYOUT } from 'util/constants';
const dateFormat = 'YYYY'
const { Option } = Select
const FORM_ITEM_LAYOUT = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
}
const EmployeeEducationForm = ({ exams, educationTypes, educationDurationUnits, examId }) => {
  const [newExamId, setExamid] = useState(examId)
  const subjects = useMemo(() => {
    const { subjects } = exams.filter((item) => item.id === newExamId)[0] || {}
    return subjects || []
  }, [exams, newExamId])
  return (
    <>
      <Row justify="start" align="stretch">
        <Col xs={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="institute"
            label="Institute"
            rules={[
              {
                required: true,
                message: 'Please input your institute name',
              },
              {
                max: 100,
                min: 4,
                message: 'Name must have a length between 4 and 100',
              },
            ]}
          >
            <Input allowClear placeholder="Please input your institute name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="exam_id"
            label="Exam Name"
            rules={[
              {
                required: true,
                message: 'Please select exam name',
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="Select one"
              onChange={(v) => setExamid(v)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {exams.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="type_id"
            label="Education Type"
            rules={[
              {
                required: true,
                message: 'Please select education type',
              },
            ]}
          >
            <Select allowClear placeholder="Select one">
              {educationTypes.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="passing_year"
            label="Passing Year"
            rules={[
              {
                required: true,
                message: 'Please select year',
              },
            ]}
          >
            <DatePicker className="gx-w-100" allowClear format={dateFormat} picker="year" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="duration"
            label="Duration"
            rules={[
              {
                required: true,
                message: 'Please input duration',
              },
            ]}
          >
            <InputNumber
              min={1}
              allowClear
              placeholder="Please input duration"
              className="gx-w-100"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="duration_unit_id"
            label="Duration Type"
            rules={[
              {
                required: true,
                message: 'Please select duration type',
              },
            ]}
          >
            <Select allowClear placeholder="Select one">
              {educationDurationUnits.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="subject_id"
            label="Subject/Group"
            rules={[
              {
                required: true,
                message: 'Please select subject/group',
              },
            ]}
          >
            <Select allowClear placeholder="Select one">
              {subjects.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="certificate_no"
            label="Certificate No"
            rules={[
              {
                required: true,
                message: 'Please input certificate no.',
              },
            ]}
          >
            <Input allowClear placeholder="Please input certificate no." />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            {...FORM_ITEM_LAYOUT}
            name="result"
            label="Result"
            rules={[
              {
                required: true,
                message: 'Please input result',
              },
            ]}
          >
            <Input allowClear placeholder="Please input result" />
          </Form.Item>
        </Col>
        <Col xs={12}>
          <Form.Item {...FORM_ITEM_LAYOUT} name="description" label="Description">
            <Input.TextArea allowClear />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

EmployeeEducationForm.defaultProps = {
  exams: [],
  examId: 0,
  educationTypes: [],
  educationDurationUnits: [],
}

EmployeeEducationForm.propTypes = {
  exams: PropTypes.array,
  examId: PropTypes.number,
  onExamChange: PropTypes.func,
  educationTypes: PropTypes.array,
  educationDurationUnits: PropTypes.array,
}
export default EmployeeEducationForm
