/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { EprService } from 'services'
import { PAGINATION_TYPES } from 'util/constants'
import {
  pagePaginationSuccess,
  addPaginationItem,
  updatePaginationItem,
  deletePaginationItem,
  selectPaginationItem,
} from 'appRedux/reducers/Pagination'
import { setLoading } from 'appRedux/reducers/Loading'
import { showMessage } from '../../util/helpers'
import { ALERT_TYPES } from '../../util/constants'

const updateEPRSectionA = (section_a) => {
  const { objectives } = section_a || {}
  // console.log('---objectives---------->', objectives);
  objectives.map((element) => {
    const { objective_kpi } = element
    element.objective_id = objective_kpi.objective.id
    element.objective_name = objective_kpi.objective.name
    return element
  })
  const newObjects = objectives.reduce((r, a) => {
    r[a.objective_id] = r[a.objective_id] || []
    r[a.objective_id].push(a)
    return r
  }, Object.create(null))
  // console.log('---newObjects---------->', newObjects);
  const result = Object.keys(newObjects).map((v) => {
    const item = newObjects[v]
    // console.log('---item---------->', item);
    const { objective_id, objective_name } = item[0]
    let total_weight = 0
    item.forEach((element) => {
      total_weight += element.objective_kpi.weight
    })
    return {
      objective_id,
      objective_name,
      objective_kpis: item,
      total_weight,
    }
  })
  section_a.objectives = result
  return section_a
}

export const fetchEPR = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.epr
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EprService.find(query)
      dispatch(
        pagePaginationSuccess({
          key,
          data: { ...res, ...query },
        })
      )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'EPR fetch', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const fetchEPRById = (id) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.epr
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EprService.findById(id)
      const section_a = res?.section_a
      res.section_a = updateEPRSectionA(section_a)

      const epr = selectPaginationItem({ key, id })(getState())
      if (!epr) {
        dispatch(
          addPaginationItem({
            key,
            value: res,
          })
        )
      } else {
        dispatch(
          updatePaginationItem({
            id,
            key,
            value: { ...res },
          })
        )
      }
    } catch (e) {
      console.error('PRINT IN %s=====>', 'EPR get by id', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
export const updateEPRSection = (payload) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.epr
    const { id, route, section, ...rest } = payload
    let response
    // console.log('------------->', payload);
    const epr = selectPaginationItem({ key, id })(getState())
    const res = { ...epr }
    if (section === 'section_d' && rest.section_d_id) {
      const { section_d, ...devPlan } = await EprService.createSectionDDevPlan(route, rest)
      const { dev_plans } = res.section_d || []
      let prevDevPlans = [...dev_plans]
      const devPlanIndex = prevDevPlans.findIndex((item) => item.id === devPlan.id)
      if (devPlanIndex === -1) {
        prevDevPlans = [...prevDevPlans, devPlan]
      } else {
        prevDevPlans[devPlanIndex] = { ...devPlan }
      }
      section_d.dev_plans = prevDevPlans
      response = section_d
    } else {
      response = await EprService.patchAction(route, rest)
    }
    // console.log('--section_a----------->', response);
    switch (section) {
    case 'section_a':
      res.section_a = updateEPRSectionA(response)
      break
    case 'section_b':
      res.section_b = response
      break
    case 'section_c':
      res.section_c = response
      break
    case 'section_d':
      res.section_d = response
      break
    default:
      break
    }
    if(section === 'section_a') {
      dispatch(fetchEPRById(id))
    }
    // console.log('4------------->', res);
    dispatch(
      updatePaginationItem({
        id,
        key,
        value: { ...res },
      })
    )
  }
}
export const addOrUpdateEPR = (payload) => {
  return async (dispatch, getState) => {
    const { id, route, isSection, ...rest } = payload
    const key = PAGINATION_TYPES.epr
    const loadingObj = {
      key,
      value: true,
    }
    let res
    dispatch(setLoading(loadingObj))
    // console.log('------------->', payload);
    if (isSection && id) {
      const epr = selectPaginationItem({ key, id })(getState())
      res = { ...epr }
      const section_a = await EprService.patchAction(route, rest)
      // console.log('--section_a----------->', section_a);
      res.section_a = updateEPRSectionA(section_a)
      dispatch(
        updatePaginationItem({
          id,
          key,
          value: { ...res },
        })
      )
    } else if (!id) {
      res = await EprService.create(rest)
      res && dispatch(
              addPaginationItem({
                    key,
                    value: res,
                  })
                )
    } else {
      res = await EprService.patch(id, payload)
      res && dispatch(
        updatePaginationItem({
          id,
          key,
          value: { ...res },
        })
      )
    }

    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const deleteEPR = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.epr
    const loadingObj = {
      key,
      value: true,
    }
    dispatch(setLoading(loadingObj))
    if (id) {
      const res = await EprService.remove({ epr_id: id })
      if (res.success) {
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
      }
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
export const deleteSectionDDevPlane = (payload) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.epr
    const { id, dev_plan_id } = payload
    let res
    const response = await EprService.removeDevPlan({ dev_plan_id })
    if (response.success) {
      const epr = selectPaginationItem({ key, id })(getState())
      const { section_d } = epr
      res = {
        ...epr,
        section_d: {
          ...section_d,
          dev_plans: section_d.dev_plans.filter((item) => item.id !== parseInt(dev_plan_id, 10)),
        },
      }
      dispatch(
        updatePaginationItem({
          id,
          key,
          value: { ...res },
        })
      )
    }
    return response
  }
}

export const handleEPRAction = (route, payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.epr
    const loadingObj = {
      key,
      value: true,
    }
    // console.log('----->', route);
    dispatch(setLoading(loadingObj))
    let res
    try {
      res = await EprService.patchAction(route, payload)
      const section_a = res?.section_a
      res.section_a = updateEPRSectionA(section_a)
      dispatch(
        updatePaginationItem({
          id: payload.epr_id,
          key,
          value: { ...res },
        })
      )
    } catch (error) {
      console.error('error----->', error)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const eprPatchActions = (route, body) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.epr
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await EprService.patch(route, body)
      res && dispatch(fetchEPRById(body.epr_id))
      res && showMessage(ALERT_TYPES.SUCCESS, 'Status has been updated!')
    } catch (e) {
      console.error('PRINT IN %s=====>', 'epr patch actions', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const wait = (milleSecond = 1000) => {
  return new Promise((resolve) =>
    setTimeout(() => {
      resolve({ success: true })
    }, milleSecond)
  )
}
