import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Col, Descriptions, Row } from 'antd'
//
import Widget from '../../Widget'
import { DATE_FORMAT } from 'util/constants'

const Separations = ({ data }) => {
  const { resign_date, last_working_date, cert_issued, type, status, mode } = data.separation
  return (
    <Row>
      <Col span={24}>
        <Widget title="Separations Information">
          <Descriptions column={1} bordered size="small" labelStyle={{ width: 180 }}>
            <Descriptions.Item label="Resign Date">
              {resign_date && moment(resign_date).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label="Last Working Date">
              {last_working_date && moment(last_working_date).format(DATE_FORMAT)}
            </Descriptions.Item>
            <Descriptions.Item label="CERT">{cert_issued ? 'YES' : 'NO'}</Descriptions.Item>
            <Descriptions.Item label="Separation Type">{type && type.name}</Descriptions.Item>
            <Descriptions.Item label="Separation Status">{status && status.name}</Descriptions.Item>
            <Descriptions.Item label="Separation Mode">{mode && mode.name}</Descriptions.Item>
          </Descriptions>
        </Widget>
      </Col>
    </Row>
  )
}
Separations.defaultProps = {
  data: {
    separation: {},
  },
}
Separations.propTypes = {
  data: PropTypes.object,
}
export default Separations
