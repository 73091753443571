/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Badge, Col, Descriptions, Row, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
//
import Widget from 'components/Widget'
import { EPR_KPI_STATUS_BG } from '../../util/constants'

const bg_banner = require('assets/images/widget/profile_bg.png')

const { Title } = Typography
const ERPEmployeeBanner = ({ user, rightContent }) => {
  const { name, photo, eid } = user ?? {}
  return (
    <Widget styleName="gx-card-full">
      <div className="gx-slider-img">
        <img
          alt="banner"
          src={bg_banner}
          width="100%" style={{ maxHeight: 185 }}
        />
        <Avatar
          size={{ xs: 100, sm: 100, md: 72, lg: 74, xl: 110, xxl: 150 }}
          src={photo?.big}
          icon={<UserOutlined />}
          style={{
            position: 'absolute',
            bottom: '-40%',
            left: '2%',
            objectFit: 'cover',
            border: '4px solid #FFF',
            borderRadius: '100%',
          }}
        />
      </div>
      <Row>
        <Col span={3} />
        <Col span={6}>
          <Title level={4} className="gx-mb-1">
            {name}
          </Title>
          <div className="gx-jr-db-label"> ID : {eid}</div>
        </Col>
        <Col span={9}>
          <Descriptions column={1} labelStyle={{ width: 120 }} size="small">
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '120px',
                  }}
                >
                  EPR
                </span>
              }
            >
              <span className="gx-jr-db-label">{user?.epr_name}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '120px',
                  }}
                >
                  EPR Type
                </span>
              }
            >
              <span className="gx-jr-db-label">{user?.epr_type}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '120px',
                  }}
                >
                  Status
                </span>
              }
            >
              <Badge count={user?.epr_status} style={{ backgroundColor: EPR_KPI_STATUS_BG[user?.epr_status_id] }} />
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col>
          {rightContent}
        </Col>
      </Row>
    </Widget>
  )
}

ERPEmployeeBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
  rightContent: PropTypes.node,
}

ERPEmployeeBanner.defaultTypes = {
  user: {},
  rightContent: null,
}

export default ERPEmployeeBanner
