import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Divider,
  Form,
  Descriptions,
  Typography,
  Select,
  Avatar,
  Button,
  Switch,
} from 'antd'
import { useDispatch } from 'react-redux'
import { EditTwoTone, EditFilled, UserOutlined } from '@ant-design/icons'
//
import { addOrUpdateEmployee } from 'appRedux/actions/Pagination'
import { FORM_ITEM_LAYOUT } from 'util/constants'

const { Title } = Typography

const EMSelectNameForm = ({ data, name }) => {
  return (
    <Form.Item
      {...FORM_ITEM_LAYOUT}
      name={name}
      label="Name"
      rules={[
        {
          required: true,
          message: 'Name must not be empty',
        },
      ]}
    >
      <Select allowClear placeholder="Select one">
        {data.map((item, index) => (
          <Select.Option key={index} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
EMSelectNameForm.defaultProps = {
  data: [],
}

EMSelectNameForm.propTypes = {
  data: PropTypes.array,
  name: PropTypes.string,
}
const EmployeeInfo = ({
  editable,
  user,
  bloodGroups,
  offices,
  companies,
  handleAddOfficeOrCompany,
  handleEnabled,
  handleAddUser,
}) => {
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false)
  const [officeVisible, setOfficeVisible] = useState(false)
  const [companyVisible, setCompanyVisible] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [form] = Form.useForm()

  const handleOk = (value) => {
    value.id = user.id
    return dispatch(addOrUpdateEmployee(value))
  }
  const handleEnableSubmit = async () => {
    try {
      setSubmitting(true)
      await handleEnabled()
    } catch (error) {
      console.error('employee enable----->', error)
    } finally {
      setSubmitting(false)
    }
  }
  const handleAddUserSubmit = async () => {
    try {
      setSubmitting(true)
      await handleAddUser()
    } catch (error) {
      console.error('employee add user----->', error)
    } finally {
      setSubmitting(false)
    }
  }
  const { name, mobile, parent, email, eid, company, enabled, office, employee_user, EPR, KPI } = user || {}
  const { photo, blood_group, mobile: userMobile, ...rest } = user
  return (
    <Row>
      <Col xs={23} sm={11} md={11} lg={11} xl={11}>
        <Row justify="space-around" align="top">
          <Col
            xs={24}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Avatar
              size={{ xs: 120, sm: 120, md: 82, lg: 82, xl: 120, xxl: 150 }}
              src={photo && photo.big}
              icon={<UserOutlined />}
            />
          </Col>
          <Col xs={24} sm={14} md={14} lg={14} xl={14}>
            <Title level={4} className="gx-mb-0">
              {name}
            </Title>
            <Descriptions column={1}>
              <Descriptions.Item className="gx-pb-2" label={<b>Company</b>}>
                {company?.name}{' '}
                {editable && (
                  <EditFilled
                    onClick={() => {
                      setCompanyVisible(true)
                      form.setFieldsValue({
                        company_id: company?.id,
                      })
                    }}
                    twoToneColor="#616161"
                    style={{ fontSize: '1em', cursor: 'pointer' }}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item className="gx-pb-2" label={<b>Office</b>}>
                {office?.name}{' '}
                {editable && (
                  <EditFilled
                    onClick={() => {
                      setOfficeVisible(true)
                      form.setFieldsValue({
                        office_id: office?.id,
                      })
                    }}
                    twoToneColor="#616161"
                    style={{ fontSize: '1em', cursor: 'pointer' }}
                  />
                )}
              </Descriptions.Item>
              <Descriptions.Item className="gx-pb-2" label={<b>Employee ID</b>}>
                {eid}
              </Descriptions.Item>
              {EPR && (
                <Descriptions.Item className="gx-pb-2" label={<b>EPR</b>}>
                  {EPR.name} {EPR.description}
                </Descriptions.Item>
              )}
              {KPI && (
                <Descriptions.Item className="gx-pb-2" label={<b>KPI</b>}>
                  {KPI.name} {KPI.description}
                </Descriptions.Item>
              )}
            </Descriptions>
            {editable && (
              <div className="gx-flex-row gx-align-items-center gx-justify-content-start gn-items-start">
                <div className="gx-mr-3">
                  <Switch
                    checkedChildren="ENABLE"
                    unCheckedChildren="DISABLE"
                    checked={enabled}
                    loading={submitting}
                    onChange={() => handleEnableSubmit()}
                  />
                </div>
                <Button
                  type="ghost"
                  loading={submitting}
                  disabled={!!employee_user}
                  onClick={() => handleAddUserSubmit()}
                >
                  Add User
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Col>
      {editable && (
        <div style={{ position: 'absolute', right: '20px' }}>
          <EditTwoTone
            onClick={() => {
              setVisible(true)
              form.setFieldsValue({
                photo: photo?.big,
                blood_group_id: blood_group?.id,
                mobile: userMobile?.substring(mobile.length - 11),
                ...rest,
              })
            }}
            twoToneColor="#616161"
            style={{ fontSize: '1.2rem', cursor: 'pointer' }}
          />
        </div>
      )}
      <Col xs={0} sm={1} md={1} lg={1} xl={1}>
        <Divider type="vertical" dashed style={{ height: '160px' }} />
      </Col>
      <Col xs={24} sm={0} md={0} lg={0} xl={0}>
        <Divider type="horizontal" dashed />
      </Col>
      <Col xs={24} sm={11} md={11} lg={11} xl={11}>
        <Descriptions column={1}>
          <Descriptions.Item
            label={
              <span
                style={{
                  display: 'inline-block',
                  width: '150px',
                }}
              >
                <b>Mobile</b>
              </span>
            }
          >
            {mobile}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <span
                style={{
                  display: 'inline-block',
                  width: '150px',
                }}
              >
                <b>Email</b>
              </span>
            }
          >
            {email}
          </Descriptions.Item>
          <Descriptions.Item
            label={
              <span
                style={{
                  display: 'inline-block',
                  width: '150px',
                }}
              >
                <b>Blood Group</b>
              </span>
            }
          >
            {blood_group?.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={<span
              style={{
                display: 'inline-block',
                width: '150px'
              }}
            >
              <b>Line Manager</b>
            </span>}
          >
            {parent?.name}
          </Descriptions.Item>

        </Descriptions>
      </Col>
    </Row>
  )
}
EmployeeInfo.defaultProps = {
  user: {},
  editable: false,
  bloodGroups: [],
  offices: [],
}

EmployeeInfo.propTypes = {
  user: PropTypes.object.isRequired,
  editable: PropTypes.bool.isRequired,
  handleEnabled: PropTypes.func,
  handleAddUser: PropTypes.func,
  handleAddOfficeOrCompany: PropTypes.func,
  bloodGroups: PropTypes.array,
  offices: PropTypes.array,
  companies: PropTypes.array,
}

export default EmployeeInfo
