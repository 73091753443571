import React from 'react'
import { Avatar } from 'antd'

const SentMessageCell = ({ conversation }) => {
  return (
    <div className="gx-chat-item gx-flex-row-reverse">
      <Avatar
        className="gx-size-40 gx-align-self-end"
        src={conversation.employee.photo?.medium}
        alt={conversation.employee.name}
      />

      <div className="gx-bubble-block">
        <div className="gx-bubble">
          <div className="gx-message">{conversation.comment}</div>
          <div className="gx-time gx-text-muted gx-text-right gx-mt-2">{conversation.sentAt}</div>
        </div>
      </div>
    </div>
  )
}

export default SentMessageCell
