import request from 'util/request'
import { FETCH_TYPES } from 'util/constants'
import { ServiceBase } from './ServiceBase'
import { REST_API } from './rest.api'

export class EPR extends ServiceBase { 
  async createSectionDDevPlan(route, body) {
    const options = {method: FETCH_TYPES.POST, body};
    return await request(this.API_ENDPOINT + route, options);
  }
  async removeDevPlan(body) {
    const options = {method: FETCH_TYPES.DELETE, body};
    return await request(
      this.API_ENDPOINT + REST_API.EPR_ACTIONS.SECTION_D_DEV_PLAN(),
      options,
    );
  }
  async createItem(route, body) {
    const options = { method: FETCH_TYPES.POST, body }
    return await request(this.API_ENDPOINT + route, options)
  }
  async patchItem(id, route, body) {
    const options = { method: FETCH_TYPES.PATCH, body }
    return await request(this.API_ENDPOINT + route + '/' + id, options)
  }
  async removeItem(route, body) {
    const options = { method: FETCH_TYPES.DELETE, body }
    return await request(this.API_ENDPOINT + route, options)
  }
}
