/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Avatar, Col, Row, Spin } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { UserOutlined } from '@ant-design/icons'
import { EmployeeService } from 'services'
import { fetchStaticItem } from 'appRedux/actions'
//
import Widget from '../Widget'
const Loader = () => (
  <Row justify="center">
    <Col span={4}>
      <Spin />
    </Col>
  </Row>
)
const CardBody = ({ id, eid, name, email, photo, ...rest }) => {
  const parentId = rest?.employee?.parent?.id
  return (
    <Widget styleName="gx-card-full">
      {(id === parentId ? (
      <div className="gx-text-center gx-p-3 gx-pt-5">
          <div className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3">
            <Avatar className="gx-size-80" src={photo?.medium} icon={<UserOutlined />} />
          </div>
          <div className="gx-mb-0">
            <h4>{name}</h4>
            <p className="gx-text-grey">
              {eid}
              <br />
              {email}
            </p>
          </div>
      </div>
        ) : (
        <Link to={`/profile/${id}`}>
        <div className="gx-text-center gx-p-3 gx-pt-5">
          <div className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3">
            <Avatar className="gx-size-80" src={photo?.medium} icon={<UserOutlined />} />
          </div>
          <div className="gx-mb-0">
            <h4>{name}</h4>
            <p className="gx-text-grey">
              {eid}
              <br />
              {email}
            </p>
          </div>
        </div>
        </Link>
      ))}
    </Widget>
  )
}
const EmployeesCard = ({ data, pagination, handleLoadMore }) => {
  const dispatch = useDispatch()
  const res = localStorage.getItem('currentUser')
  const user = JSON.parse(res)
  const { employee_id: employeeId } = user || {}

  useEffect(() => {
    dispatch(fetchStaticItem(`EMPLOYEE_/${employeeId}`, EmployeeService))
  }, [employeeId, dispatch])

  const { employee } = useSelector(({ common }) => ({
    employee: common[`EMPLOYEE_/${employeeId}`],
  }))
  const { height } = useSelector(({ common }) => ({ height: common.height }))
  return (
    <div>
      <InfiniteScroll
        dataLength={data.length}
        next={handleLoadMore}
        hasMore={data.length !== pagination.total}
        height={height - 140}
      >
        {/* loader={<Loader />} */}
        <Row className="gx-mb-30">
          {data.map((item, index) => {
            return (
              <Col key={index} xs={12} sm={12} md={6} lg={6} xl={6} xxl={4}>
                <CardBody {...{ ...item }} employee={employee}/>
              </Col>
            )
          })}
        </Row>
      </InfiniteScroll>
    </div>
  )
}

CardBody.defaultProps = {
  id: 1,
  eid: '',
  name: '',
  email: '',
  photo: {},
}
CardBody.propTypes = {
  id: PropTypes.number,
  eid: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  photo: PropTypes.object,
}
EmployeesCard.propTypes = {
  data: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  handleLoadMore: PropTypes.func,
}

export default EmployeesCard
