/* eslint-disable camelcase */
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { Avatar, Button, Col, DatePicker, Row, Skeleton, Table } from 'antd'
import { UserOutlined } from '@ant-design/icons'
// import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
//
import {
  disabledDate,
  getInitialSearch,
  setSearchInLocalStorage,
} from 'util/helpers'
import { ReportService, EmployeeService } from 'services'
import { REST_API } from 'services/rest.api'
import { fetchIndividualAttendance, userSignOut, fetchStaticItem } from 'appRedux/actions'
import { PAGINATION_TYPES, DATE_FORMAT } from 'util/constants'
import Auxiliary from 'util/Auxiliary'
import Widget from 'components/Widget'
import { paginateObj, pagePaginationSuccess } from 'appRedux/reducers/Pagination'

const { RangePicker } = DatePicker
const dateFormat = 'YYYY-MM-DD'
const attendanceColumns = [
  {
    title: '#',
    dataIndex: '#',
    render: (_, __, index) => 1 + index,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    width: 120,
    align: 'center',
  },
  {
    title: 'Absent',
    dataIndex: 'absent',
    width: 100,
  },
  {
    title: 'Present',
    dataIndex: 'present',
    width: 100,
  },
  {
    title: 'Late',
    dataIndex: 'late',
    width: 100,
  },
  {
    title: 'Late time',
    dataIndex: 'late_in_minute',
    width: 100,
  },
  {
    title: 'Leave',
    dataIndex: 'leave',
    width: 100,
  },
  {
    title: 'Remote work',
    dataIndex: 'remote_work',
    width: 120,
  },
  {
    title: 'Punch in',
    dataIndex: 'punch_in',
    render: (_) => {
      const date = _?.split(' ')[1]
      return <div>{date?.slice(0, 5)}</div>
    },
  },
  {
    title: 'Punch out',
    dataIndex: 'punch_out',
    render: (_) => {
      const date = _?.split(' ')[1]
      return <div>{date?.slice(0, 5)}</div>
    },
  },
]
const Individual = () => {
  const dispatch = useDispatch()
  const componentRef = React.useRef()
  const { url: urll } = useRouteMatch()
  const [search, setSearch] = React.useState(getInitialSearch({
    from_date: '',
    to_date: '',
  }, urll))
  const res = localStorage.getItem('currentUser')
  const user = JSON.parse(res)
  const { employee_id: employeeId } = user || {}

  React.useEffect(() => {
    if (!employeeId) {
      dispatch(userSignOut())
    }
    dispatch(fetchStaticItem(`EMPLOYEE_/${employeeId}`, EmployeeService))
  }, [employeeId, dispatch])

  React.useEffect(() => {
    if (search) {
      setSearchInLocalStorage(search, urll)
    }
  }, [search, urll])

  const { employee } = useSelector(({ common }) => ({
    employee: common[`EMPLOYEE_/${employeeId}`],
  }))

  const { authUser, Attendance, isLoading } = useSelector(({ auth, pagination, common, loading }) => ({
    Attendance: pagination[PAGINATION_TYPES.attendance_individual],
    height: common.height,
    authUser: auth?.authUser?.user ?? {},
    isLoading: loading[PAGINATION_TYPES.attendance_individual],
  }))

  const handleInitial = React.useCallback(() => {
    const { employee_id } = authUser
    const { from_date, to_date } = search
    if (!employee_id || !from_date || !to_date) {
      dispatch(
        pagePaginationSuccess({
          data: { ...paginateObj },
          key: PAGINATION_TYPES.attendance_individual,
        })
      )
      return
    }

    dispatch(fetchIndividualAttendance({ ...search, employee_id, response_type: 'json' }))
  }, [dispatch, authUser, search])

  React.useEffect(() => {
    handleInitial()
  }, [handleInitial])

  const handleChange = (value = '', key) => {
    setSearch((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleDateChange = (dates) => {
    if (!dates?.length) {
      handleChange('', 'from_date')
      handleChange('', 'to_date')
      return
    }
    handleChange(dates[0].format('YYYY-MM-DD'), 'from_date')
    handleChange(dates[1].format('YYYY-MM-DD'), 'to_date')
  }

  const handleCsv = async () => {
    const { employee_id } = authUser
    const { from_date, to_date } = search
    if (!employee_id || !from_date || !to_date) return
    const csvRes = await ReportService.getItem(REST_API.REPORT.ATTENDANCE_INDIVIDUAL, {
      ...search,
      employee_id,
      response_type: 'csv',
    })
    const downloadLink = document.createElement('a')
    const blob = new Blob(['\ufeff', csvRes])
    const url = URL.createObjectURL(blob)
    downloadLink.href = url
    downloadLink.download = `Attendance_${employee.name}_${employee.eid}_${search?.from_date}_${search?.to_date}.csv`

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  const renderAttendance = () => (
    <Row align="middle">
      <Col span={6}>
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            'This Month': [moment().startOf('month'), moment()],
          }}
          disabledDate={disabledDate}
          placeholder={['Start date', 'End date']}
          format={DATE_FORMAT}
          onChange={(v) => handleDateChange(v)}
          defaultValue={search?.from_date && search?.to_date && [moment(search?.from_date, dateFormat), moment(search?.to_date, dateFormat)]}
        />
      </Col>
      <Col span={8}>
        {/* <Button
          type="primary"
          className="gx-mb-0"
          disabled={!Attendance?.items?.length}
          onClick={handlePrint}
        >
          Export to PDF
        </Button> */}
        <Button
          type="primary"
          className="gx-mb-0"
          disabled={!Attendance?.items?.length}
          onClick={() => handleCsv()}
        >
          Export to CSV
        </Button>
      </Col>
      <Col span={24} ref={componentRef} className="gx-mt-4">
        <Table
          loading={isLoading}
          columns={attendanceColumns}
          rowClassName="gx-text-center"
          dataSource={Attendance.items}
          pagination={false}
          bordered
          rowKey={(item) => item?.date}
          title={() => (
            <>
              <div>
                <div className="gx-flex-row gx-flex-nowrap gx-align-items-center">
                  {authUser.employee_id ? (
                    <>
                      <Avatar size={60} src={employee?.photo?.big} icon={<UserOutlined />} />
                      <div className="gx-mb-0 gx-ml-2">
                        <h4 className="gx-mb-1">
                          {employee?.name}
                          <small> ({employee?.eid})</small>
                        </h4>
                        <strong>From: </strong> {search?.from_date}
                        <strong> To: </strong> {search?.to_date}
                      </div>
                    </>
                  ) : (
                    <>
                      <Skeleton.Avatar size={60} shape={'circle'} />
                      <Skeleton.Input style={{ marginLeft: 8, width: 200 }} size={40} />
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        />
      </Col>
    </Row>
  )
  return (
    <Auxiliary>
      <Widget>{renderAttendance()}</Widget>
    </Auxiliary>
  )
}

export default Individual
