import { FETCH_TYPES } from '../util/constants'
import request from '../util/request'
import { REST_API } from './rest.api'
import { ServiceBase } from './ServiceBase'

export class ObjectiveKPI extends ServiceBase {
  async removeItem(route, body) {
    const options = { method: FETCH_TYPES.PATCH, body }
    return await request(this.API_ENDPOINT + route, options)
  }
  async createComment(id, body) {
    const options = { method: FETCH_TYPES.POST, body }
    return await request(this.API_ENDPOINT + '/' + id + REST_API.COMMENTS, options)
  }
}
