import { setLoading } from 'appRedux/reducers/Loading'
import { RemoteWorkService } from 'services'
import { PAGINATION_TYPES, ALERT_TYPES } from 'util/constants'
import {
  pagePaginationSuccess,
  addPaginationItem,
  updatePaginationItem,
  deletePaginationItem,
} from 'appRedux/reducers/Pagination'
import { showMessage } from 'util/helpers'

export const fetchRemoteWorks = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.remote_work
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await RemoteWorkService.find(query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: res,
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateRemoteWork = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.remote_work
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await RemoteWorkService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
      res && showMessage(ALERT_TYPES.SUCCESS, 'Your data has been saved!')
    } else {
      res = await RemoteWorkService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
      res && showMessage(ALERT_TYPES.SUCCESS, 'Your data has been updated!')
    }
    return res
  }
}

export const deleteRemoteWork = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.remote_work
    const loadingObj = {
      key,
      value: true,
    }
    let res
    if (id) {
      res = await RemoteWorkService.remove({ remote_work_id: id })
      if (res.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    return res
  }
}

export const handleRemoteWorkActions = (payload) => {
  return async (dispatch) => {
    const { id, key, route } = payload
    const loadingObj = {
      key,
      value: true,
    }
    if (id) {
      dispatch(setLoading(loadingObj))
      const res = await RemoteWorkService.patchAction(route, { remote_work_id: id })
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}
