import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
//
import Settings from './Settings'
import Auth from './Auth'
import Common from './Common'
import loading from './Loading'
import pagination from './Pagination'
import groups from './Groups'
import exams from './Exams'

const createBrowserHistory = require('history').createBrowserHistory

export const history = createBrowserHistory()

const createRootReducer = combineReducers({
  router: connectRouter(history),
  common: Common,
  uiSettings: Settings,
  loading,
  auth: Auth,
  pagination,
  groups,
  exams,
})

export default createRootReducer
