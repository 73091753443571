import { KpiService, ObjectiveService, ObjectiveKpiService } from 'services'
import { PAGINATION_TYPES } from 'util/constants'
import {
  pagePaginationSuccess,
  addPaginationItem,
  updatePaginationItem,
  deletePaginationItem,
  selectPaginationItem,
} from 'appRedux/reducers/Pagination'
import { setLoading } from 'appRedux/reducers/Loading'
import { showMessage } from '../../util/helpers'
import { ALERT_TYPES } from '../../util/constants'

export const fetchKPI = (query) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await KpiService.find(query)
      res &&
        dispatch(
          pagePaginationSuccess({
            data: { ...res },
            key,
          })
        )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const getKPIById = (id) => {
  return async (dispatch, getState) => {
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    try {
      dispatch(setLoading(loadingObj))
      const res = await KpiService.findById(id)
      const kpi = selectPaginationItem({ key, id })(getState())
      if (!kpi)
        res &&
          dispatch(
            addPaginationItem({
              value: res,
              key,
            })
          )
      else
        res &&
          dispatch(
            updatePaginationItem({
              key,
              id,
              value: res,
            })
          )
    } catch (e) {
      console.error('PRINT IN %s=====>', 'Pagination', e)
    }
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
  }
}

export const addOrUpdateKPI = (payload) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.kpi
    const { id, ...rest } = payload
    let res
    if (!id) {
      res = await KpiService.create(rest)
      res &&
        dispatch(
          addPaginationItem({
            value: res,
            key,
          })
        )
      res && showMessage(ALERT_TYPES.SUCCESS, `Your data has been submitted!`)
    } else {
      res = await KpiService.patch(id, rest)
      res &&
        dispatch(
          updatePaginationItem({
            key,
            id,
            value: res,
          })
        )
      res && showMessage(ALERT_TYPES.SUCCESS, `Your data has been updated!`)
    }
    return res
  }
}

export const deleteKPI = (id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    // dispatch(setLoading(loadingObj));
    let res
    if (id) {
      res = await KpiService.remove({ kpi_id: id })
      if (res.success)
        dispatch(
          deletePaginationItem({
            key,
            value: id,
          })
        )
    }
    loadingObj.value = false
    // dispatch(setLoading(loadingObj));
    return res
  }
}

export const addOrUpdateObjective = (payload) => {
  return async (dispatch) => {
    const { id, kpi_id } = payload
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    let res
    dispatch(setLoading(loadingObj))
    if (!id) {
      res = await ObjectiveService.create(payload)
    } else {
      res = await ObjectiveService.patch(id, payload)
    }
    res &&
      dispatch(
        updatePaginationItem({
          id: kpi_id,
          key,
          value: { ...res },
        })
      )
    res && showMessage(ALERT_TYPES.SUCCESS, `Your data has been submitted!`)
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const deleteObjective = (id, kpi_id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    // dispatch(setLoading(loadingObj));
    let res
    if (id) {
      res = await ObjectiveService.removeItem('/delete', { objective_id: id })
      res && showMessage(ALERT_TYPES.SUCCESS, `Data has been successfully deleted!`)
      res && dispatch(getKPIById(kpi_id))
    }
    loadingObj.value = false
    // dispatch(setLoading(loadingObj));
    return res
  }
}

export const addOrUpdateObjectiveKPI = (payload) => {
  return async (dispatch) => {
    const { id, kpi_id } = payload
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    let res
    dispatch(setLoading(loadingObj))
    if (!id) {
      res = await ObjectiveKpiService.create(payload)
    } else {
      res = await ObjectiveKpiService.patch(id, payload)
    }
    res && dispatch(getKPIById(kpi_id))
    res && showMessage(ALERT_TYPES.SUCCESS, `Your data has been submitted!`)
    loadingObj.value = false
    dispatch(setLoading(loadingObj))
    return res
  }
}

export const deleteObjectiveKPI = (id, kpi_id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    // dispatch(setLoading(loadingObj));
    let res
    if (id) {
      res = await ObjectiveKpiService.removeItem('/delete', { objective_kpi_id: id })
      res && showMessage(ALERT_TYPES.SUCCESS, `Data has been successfully deleted!`)
      res && dispatch(getKPIById(kpi_id))
    }
    loadingObj.value = false
    // dispatch(setLoading(loadingObj));
    return res
  }
}

export const sendComment = (Service, id, comment, kpi_id) => {
  return async (dispatch) => {
    const key = PAGINATION_TYPES.kpi
    const loadingObj = {
      key,
      value: true,
    }
    // dispatch(setLoading(loadingObj));
    let res
    if (id) {
      res = await Service.createComment(id, { comment })
      res && showMessage(ALERT_TYPES.SUCCESS, `Message has been successfully submitted!`)
      res && dispatch(getKPIById(kpi_id))
    }
    loadingObj.value = false
    // dispatch(setLoading(loadingObj));
    return res
  }
}
