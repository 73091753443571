/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
//
import EmployeesCard from './EmployeesCard'
import EmployeesTable from './EmployeesTable'

const EmployeeList = ({ viewType, ...rest }) => {
  return <>{viewType === 'card' ? <EmployeesCard {...rest} /> : <EmployeesTable {...rest} />}</>
}

EmployeeList.propTypes = { viewType: PropTypes.string.isRequired }
export default EmployeeList
