import React from 'react'
import { connect } from 'react-redux'
import { Avatar, Divider, Select, Spin } from 'antd'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import { UserOutlined } from '@ant-design/icons'
import { EmployeeService } from 'services'
import { addCommonItems } from 'appRedux/reducers/Common'
import { KEYS } from 'util/constants'

const { Option } = Select

class SearchEmployee extends React.Component {
  constructor(props) {
    super(props)
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 600)
  }

  // eslint-disable-next-line react/state-in-constructor
  state = {
    data: [],
    value: undefined,
    fetching: false,
  }

  async componentDidMount() {
    const { value, onChange } = this.props
    if (value) {
      try {
        const res = await EmployeeService.find({ id: value, enable: true })
        const data = res.items.map((user) => ({
          text: user.name,
          value: `${user.id}#${user.name}#${user.eid}`,
          eid: user.eid,
          image: user.photo,
        }))
        this.setState({
          data,
          fetching: false,
          value: data[0]?.value ? { value: data[0]?.value } : value,
        })
        if (onChange && data[0]?.value) {
          onChange({ value: data[0]?.value })
        }
      } catch (error) {
        console.error('Error fetching user:', error)
      }
    }
  }

  fetchUser = (value) => {
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ data: [], fetching: true })
    const query = { full_text: value, status: true }
    EmployeeService.search({}, query)
      .then((res) => {
        if (!res || !res.items || fetchId !== this.lastFetchId) {
          return
        }
        const data = res.items.map((user) => ({
          text: user.name,
          value: `${user.id}#${user.name}#${user.eid}`,
          eid: user.eid,
          image: user.photo,
        }))
        this.setState({ data, fetching: false })
      })
      .catch((error) => {
        console.error('Error fetching user:', error)
        this.setState({ fetching: false })
      })
  }

  handleChange = (value) => {
    // eslint-disable-next-line react/destructuring-assignment
    const filtered = this.state.data.filter((x) => x?.value === value?.value)[0]
    const employee = {
      name: filtered?.text,
      id: filtered?.value?.split('#')[0],
      ...filtered,
    }
    // eslint-disable-next-line react/destructuring-assignment, react/prop-types
    this.props.dispatch(addCommonItems({ key: KEYS.selectedEmployee, value: employee }))
    this.setState({
      value,
      data: [],
      fetching: false,
    })
    const { onChange } = this.props
    if (onChange) {
      onChange(value)
    }
  }

  render() {
    const { fetching, data, value } = this.state
    return (
      <Select
        showSearch
        allowClear
        labelInValue
        value={value}
        defaultActiveFirstOption={false}
        showArrow={false}
        placeholder="Line Manager"
        notFoundContent={fetching ? <Spin size="small" /> : null}
        onSearch={this.fetchUser}
        onChange={this.handleChange}
        filterOption={(input, option) => {
          const [, name, eid] = option?.key?.split('#') ?? ['', '', '']
          return (
            name?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            eid?.toLowerCase().indexOf(input.toLowerCase()) >= 0
          )
        }}
        // eslint-disable-next-line react/destructuring-assignment
        style={this.props.style}
      >
        {data.map((d) => (
          <Option key={d.value}>
            <Avatar size={20} src={d.image?.small} icon={<UserOutlined />} />
            <Divider type="vertical" />
            {d.text}
            <small> ({d.eid})</small>
          </Option>
        ))}
      </Select>
    )
  }
}

SearchEmployee.defaultProps = {
  style: { width: '100%' },
}

SearchEmployee.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
}

// eslint-disable-next-line import/prefer-default-export
export const SearchEmployeeBox = connect()(SearchEmployee)
