/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Col, Descriptions, Row, Switch, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
//
import Widget from 'components/Widget'

const bg_banner = require('assets/images/widget/profile_bg.png')

const { Title } = Typography
const EmployeeBanner = ({ user }) => {
  const { name, parent, photo, mobile, email, eid, blood_group, company, enabled, office } = user ?? {}
  return (
    <Widget styleName="gx-card-full">
      <div className="gx-slider-img">
        <img alt="banner" src={bg_banner} width="100%" style={{ maxHeight: 185 }} />
        <Avatar
          size={{ xs: 120, sm: 120, md: 82, lg: 82, xl: 100, xxl: 150 }}
          src={photo?.big}
          icon={<UserOutlined />}
          style={{
            position: 'absolute',
            bottom: '-40%',
            left: '2%',
            objectFit: 'cover',
            border: '4px solid #FFF',
            borderRadius: '100%',
          }}
        />
      </div>
      <Row>
        <Col span={3} />
        <Col span={4}>
          <Title level={4} className="gx-mb-1">
            {name}
          </Title>
          <div className="gx-jr-db-label">ID :{eid}</div>
        </Col>
        <Col span={8}>
          <Descriptions column={1} labelStyle={{ width: 150 }} size="small">
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Company
                </span>
              }
            >
              <span className="gx-jr-db-label">{company?.name}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Office
                </span>
              }
            >
              <span className="gx-jr-db-label">{office?.name}</span>
            </Descriptions.Item>
          </Descriptions>
          <Switch
            checkedChildren="ENABLE"
            unCheckedChildren="DISABLE"
            checked={enabled}
          />
        </Col>
        <Col span={9}>
          <Descriptions column={1} labelStyle={{ width: 150, marginBottom: 0 }}>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Mobile
                </span>
              }
            >
              <span className="gx-jr-db-label">{mobile}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Email
                </span>
              }
            >
              <span className="gx-jr-db-label">{email}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={
                <span
                  className="gx-jr-db-label"
                  style={{
                    display: 'inline-block',
                    width: '150px',
                  }}
                >
                  Blood Group
                </span>
              }
            >
              <span className="gx-jr-db-label">{blood_group?.name}</span>
            </Descriptions.Item>
            <Descriptions.Item
              label={<span
                className="gx-jr-db-label"
                style={{
                  display: 'inline-block',
                  width: '150px'
                }}
              >
                Line Manager
              </span>}
            >
              <span className="gx-jr-db-label">{parent?.name}</span>
            </Descriptions.Item>

          </Descriptions>
        </Col>
      </Row>
    </Widget>
  )
}

EmployeeBanner.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object.isRequired,
}

export default EmployeeBanner
